import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { currency } from "./Currency";
import Dialog from "./Dialog";
import { bulan, jenisBelanja } from "./constant";
import useSatuanOptions from "../options/satuan";
import { HiPencilSquare, HiTrash } from "react-icons/hi2";
import DialogDelete from "./DialogDelete";
import FormEditDetail from "./FormEditDetail";

function DialogRincian(props) {
  const location = useLocation();
  const { rincian } = useSelector((state) => state.loadProgram);
  const [showDeleteDetail, setShowDetailDelete] = useState(false);
  const [formEditDetail, setFormEditDetail] = useState(false);
  const [idDetail, setIdDetail] = useState("");
  const [dataDetail, setDataDetal] = useState({});
  const satuanOpions = useSatuanOptions(true);
  return (
    <div>
      <DialogDelete
        show={showDeleteDetail}
        close={setShowDetailDelete}
        click={() => {
          props.remove(idDetail);
          setShowDetailDelete(false);
        }}
      />
      <FormEditDetail
        show={formEditDetail}
        close={setFormEditDetail}
        lembaga={props.lembaga}
        data={dataDetail}
        id={idDetail}
        submit={(data) => {
          props.update(idDetail, data);
          setFormEditDetail(false);
        }}
      />
      <Dialog show={props.show} toggle={props.close}>
        <div className="bg-white md:w-[70vw] w-[90vw] h-[90vh] overflow-y-auto">
          <div className="py-4 px-8 border-b border-slate-200">
            <h1 className="text-xl">Rincian Kegiatan</h1>
          </div>
          <div className="px-8 py-7 md:grid md:grid-cols-2 md:space-y-0 space-y-3  gap-6">
            <div className="flex">
              <h1 className="text-gray-600 text-lg">Status :</h1>
              <h1
                className={`font-light text-lg ${
                  rincian.status === "Ditunda"
                    ? "text-yellow-500"
                    : rincian.status === "Disetujui"
                    ? "text-green-700"
                    : "text-red-600"
                }`}
              >
                {rincian.status}
              </h1>
            </div>
            <div className="my-2 flex">
              <h1 className="text-gray-600 text-lg">Total :</h1>
              <h1 className=" font-light text-lg">
                {rincian.total
                  ? currency(parseInt(rincian.total))
                  : currency(0)}
              </h1>
            </div>
          </div>

          <div className="px-8 pt-1 pb-7 overflow-x-hidden">
            <div className="">
              <div className=" font-light text-lg">
                {rincian?.length !== 0 ? (
                  <div className="overflow-x-auto my-2 ">
                    <div className=" w-full overflow-y-auto">
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              No
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Tahun
                            </th>
                            <th scope="col" className="px-6 py-3 w-[40rem]">
                              Dari
                            </th>
                            <th scope="col" className="px-6 py-3 w-[40rem]">
                              Sampai
                            </th>
                            <th scope="col" className="px-6 py-3 w-[40rem]">
                              Uraian
                            </th>
                            <th scope="col" className="px-6 py-3 w-[40rem]">
                              Satuan
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Vol
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Jenis Belanja
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Metode
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Harga Satuan
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Pendapatan
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Total
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Edit
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Delete
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rincian?.map((e, id) => (
                            <tr
                              className="bg-white border-b hover:bg-gray-100"
                              key={id}
                            >
                              <td className="px-6 py-3">{id + 1}</td>
                              <td className="px-6 py-3">{e.academic_year}</td>
                              <td className="px-6 py-3">{bulan[e.from - 1]}</td>
                              <td className="px-6 py-3">
                                {bulan[e.until - 1]}
                              </td>
                              <td className="px-6 py-3">{e.description}</td>
                              <td className="px-6 py-3">
                                {
                                  satuanOpions?.find(
                                    (value) => value.value === e.unit_id
                                  )?.label
                                }
                              </td>
                              <td className="px-6 py-3">{e.vol}</td>
                              <td className="px-6 py-3">
                                {
                                  jenisBelanja?.find(
                                    (value) => value?.value === e.post
                                  )?.label
                                }
                              </td>
                              <td className="px-6 py-3">{e.thawing_method}</td>
                              <td className="px-6 py-3">
                                {currency(parseInt(e.unit_price))}
                              </td>
                              <td className="px-6 py-3">
                                {e.institution_income?.name}
                              </td>
                              <td className="px-6 py-3">
                                {currency(parseInt(e.total))}
                              </td>
                              <td className="px-6 py-3">
                                <HiPencilSquare
                                  className="w-6 h-6 cursor-pointer"
                                  onClick={() => {
                                    setIdDetail(e.id);
                                    setDataDetal(e);
                                    props.close();
                                    setFormEditDetail(true);
                                  }}
                                />
                              </td>
                              <td className="px-6 py-3">
                                <HiTrash
                                  className="w-6 h-6 cursor-pointer"
                                  onClick={() => {
                                    setIdDetail(e.id);
                                    props.close();
                                    setShowDetailDelete(true);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <h1 className="text-4xl mx-1">-</h1>
                )}
              </div>
            </div>
          </div>
          <div
            className={`px-6 pb-7 flex justify-end ${props.classAdd} ${
              location.pathname === "/home/status-anggaran" ? "hidden" : "block"
            }`}
          >
            <button
              onClick={props.click}
              className="border border-sky-400  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300"
            >
              Tambah Rincian
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default DialogRincian;
