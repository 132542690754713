import {createAsyncThunk} from '@reduxjs/toolkit';
import ApiRealization from "../../api/realization";


export const getRealization = createAsyncThunk(
    "realization/all",
    async(_, {rejectWithValue})=>{
        try{
            const res = await ApiRealization.getByIstitution()
            if(res.status===200){
                return res.data
            }
        }catch(_){
            return rejectWithValue("error")
        }
    }
)