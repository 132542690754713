import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allLembaga, getRole, getSistem } from '../store/actions/loadProgram'
import Options from './Option'
import Radio from './Radio'
import useLembagaApakahOptions from '../options/lembaga'
import useSystemOptions from '../options/system'
import useRoleOptions from '../options/role'

function SistemHelp(props) {
    const { role } = useSelector((state) => state.loadProgram)
    const sistemWatch = props.method.watch(`sistem[${props.id}].system_id`)
    const watchRole = props.method.watch(`sistem[${props.id}].role_id`)
    const find = role.find((e) => e.uuid === watchRole)?.nama_role
    const lemOpt = useLembagaApakahOptions(true)
    const sistemOpt = useSystemOptions(true)
    const roleOpt = useRoleOptions(sistemWatch,true)
    return (
        <div className='w-full'>
            <Options label="Sistem" method={props.method} methodName={`sistem[${props.id}].system_id`} options={sistemOpt} />
            <Options label="Role" method={props.method} methodName={`sistem[${props.id}].role_id`} options={roleOpt} />
            <div className='mb-1'>
                <h1 className='font-light text-lg mb-2'>Super Admin</h1>
                <div className='flex my-auto md:space-x-0 space-x-2'>
                    <Radio method={props.method} methodName={`sistem[${props.id}].super_admin`} value="true" label="Iya" />
                    <Radio method={props.method} methodName={`sistem[${props.id}].super_admin`} value="false" label="Tidak" />
                </div>
                {props.method.formState.errors[`sistem[${props.id}].super_admin`] && (
                    <p className="text-red-600 mt-1 mx-1">
                        {props.method.formState.errors[`sistem[${props.id}].super_admin`].message}
                    </p>
                )}
            </div>

            <div className={find === "admin-lembaga" ? "block" : "hidden"}>
                <Options label="Lembaga" method={props.method} methodName={`sistem[${props.id}].institution_apakah`} options={lemOpt} />
            </div>
        </div>
    )
}

export default SistemHelp