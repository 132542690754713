import React from "react";
import Dialog from "./Dialog";
import Option from "./Option";
import TextArea from "./TextArea";
import AddButton from "./AddButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Radio from "./Radio";
import useComponentOptions from "../options/komponen";
import useResetForm from "../hooks/custom/useResetForm";
import useGetQuisioner from "../hooks/useGetQuisioner";
import { useSelector } from "react-redux";

function FormKegiatan(props) {
  const { quisioner } = useSelector((state) => state.loadProgram);
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        activity: yup.object().shape({
          component_id: yup.string().required("Tidak Boleh Kosong"),
          name: yup.string().required("Tidak Boleh Kosong"),
          continue_activity: yup
            .string()
            .oneOf(["iya", "tidak"], "Pilih salah satu")
            .required("Tidak Boleh Kosong"),
        }),
        answer_list: yup.array().of(
          yup.object().shape({
            answer_id: yup.number().typeError('Tidak boleh kosong').required('Tidak boleh kosong'),
            question_id: yup.number().typeError('Tidak boleh kosong').required('Tidak boleh kosong'),
            weight: yup.number().typeError('Tidak boleh kosong').required('Tidak boleh kosong'),
          })
        ),
      })
    ),
    defaultValues: { continue_activity: "tidak" },
  });
  useGetQuisioner(props?.show);
  const komponenOpt = useComponentOptions(
    props?.programId,
    props?.tahun,
    props?.show
  );
  useResetForm(
    method,
    {
      activity: {
        component_id: props?.komponenId,
        name: "",
        continue_activity: "tidak",
      },
    },
    props?.show
  );
  return (
    <Dialog show={props.show} toggle={props.close} scroll>
      <div className="bg-white md:w-[60vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl"> Tambah Kegiatan</h1>
        </div>
        <form
          onSubmit={method.handleSubmit(props.submit)}
          className="px-6 py-7 space-y-1"
        >
          <div className="flex flex-col">
            <Option
              label="Komponen"
              method={method}
              methodName="activity.component_id"
              options={komponenOpt}
            />
            <div className="mb-1">
              <h1 className="font-light text-lg mb-2">Kegiatan Rutin</h1>
              <div className="flex my-auto md:space-x-0 space-x-2">
                <Radio
                  method={method}
                  methodName={`activity.continue_activity`}
                  value="iya"
                  label="Iya"
                />
                <Radio
                  method={method}
                  methodName={`activity.continue_activity`}
                  value="tidak"
                  label="Tidak"
                />
              </div>
              {method.formState.errors[`continue_activity`] && (
                <p className="text-red-600 mt-1 mx-1">
                  {
                    method.formState.errors[`activity.continue_activity`]
                      .message
                  }
                </p>
              )}
            </div>
            <TextArea
              label="Kegiatan"
              method={method}
              methodName="activity.name"
            />
            <div className="my-5">
              {quisioner &&
                quisioner?.map((value, id) => (
                  <div key={id}>
                    <h1>
                      {id + 1}. {value?.question}
                    </h1>
                    <div className="grid grid-cols-2 gap-4 my-2">
                      {value?.weight_answers &&
                        value?.weight_answers?.map((answers, idx) => (
                          <div
                            key={idx}
                            onClick={() => {
                              method.setValue(
                                `answer_list.${id}.question_id`,
                                value.id
                              );
                              method.setValue(
                                `answer_list.${id}.weight`,
                                answers.weight
                              );
                            }}
                          >
                            <Radio
                              method={method}
                              methodName={`answer_list.${id}.answer_id`}
                              value={`${answers?.id}`}
                              label={answers?.answer}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}

export default FormKegiatan;
