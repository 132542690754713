import { createSlice } from "@reduxjs/toolkit";
import {
  allKegiatanRinci,
  allListKegiatanByLembaga,
  allRincianByLembaga,
  allRincianKegiatan,
  allTotalKegiatan,
  allTotalPendapatan,
} from "../actions/program";

export const programStore = createSlice({
  name: "program",
  initialState: {
    totalPendapatan: {},
    totalKegiatan: {},
    rincian: [],
    rincianKegiatan: [],
    listRincian: [],
    kegiatanByLembaga: [],
    loadingProgram: false,
    msgProgram: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(allKegiatanRinci.pending, (state) => {
        state.loadingProgram = true;
      })
      .addCase(allKegiatanRinci.fulfilled, (state, action) => {
        state.loadingProgram = false;
        state.rincian = action.payload;
      })
      .addCase(allKegiatanRinci.rejected, (state) => {
        state.loadingProgram = false;
      })
      .addCase(allRincianKegiatan.pending, (state) => {
        state.loadingProgram = true;
      })
      .addCase(allRincianKegiatan.fulfilled, (state, action) => {
        state.loadingProgram = false;
        state.rincianKegiatan = action.payload;
      })
      .addCase(allRincianKegiatan.rejected, (state) => {
        state.loadingProgram = false;
      })
      .addCase(allTotalKegiatan.pending, (state) => {
        state.loadingProgram = true;
      })
      .addCase(allTotalKegiatan.fulfilled, (state, action) => {
        state.loadingProgram = false;
        state.totalKegiatan = action.payload;
      })
      .addCase(allTotalKegiatan.rejected, (state) => {
        state.loadingProgram = false;
      })
      .addCase(allTotalPendapatan.pending, (state) => {
        state.loadingProgram = true;
      })
      .addCase(allTotalPendapatan.fulfilled, (state, action) => {
        state.loadingProgram = false;
        state.totalPendapatan = action.payload;
      })
      .addCase(allTotalPendapatan.rejected, (state) => {
        state.loadingProgram = false;
      })
      .addCase(allRincianByLembaga.pending, (state) => {
        state.loadingProgram = true;
      })
      .addCase(allRincianByLembaga.fulfilled, (state, action) => {
        state.loadingProgram = false;
        state.listRincian = action.payload;
      })
      .addCase(allRincianByLembaga.rejected, (state) => {
        state.loadingProgram = false;
      })
      .addCase(allListKegiatanByLembaga.pending, (state) => {
        state.loadingProgram = true;
      })
      .addCase(allListKegiatanByLembaga.fulfilled, (state, action) => {
        state.loadingProgram = false;
        state.kegiatanByLembaga = action.payload;
      })
      .addCase(allListKegiatanByLembaga.rejected, (state) => {
        state.loadingProgram = false;
      });
  },
});
export default programStore.reducer;
