import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useGetSystem from "../hooks/useGetSystem";

export default function useSystemOptions(trigger) {
  const { sistem } = useSelector((state) => state.loadProgram);
  const [allSystemOptions, setAllSystemOptions] = useState([]);
  useGetSystem(trigger);
  useEffect(() => {
    if (sistem.length > 0) {
      const allSystem = sistem.map((e) => {
        return { value: e.uuid, label: e.nama_sistem };
      });
      setAllSystemOptions(allSystem);
    }
  }, [sistem]);
  return allSystemOptions;
}
