import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllUser } from "../store/actions/user";

export default function useGetAllUser(trigger) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUser());
  }, [trigger]);
}
