import React from 'react'
import { Controller } from 'react-hook-form'

function Radio(props) {
  return (
    <div>
    <div className='flex w-full md:px-2'>
        <Controller
            control={props.method.control}
            name={props.methodName}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
            }) => {
                return (
                < input
                    // {...props.method.register(props.methodName)}
                    onChange={(e) =>{
                        onChange(e.target.value)
                        if(props.onChange){
                            props.onChange(e.target.value)
                        }
                    }} 
                    type="radio"
                    className={`w-4 h-4 mt-1`}
                    id={props.id} 
                    value={props.value}
                    checked={props.value === value}
                    ref={ref}
                />
             )}}

        />
        < label className='ml-2 text-md font-light'>{props.label}</label>
    </div>
</div>  )
}

export default Radio