import React, { useEffect, useState } from "react";
import {
  HiOutlineChevronUp,
  HiOutlineChevronDown,
  HiOutlinePlus,
  HiOutlinePencilSquare,
  HiOutlineTrash,
} from "react-icons/hi2";
import FormKomponen from "../component/FormKomponen";
import FormLembaga from "../component/FormLembaga";
import FormProgram from "../component/FormProgram";
import FormKegiatan from "../component/FormKegiatan";
import { useDispatch, useSelector } from "react-redux";
import {
  addKegiatan,
  addKomponen,
  addLembaga,
  addPersetujuan,
  addProgram,
  addRincian,
  addSubKegiatan,
  destroyKegiatan,
  destroyKomponen,
  destroyLembaga,
  destroyProgram,
  destroySubKegiatan,
  updateKegiatan,
  updateKomponen,
  updateLembaga,
  updateProgram,
  updateSubKegiatan,
} from "../store/actions/proker";
import {
  allKegiatan,
  allKomponen,
  allLembaga,
  allProgram,
  allProgramByLem,
  allSubKegiatan,
  getTahunActive,
} from "../store/actions/loadProgram";
import Loader from "../component/Loader";
import SnackBar from "../component/Snackbar";
import FormSubKegiatan from "../component/FormSubKegiatan";
import FormRincian from "../component/FormRincian";
import {
  allRincianKegiatan,
  allTotalPendapatan,
} from "../store/actions/program";
import DialogRincian from "../component/DialogRincian";
import FormEditProgram from "../component/FormEditProgram";
import DialogDelete from "../component/DialogDelete";
import FormEditKomponen from "../component/FormEditKomponen";
import FormEditSub from "../component/FormEditSub";
import FormEditKegiatan from "../component/FormEditKegiatan";
import FormEditLembaga from "../component/FormEditLembaga";
import { currency } from "../component/Currency";
import { convertToRupiah } from "../component/FormatMoney";

function Anggaran({ no_lembaga, tahun_ajar, trigger }) {
  const dispatch = useDispatch();
  const [formLembaga, setFormLembaga] = useState(false);
  const [formProgram, setFormProgram] = useState(false);
  const [formKomponen, setFormKomponen] = useState(false);
  const [formKegiatan, setFormKegiatan] = useState(false);
  const [formSub, setFormSub] = useState(false);
  const [formRincian, setFormRincian] = useState(false);
  const [formEditLembaga, setFormEditLembaga] = useState(false);
  const [formEditProgram, setFormEditProgram] = useState(false);
  const [formEditKomponen, setFormEditKomponen] = useState(false);
  const [formEditKegiatan, setFormEditKegiatan] = useState(false);
  const [formEditSub, setFormEditSub] = useState(false);
  const [dialogRincian, setDialogRincian] = useState(false);
  const [snackForm, setSnackForm] = useState(false);
  const [dropProgram, setDropProgram] = useState(false);
  const [dropKomponen, setDropKomponen] = useState(false);
  const [dropKegiatan, setDropKegiatan] = useState(false);
  const [dialogDeleteLembaga, setDialogDeleteLembaga] = useState(false);
  const [dialogDeleteProgram, setDialogDeleteProgram] = useState(false);
  const [dialogDeleteKomponen, setDialogDeleteKomponen] = useState(false);
  const [dialogDeleteKegiatan, setDialogDeleteKegiatan] = useState(false);
  const [dialogDeleteSub, setDialogDeleteSub] = useState(false);
  const [rinci, setRinci] = useState(false);
  const [noProgram, setNoProgram] = useState("");
  const [noKomponen, setNoKomponen] = useState("");
  const [no_prog, setNo_Prog] = useState("");
  const [no_kom, setNo_kom] = useState("");
  const [no_keg, setNo_keg] = useState("");
  const [no_sub, setNo_sub] = useState("");
  const [itm_program, setItm_program] = useState("");
  const [itm_komponen, setItm_komponen] = useState("");
  const [itm_kegiatan, setItm_kegiatan] = useState("");
  const [itm_sub, setItm_sub] = useState("");
  const [allTotal, setAllTotal] = useState(0);
  const { program, lembaga, komponen, kegiatan, loadingProgram, subKegiatan } =
    useSelector((state) => state.loadProgram);
  const { lembagaAuth, role } = useSelector((state) => state.auth);
  const { msgProker } = useSelector((state) => state.proker);
  const { rincianKegiatan, totalPendapatan } = useSelector(
    (state) => state.program
  );
  const tambahLembaga = (e) => {
    dispatch(addLembaga(e));
    setFormLembaga(!formLembaga);
    setSnackForm(!snackForm);
  };
  const tambahProgram = (e) => {
    dispatch(addProgram(e));
    setFormProgram(!formProgram);
    setSnackForm(!snackForm);
  };
  const tambahKomponen = (e) => {
    dispatch(addKomponen(e));
    setFormKomponen(!formKomponen);
    setSnackForm(!snackForm);
  };
  const tambahKegiatan = (e) => {
    e.continue_ = e.continue_ === "iya" ? true : false;
    dispatch(addKegiatan(e));
    setFormKegiatan(!formKegiatan);
    setSnackForm(!snackForm);
  };
  const tambahSub = (e) => {
    dispatch(addSubKegiatan(e));
    setFormSub(!formSub);
    setSnackForm(!snackForm);
  };
  const tambahRincian = (e) => {
    let chnge = e.detail_of_activity_list?.map((d) => {
      d.unit_price = convertToRupiah(d.unit_price);
      d.total = convertToRupiah(d.total);
      return d;
    });
    e.detail_of_activity_list = chnge;
    dispatch(addRincian(e));
    setFormRincian(!formRincian);
    setSnackForm(!snackForm);
  };
  const editLembaga = (e) => {
    dispatch(updateLembaga({ no_lem: no_lembaga?.value, edit: e }));
    setFormEditLembaga(!formEditLembaga);
    setSnackForm(!snackForm);
  };
  const editProgram = (e) => {
    dispatch(updateProgram({ no_prog: no_prog, edit: e }));
    setFormEditProgram(!formEditProgram);
    setSnackForm(!snackForm);
  };
  const editKomponen = (e) => {
    dispatch(updateKomponen({ no_kom: no_kom, edit: e }));
    setFormEditKomponen(!formEditKomponen);
    setSnackForm(!snackForm);
  };
  const editKegiatan = (e) => {
    dispatch(updateKegiatan({ no_keg: no_keg, edit: e }));
    setFormEditKegiatan(!formEditKegiatan);
    setSnackForm(!snackForm);
  };
  const editSub = (e) => {
    dispatch(updateSubKegiatan({ no_sub: no_sub, edit: e }));
    setFormEditSub(!formEditSub);
    setSnackForm(!snackForm);
  };
  const hapusLembaga = () => {
    dispatch(destroyLembaga(no_lembaga?.value));
    setDialogDeleteLembaga(!dialogDeleteLembaga);
    setSnackForm(!snackForm);
  };
  const hapusProgram = () => {
    dispatch(destroyProgram(no_prog));
    setDialogDeleteProgram(!dialogDeleteProgram);
    setSnackForm(!snackForm);
  };
  const hapusKomponen = () => {
    dispatch(destroyKomponen(no_kom));
    setDialogDeleteKomponen(!dialogDeleteKomponen);
    setSnackForm(!snackForm);
  };
  const hapusKegiatan = () => {
    dispatch(destroyKegiatan(no_keg));
    setDialogDeleteKegiatan(!dialogDeleteKegiatan);
    setSnackForm(!snackForm);
  };
  const hapusSub = () => {
    dispatch(destroySubKegiatan(no_sub));
    setDialogDeleteSub(!dialogDeleteSub);
    setSnackForm(!snackForm);
  };
  useEffect(() => {
    if (role === "bendahara" || role === "eksekutif") {
      dispatch(allLembaga());
      dispatch(getTahunActive());
    }
  }, [snackForm]);
  useEffect(() => {
    if (lembagaAuth !== "" && role === "admin-lembaga" && tahun_ajar) {
      dispatch(allProgramByLem(tahun_ajar));
      dispatch(allTotalPendapatan("-"));
    } else if (role === "bendahara" || role === "eksekutif") {
      if (no_lembaga && no_lembaga?.value !== "") {
        dispatch(allProgram({ no_lem: no_lembaga?.value, tahun: tahun_ajar }));
        dispatch(allTotalPendapatan(no_lembaga?.value));
      }
    }
  }, [no_lembaga, formProgram, snackForm, tahun_ajar, trigger]);
  useEffect(() => {
    if (no_prog !== "") {
      dispatch(allKomponen({ program: no_prog, tahun: tahun_ajar }));
    }
  }, [no_prog, snackForm]);
  useEffect(() => {
    if (no_kom !== "") {
      dispatch(allKegiatan({ komponen: no_kom, tahun: tahun_ajar }));
    }
  }, [no_kom, snackForm]);
  useEffect(() => {
    if (no_keg !== "") {
      dispatch(allSubKegiatan({ kegiatan: no_keg, tahun: tahun_ajar }));
    }
  }, [no_keg, snackForm]);
  const lembagaOpt = lembaga.map((e) => {
    return { value: e.no_lembaga, label: e.nama_lembaga };
  });
  const setuju = () => {
    dispatch(addPersetujuan({ no_keg: no_keg, post: { status: "Disetujui" } }));
    setDialogRincian(!dialogRincian);
    setSnackForm(!snackForm);
  };
  const tolak = () => {
    dispatch(addPersetujuan({ no_keg: no_keg, post: { status: "Ditolak" } }));
    setDialogRincian(!dialogRincian);
    setSnackForm(!snackForm);
  };
  useEffect(() => {
    setAllTotal(0);
    if (Object.keys(program).length !== 0) {
      program.map((d) => {
        return setAllTotal((e) => e + d.total);
      });
    }
  }, [program]);
  return (
    <div>
      <Loader show={loadingProgram} size="w-5 h-5 " />
      <SnackBar show={snackForm} toggle={setSnackForm} msg={msgProker} />
      <FormLembaga
        show={formLembaga}
        close={setFormLembaga}
        submit={tambahLembaga}
      />
      <FormProgram
        show={formProgram}
        close={setFormProgram}
        submit={tambahProgram}
        no_lem={role !== "admin-lembaga" ? no_lembaga : lembagaAuth}
      />
      <FormKomponen
        show={formKomponen}
        close={setFormKomponen}
        submit={tambahKomponen}
        no={noProgram}
        lem={no_lembaga}
      />
      <FormKegiatan
        show={formKegiatan}
        close={setFormKegiatan}
        submit={tambahKegiatan}
        noKom={noKomponen}
        lem={no_lembaga}
      />
      <FormSubKegiatan
        show={formSub}
        close={setFormSub}
        submit={tambahSub}
        no_keg={no_keg}
      />
      <FormRincian
        show={formRincian}
        close={setFormRincian}
        submit={tambahRincian}
        no_keg={no_keg}
        no_subKeg={no_sub}
        rincian={rincianKegiatan}
        rinci={rinci}
        lem={no_lembaga}
        tahunAjar={tahun_ajar}
      />
      <DialogRincian
        show={dialogRincian}
        close={setDialogRincian}
        onClick={() => {
          setDialogRincian(!dialogRincian);
          setFormRincian(!formRincian);
        }}
        setuju={setuju}
        tolak={tolak}
      />
      <DialogDelete
        show={dialogDeleteProgram}
        close={setDialogDeleteProgram}
        onClick={hapusProgram}
      />
      <DialogDelete
        show={dialogDeleteKomponen}
        close={setDialogDeleteKomponen}
        onClick={hapusKomponen}
      />
      <DialogDelete
        show={dialogDeleteKegiatan}
        close={setDialogDeleteKegiatan}
        onClick={hapusKegiatan}
      />
      <DialogDelete
        show={dialogDeleteSub}
        close={setDialogDeleteSub}
        onClick={hapusSub}
      />
      <DialogDelete
        show={dialogDeleteLembaga}
        close={setDialogDeleteLembaga}
        onClick={hapusLembaga}
      />
      <FormEditLembaga
        show={formEditLembaga}
        close={setFormEditLembaga}
        nama_lembaga={no_lembaga?.label}
        submit={editLembaga}
      />
      <FormEditProgram
        show={formEditProgram}
        close={setFormEditProgram}
        submit={editProgram}
        item_program={itm_program}
      />
      <FormEditKomponen
        show={formEditKomponen}
        close={setFormEditKomponen}
        submit={editKomponen}
        item_komponen={itm_komponen}
      />
      <FormEditKegiatan
        show={formEditKegiatan}
        close={setFormEditKegiatan}
        submit={editKegiatan}
        item_kegiatan={itm_kegiatan}
      />
      <FormEditSub
        show={formEditSub}
        close={setFormEditSub}
        submit={editSub}
        item_sub_kegiatan={itm_sub}
      />
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-5">
          <div className="flex  text-xs text-gray-700 uppercase bg-gray-50">
            <h1 className="px-6 py-3 w-[5rem]">No</h1>
            <h1 className="px-6 py-3 w-[7rem]">Detail</h1>
            <h1 className="md:px-6 py-3 w-[10rem]">No Program</h1>
            <h1 className="px-6 py-3 w-[50rem]">Program</h1>
            <h1 className="px-6 py-3 w-[50rem]">Total</h1>
            <h1
              className={`md:px-6 px-12 py-3  ${
                role === "eksekutif" ? "hidden" : "w-[7rem]"
              } `}
            >
              Edit
            </h1>
            <h1
              className={`px-6 py-3  ${
                role === "eksekutif" ? "hidden" : "w-[7rem]"
              } `}
            >
              Delete
            </h1>
            <h1
              className={`px-6 py-3 w-[7rem] bg-sky-400 hover:bg-sky-500 hover:text-slate-50 transition-colors ease-in-out duration-300 cursor-pointer ${
                role === "eksekutif" ? "hidden" : "block"
              }`}
              onClick={() => {
                setFormProgram(!formProgram);
              }}
            >
              Tambah Program
            </h1>
          </div>
          {program.map((e, id) => (
            <div key={id}>
              <div className="flex  w-full text-sm text-left text-gray-500 hover:bg-gray-100">
                <h1 className="px-6 py-3 w-[5rem]">{id + 1}</h1>
                <h1 className="px-6 py-3 w-[7rem]">
                  {e.no_program === no_prog && dropProgram ? (
                    <HiOutlineChevronUp
                      className="w-5 h-5 mx-2  cursor-pointer"
                      onClick={() => {
                        setNo_Prog(e.no_program);
                        setDropProgram(false);
                      }}
                    />
                  ) : (
                    <HiOutlineChevronDown
                      className="w-5 h-5 mx-2  cursor-pointer"
                      onClick={() => {
                        setNo_Prog(e.no_program);
                        setDropProgram(true);
                      }}
                    />
                  )}
                </h1>
                <h1 className="px-6 py-3 w-[10rem]">{e.no_program}</h1>
                <h1 className="px-6 py-3 w-[50rem]">{e.item_program}</h1>
                <h1 className="px-12 py-3 w-[50rem]">
                  {e.total !== null ? currency(e.total) : "0"}
                </h1>
                <h1
                  className={`md:px-5 px-3 py-3 ${
                    role === "eksekutif" ? "hidden" : "w-[7rem]"
                  } `}
                >
                  <HiOutlinePencilSquare
                    className="w-6 h-6 cursor-pointer "
                    onClick={() => {
                      setNo_Prog(e.no_program);
                      setItm_program(e.item_program);
                      setFormEditProgram(!formEditProgram);
                    }}
                  />
                </h1>
                <h1
                  className={`md:px-5 px-16 py-3  ${
                    role === "eksekutif" ? "hidden" : "w-[7rem]"
                  } `}
                >
                  <HiOutlineTrash
                    className="w-6 h-6 cursor-pointer mx-auto"
                    onClick={() => {
                      setNo_Prog(e.no_program);
                      setDialogDeleteProgram(!dialogDeleteProgram);
                    }}
                  />
                </h1>
                <h1
                  className={`px-12 py-3  ${
                    role === "eksekutif" ? "hidden" : "w-[7rem]"
                  } `}
                ></h1>
              </div>
              <div
                className={`relative overflow-x-auto sm:rounded-lg my-5 ml-5 transition-all ease-in-out duration-100 ${
                  e.no_program === no_prog && dropProgram
                    ? "visible mt-0 bg-opacity-100 text-opacity-100"
                    : "bg-opacity-0 -mt-1 text-transparent invisible hidden"
                }`}
              >
                <div className="flex  text-xs text-gray-700 uppercase bg-gray-50">
                  <h1 className="px-6 py-3 w-[7rem]">Detail</h1>
                  <h1 className="px-6 py-3 w-[10rem]">No Komponen</h1>
                  <h1 className="px-6 py-3 w-[50rem]">Komponen</h1>
                  <h1 className="px-6 py-3 w-[50rem]">Total</h1>
                  <h1
                    className={`px-6 py-3  ${
                      role === "eksekutif" ? "hidden" : "w-[7rem]"
                    } `}
                  >
                    Edit
                  </h1>
                  <h1
                    className={`px-6 py-3  ${
                      role === "eksekutif" ? "hidden" : "w-[7rem]"
                    } `}
                  >
                    Delete
                  </h1>
                  <h1
                    className={`px-6 py-3 w-[7rem] bg-sky-400 hover:bg-sky-500 hover:text-slate-50 transition-colors ease-in-out duration-300 cursor-pointer ${
                      role === "eksekutif" ? "hidden" : "block"
                    }`}
                    onClick={() => {
                      setFormKomponen(!formKomponen);
                      setNoProgram(e.no_program);
                    }}
                  >
                    Tambah Komponen
                  </h1>
                </div>
                {komponen.map((e, id) => (
                  <div key={id}>
                    <div
                      key={id}
                      className="flex  w-full text-sm text-left text-gray-500 hover:bg-gray-100"
                    >
                      <h1 className="px-6 py-3 w-[7rem]">
                        {e.no_komponen === no_kom && dropKomponen ? (
                          <HiOutlineChevronUp
                            className="w-5 h-5 mx-2 cursor-pointer"
                            onClick={() => {
                              setNo_kom(e.no_komponen);
                              setDropKomponen(false);
                            }}
                          />
                        ) : (
                          <HiOutlineChevronDown
                            className="w-5 h-5 mx-2 cursor-pointer"
                            onClick={() => {
                              setNo_kom(e.no_komponen);
                              setDropKomponen(true);
                            }}
                          />
                        )}
                      </h1>
                      <h1 className="px-6 py-3 w-[10rem]">{e.no_komponen}</h1>
                      <h1 className="px-6 py-3 w-[50rem]">{e.item_komponen}</h1>
                      <h1 className="px-6 py-3 w-[50rem]">
                        {e.total !== null ? currency(e.total) : "0"}
                      </h1>
                      <h1
                        className={`px-6 py-3  ${
                          role === "eksekutif" ? "hidden" : "w-[7rem]"
                        } `}
                      >
                        <HiOutlinePencilSquare
                          className="w-6 h-6 cursor-pointer "
                          onClick={() => {
                            setNo_kom(e.no_komponen);
                            setItm_komponen(e.item_komponen);
                            setFormEditKomponen(!formEditKomponen);
                          }}
                        />
                      </h1>
                      <h1
                        className={`px-6 py-3  ${
                          role === "eksekutif" ? "hidden" : "w-[7rem]"
                        } `}
                      >
                        <HiOutlineTrash
                          className="w-6 h-6 cursor-pointer mx-auto"
                          onClick={() => {
                            setNo_kom(e.no_komponen);
                            setDialogDeleteKomponen(!dialogDeleteKomponen);
                          }}
                        />
                      </h1>
                      <h1
                        className={`px-12 py-3  ${
                          role === "eksekutif" ? "hidden" : "w-[7rem]"
                        } `}
                      ></h1>
                    </div>
                    <div
                      className={`relative overflow-x-auto sm:rounded-lg my-5 ml-5 ${
                        e.no_komponen === no_kom && dropKomponen
                          ? "block"
                          : "hidden"
                      }`}
                    >
                      <div className="flex  text-xs text-gray-700 uppercase bg-gray-50">
                        <h1 className="px-6 py-3 w-[7rem]">Detail</h1>
                        <h1 className="px-6 py-3 w-[10rem]">No Kegiatan</h1>
                        <h1 className="px-6 py-3 w-[50rem] ">Kegiatan</h1>
                        <h1 className="px-6 py-3 w-[50rem] ">Total</h1>
                        <h1 className="px-6 py-3 w-[7rem">Rincian</h1>
                        <h1
                          className={`px-6 py-3  ${
                            role === "eksekutif" ? "hidden" : "w-[7rem]"
                          } `}
                        >
                          Edit
                        </h1>
                        <h1
                          className={`px-6 py-3  ${
                            role === "eksekutif" ? "hidden" : "w-[7rem]"
                          } `}
                        >
                          Delete
                        </h1>
                        <h1
                          className={`px-6 py-3 md:w-[7rem] bg-sky-400 hover:bg-sky-500 hover:text-slate-50 transition-colors ease-in-out duration-300 cursor-pointer ${
                            role === "eksekutif" ? "hidden" : "block"
                          }`}
                          onClick={() => {
                            setFormKegiatan(!formKegiatan);
                            setNoProgram(e.no_program);
                            setNoKomponen(e.component_id);
                          }}
                        >
                          Tambah Kegiatan
                        </h1>
                      </div>
                      {kegiatan.map((e, id) => (
                        <div key={id}>
                          <div
                            key={id}
                            className="flex  w-full text-sm text-left text-gray-500 hover:bg-gray-100"
                          >
                            <h1 className="px-6 py-3 w-[8rem]">
                              {e.no_kegiatan === no_keg && dropKegiatan ? (
                                <HiOutlineChevronUp
                                  className="w-5 h-5 mx-2 cursor-pointer"
                                  onClick={() => {
                                    setNo_keg(e.activity_id);
                                    setDropKegiatan(false);
                                  }}
                                />
                              ) : (
                                <HiOutlineChevronDown
                                  className="w-5 h-5 mx-2 cursor-pointer"
                                  onClick={() => {
                                    setNo_keg(e.activity_id);
                                    setDropKegiatan(true);
                                  }}
                                />
                              )}
                            </h1>
                            <h1 className="px-6 py-3 w-[10rem]">
                              {e.no_kegiatan}
                            </h1>
                            <h1 className="px-6 py-3 w-[50rem]">
                              {e.item_kegiatan}
                            </h1>
                            <h1 className="px-6 py-3 w-[50rem]">
                              {e.total !== null ? currency(e.total) : "0"}
                            </h1>
                            <div
                              onClick={() => {
                                dispatch(
                                  allRincianKegiatan({
                                    tahun_ajar: tahun_ajar,
                                    no_kegiatan: e.no_kegiatan,
                                  })
                                );
                                setRinci(true);
                                setNo_keg(e.activity_id);
                                setNo_sub(e.no_sub_kegiatan);
                                setDialogRincian(!dialogRincian);
                              }}
                              className={`md:px-9 px-14 py-3 w-auto cursor-pointer `}
                            >
                              <HiOutlinePlus className="w-6 h-6" />
                            </div>
                            <h1
                              className={`md:px-6 py-3  ${
                                role === "eksekutif" ? "hidden" : "w-[7rem]"
                              } `}
                            >
                              <HiOutlinePencilSquare
                                className="w-6 h-6 cursor-pointer "
                                onClick={() => {
                                  setNo_keg(e.activity_id);
                                  setItm_kegiatan(e.item_kegiatan);
                                  setFormEditKegiatan(!formEditKegiatan);
                                }}
                              />
                            </h1>
                            <h1
                              className={`md:px-6 px-16 py-3  ${
                                role === "eksekutif" ? "hidden" : "w-[7rem]"
                              } `}
                            >
                              <HiOutlineTrash
                                className="w-6 h-6 cursor-pointer mx-auto"
                                onClick={() => {
                                  setNo_keg(e.activity_id);
                                  setDialogDeleteKegiatan(
                                    !dialogDeleteKegiatan
                                  );
                                }}
                              />
                            </h1>
                            <h1
                              className={`px-12 py-3  ${
                                role === "eksekutif" ? "hidden" : "w-[7rem]"
                              } `}
                            ></h1>
                          </div>
                          <div
                            className={`relative overflow-x-auto sm:rounded-lg my-5 ml-5 ${
                              e.no_kegiatan === no_keg && dropKegiatan
                                ? "block"
                                : "hidden"
                            }`}
                          >
                            <div className="flex  text-xs text-gray-700 uppercase bg-gray-50">
                              <h1 className="px-6 py-3 w-[7rem]">Rincian</h1>
                              <h1 className="px-6 py-3 w-[10rem]">
                                No Sub Kegiatan
                              </h1>
                              <h1 className="px-6 py-3 w-[50rem]">
                                Sub Kegiatan
                              </h1>
                              <h1 className="px-6 py-3 w-[50rem]">Total</h1>
                              <h1
                                className={`px-6 py-3  ${
                                  role === "eksekutif"
                                    ? "hidden"
                                    : "w-[7rem]"
                                } `}
                              >
                                Edit
                              </h1>
                              <h1
                                className={`px-6 py-3  ${
                                  role === "eksekutif"
                                    ? "hidden"
                                    : "w-[7rem]"
                                } `}
                              >
                                Delete
                              </h1>
                              <h1
                                className={`px-6 py-3 w-[7rem] bg-sky-400 hover:bg-sky-500 hover:text-slate-50 transition-colors ease-in-out duration-300 cursor-pointer ${
                                  role === "eksekutif" ? "hidden" : "block"
                                }`}
                                onClick={() => {
                                  setFormSub(!formSub);
                                  setNoKomponen(e.component_id);
                                  setNo_keg(e.activity_id);

                                }}
                              >
                                Tambah Sub
                              </h1>
                            </div>
                            {subKegiatan.map((e, id) => (
                              <div key={id}>
                                <div
                                  key={id}
                                  className="flex  w-full text-sm text-left text-gray-500 hover:bg-gray-100"
                                  onClick={() => {
                                    setNo_sub(e.no_sub_kegiatan);
                                  }}
                                >
                                  <h1 className="px-6 py-3 w-[8rem]">
                                    {e.no_kegiatan === no_keg ? (
                                      <HiOutlineChevronDown
                                        className="w-5 h-5 mx-2 cursor-pointer"
                                        onClick={() => {
                                          setNo_sub(e.no_sub_kegiatan);
                                          dispatch(
                                            allRincianKegiatan({
                                              tahun_ajar: tahun_ajar,
                                              no_kegiatan: e.no_kegiatan,
                                            })
                                          );
                                          setDialogRincian(!dialogRincian);
                                          setRinci(false);
                                        }}
                                      />
                                    ) : (
                                      <HiOutlineChevronDown className="w-5 h-5 mx-2 cursor-pointer" />
                                    )}
                                  </h1>
                                  <h1 className="px-6 py-3 w-[10rem]">
                                    {e.no_sub_kegiatan}
                                  </h1>
                                  <h1 className="px-6 py-3 w-[50rem]">
                                    {e.item_sub_kegiatan}
                                  </h1>
                                  <h1 className="px-6 py-3 w-[50rem]">
                                    {e.total !== null ? currency(e.total) : "0"}
                                  </h1>
                                  <h1
                                    className={`px-6 py-3  ${
                                      role === "eksekutif"
                                        ? "hidden"
                                        : "w-[7rem]"
                                    } `}
                                  >
                                    <HiOutlinePencilSquare
                                      className="w-6 h-6 cursor-pointer "
                                      onClick={() => {
                                        setNo_sub(e.no_sub_kegiatan);
                                        setItm_sub(e.item_sub_kegiatan);
                                        setFormEditSub(!formEditSub);
                                      }}
                                    />
                                  </h1>
                                  <h1
                                    className={`px-6 py-3  ${
                                      role === "eksekutif"
                                        ? "hidden"
                                        : "w-[7rem]"
                                    } `}
                                  >
                                    <HiOutlineTrash
                                      className="w-6 h-6 cursor-pointer mx-auto"
                                      onClick={() => {
                                        setNo_sub(e.no_sub_kegiatan);
                                        setDialogDeleteSub(!dialogDeleteSub);
                                      }}
                                    />
                                  </h1>
                                  <h1
                                    className={`px-12 py-3  ${
                                      role === "eksekutif"
                                        ? "hidden"
                                        : "w-[7rem]"
                                    } `}
                                  ></h1>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Anggaran;
