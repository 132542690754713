import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiProker from "../../api/proker";

export const addLembaga = createAsyncThunk(
  "lembaga/add",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.postLembaga(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const addProgram = createAsyncThunk(
  "program/add",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.postProgram(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const addKomponen = createAsyncThunk(
  "komponen/add",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.postKomponen(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const addKegiatan = createAsyncThunk(
  "kegiatan/add",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.postKegiatan(data.activity);
      if (res.status === 200) {
        const send = await ApiProker.addQuisioener({
          activity_id: res.data?.message,
          answer: data.answer_list,
        });
        if (send?.status === 200) {
          return send.data;
        }
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const addSubKegiatan = createAsyncThunk(
  "subKegiatan/add",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.posSubKegiatan(data.sub);
      if (res.status === 200) {
        const send = await ApiProker.addQuisioener({
          activity_id: res.data?.message,
          answer: data.answer_list,
        });
        if (send?.status === 200) {
          return send.data;
        }
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const addRincian = createAsyncThunk(
  "rincian/add",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.postRincian(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const updateLembaga = createAsyncThunk(
  "lembaga/edit",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.putLembaga(data.no_lem, data.edit);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const updateProgram = createAsyncThunk(
  "program/edit",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.putProgram(data.no_prog, data.edit);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const updateKomponen = createAsyncThunk(
  "komponen/edit",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.putKomponen(data.no_kom, data.edit);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const updateKegiatan = createAsyncThunk(
  "Kegiatan/edit",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.putKegiatan(data.no_keg, data.edit);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const updateSubKegiatan = createAsyncThunk(
  "SubKegiatan/edit",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.putSubKegiatan(data.no_sub, data.edit);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const updateDetail = createAsyncThunk(
  "detail/edit",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.putDetail(data.id, data.data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const destroyLembaga = createAsyncThunk(
  "lembaga/delete",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.deleteLembaga(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const destroyProgram = createAsyncThunk(
  "Program/delete",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.deleteProgram(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const destroyKomponen = createAsyncThunk(
  "komponen/delete",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.deleteKomponen(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const destroyKegiatan = createAsyncThunk(
  "Kegiatan/delete",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.deleteKegiatan(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const destroySubKegiatan = createAsyncThunk(
  "SubKegiatan/delete",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.deleteSubKegiatan(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const destroyRincian = createAsyncThunk(
  "rincian/destroy",
  async (uuid, { rejectWithValue }) => {
    try {
      const res = await ApiProker.deleteRincian(uuid);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const addPersetujuan = createAsyncThunk(
  "proker/persetujuan",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.postPersetujuan(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const postNewAnggaran = createAsyncThunk(
  "proker/new/anggaran",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProker.addNewAnggaran(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
// export const postQuisioner = createAsyncThunk(
//   "quisioner/add",
//   async (data, { rejectWithValue }) => {
//     try {
//       const res = await ApiProker.addQuisioener(data);
//       if (res.status === 200) {
//         return res.data;
//       }
//     } catch (err) {
//       return rejectWithValue(err.response.data.message);
//     }
//   }
// );
