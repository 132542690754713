import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useGetPendapatan from "../hooks/useGetPendapatan";

export default function usePendapatanOptions(lembaga, tahun, status, trigger) {
  const { pendapatanAll } = useSelector((state) => state.pendapatan);
  const [allPendapatan, setAllPendapatan] = useState([]);
  useGetPendapatan(lembaga, tahun, status, trigger);
  useEffect(() => {
    if (pendapatanAll.length > 0) {
      const allPendapatan = pendapatanAll.map((e) => {
        return { value: e.id, label: e.name };
      });
      setAllPendapatan(allPendapatan);
    } else {
      setAllPendapatan([]);
    }
  }, [pendapatanAll, lembaga, tahun, trigger]);
  return allPendapatan;
}
