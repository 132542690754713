import React from 'react'
import Dialog from './Dialog'

function DialogDelete(props) {
    return (
        <Dialog show={props.show} toggle={props.close}>
            <div className='bg-white md:w-[60vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Anda Yakin Ingin Menghapus?</h1>
                </div>
                <div className='p-5 w-full '>
                    <button onClick={props.click} className='border border-sky-400 w-full  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300'>Iya</button>
                </div>
            </div>
        </Dialog>
    )
}

export default DialogDelete