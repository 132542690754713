import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiPendapatan from "../../api/pendapatan";

export const addPendapatan = createAsyncThunk(
    'pendapatan/add',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiPendapatan.postPendapatan(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const addIncomeGroup = createAsyncThunk(
    'income/group/add',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiPendapatan.postGroupPendapatan(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getAllPendapatan = createAsyncThunk(
    'pendapatan/all',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiPendapatan.getPendapatan(data.lembaga,data.tahun,data.status)
            if(res.status === 200){
                return res.data
            }
        }
        catch(_){
            return rejectWithValue("error")
        }
    }
)
export const getIncomeGroup = createAsyncThunk(
    'group-income/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiPendapatan.getAllGroupPendapatan()
            if(res.status === 200){
                return res.data
            }
        }
        catch(_){
            return rejectWithValue("error")
        }
    }
)
export const getAllPendapatanByLem = createAsyncThunk(
    'pendapatan/all/lem',
    async(no_lem,{rejectWithValue})=>{
        try{
            const res = await ApiPendapatan.getPendapatanByLem(no_lem)
            if(res.status === 200){
                return res.data
            }
        }
        catch(_){
            return rejectWithValue("error")
        }
    }
)
export const getOneByNoPendapatan = createAsyncThunk(
    'pendapatan/one',
    async(no,{rejectWithValue})=>{
        try{
            const res = await ApiPendapatan.getPendapatanByNoPendapatan(no)
            if(res.status === 200){
                return res.data
            }
        }
        catch(_){
            return rejectWithValue("error")
        }
    }
)
export const updatePendapatan = createAsyncThunk(
    'pendapatan/update',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiPendapatan.putPendapatan(data.uuid,data.edit)
            if(res.status === 200){
                return res.data
            }
        }
        catch(_){
            return rejectWithValue("error")
        }
    }
)
export const destroyPendapatan = createAsyncThunk(
    'pendapatan/destroy',
    async(uuid,{rejectWithValue})=>{
        try{
            const res = await ApiPendapatan.deletePendapatan(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(_){
            return rejectWithValue("error")
        }
    }
)
export const destroyRincianPendapatan = createAsyncThunk(
    'rincian/pendapatan/destroy',
    async(uuid,{rejectWithValue})=>{
        try{
            const res = await ApiPendapatan.deleteRincianPendapatan(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(_){
            return rejectWithValue("error")
        }
    }
)