import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiAuth from "../../api/auth";

export const updateUser = createAsyncThunk(
    'user/update',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiAuth.putUser(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.message)
        }
    }
)
export const getAllUser = createAsyncThunk(
    'user/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiAuth.getUser(_)
            if(res.status === 200){
                return res.data
            }
        }
        catch(_){
            return rejectWithValue("error")
        }
    }
)
export const addUser = createAsyncThunk(
    'user/add',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiAuth.postUser(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue("error")
        }
    }
)
export const editUserByAdmin = createAsyncThunk(
    'user/admin/edit',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiAuth.putUserByAdmin(data.uuid,data.edit)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue("error")
        }
    }
)
export const postSistem = createAsyncThunk(
    'sistem/add',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiAuth.addSistem(data.uuid,data.post)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.message)
        }
    }
)
export const destroySistem = createAsyncThunk(
    'sistem/delete',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiAuth.deleteSistem(data.uuid,data.uuid_sis)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.message)
        }
    }
)
export const editSistemOne = createAsyncThunk(
    'sistem/edit/one',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiAuth.updateSistem(data.uuid,data.uuid_sistem,data.update)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.message)
        }

    }
)
export const destroyUser = createAsyncThunk(
    'user/delete',
    async (uuid, { rejectWithValue }) => {
        try {
            const res = await ApiAuth.deleteUser(uuid)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.message)
        }
    }
)
