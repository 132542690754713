import React, { useEffect, useState } from "react";
import { HiChevronDoubleDown } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import HeaderUi from "../component/HeaderUi";
import Table from "../component/Table";
import { allKegiatanRinci, allRincianKegiatan } from "../store/actions/program";
import DialogRincian from "../component/DialogRincian";
import Loader from "../component/Loader";
import { currency } from "../component/Currency";
import { allLembaga, downloadRekapRencana } from "../store/actions/loadProgram";
import { addPersetujuan } from "../store/actions/proker";
import SnackBar from "../component/Snackbar";
import Button from "../component/Button";
import DialogUrl from "../component/DialogUrl";
import ReactSelect from "react-select";
import Dialog from "../component/Dialog";
import useLembagaApakahOptions from "../options/lembaga";
import useGetKegiatanByStatus from "../hooks/useGetKegiatanByStatus";
import useGetLembagaApakah from "../hooks/useGetLembagaApakah";

function RencanaKerja() {
  const dispatch = useDispatch();
  const { rincian, loadingProgram } = useSelector((state) => state.program);
  const { msgProker } = useSelector((state) => state.proker);
  const { rekap, thn_ajar } = useSelector((state) => state.loadProgram);
  const { role, lembagaAuth } = useSelector((state) => state.auth);
  const [dialogRincian, setDialogRincian] = useState(false);
  const [dialogUrl, setDialogUrl] = useState(false);
  const [snack, setSnack] = useState(false);
  const [showApprove, setshowApprove] = useState(false);
  const [lem, setLem] = useState({});
  const [approve, setApprove] = useState({});
  const [selectedAnggaran, setSelectedAnggaran] = useState([]);
  const lembagaApakahOptions = useLembagaApakahOptions();
  useGetKegiatanByStatus(lem?.value,approve?.value,snack)
  const rekapDownload = () => {
    dispatch(downloadRekapRencana(lem.value));
    setDialogUrl(true);
  };
  const click = () => {
    window.open(rekap.url);
  };
  const approveOptions = [
    { value: 0, label: "Ditunda" },
    { value: 1, label: "Disetujui" },
    { value: 2, label: "Ditolak" },
  ];
  const handleSelectAnggaran = (value) => {
    if (
      !selectedAnggaran.includes(
        selectedAnggaran?.find((x) => x?.id === value?.id)
      )
    ) {
      setSelectedAnggaran([
        ...selectedAnggaran,
        {
          id: value.id,
          total: value.total,
          status:approve?.value
        },
      ]);
    } else {
      setSelectedAnggaran(
        selectedAnggaran.filter((r) => r.id !== value.id)
      );
    }
  };
  const sendApproveAnggaran = (value) => {
    dispatch(
      addPersetujuan({
        all_activity: selectedAnggaran,
        new_status: value,
      })
    );
    setshowApprove(false);
    setSnack(true);
    setSelectedAnggaran([]);
  };
  return (
    <div>
      <Loader show={loadingProgram} size="w-5 h-5" />
      <SnackBar show={snack} toggle={setSnack} msg={msgProker} />
      <DialogUrl show={dialogUrl} close={setDialogUrl} onClick={click} />
      <DialogRincian
        show={dialogRincian}
        close={setDialogRincian}
        onClick={() => {
          setDialogRincian(!dialogRincian);
        }}
        classFirst="hidden"
      />
      <Dialog show={showApprove} toggle={() => setshowApprove(false)}>
        <div className="bg-white md:w-[60vw] w-[95vw]">
          <div className="py-4 px-6 border-b border-slate-200"></div>
          <div className="p-5 w-full ">
            <div className="my-5">
              <h1>
                Total Anggaran Yang dipilih :{" "}
                {currency(
                  selectedAnggaran
                    ?.map((e) => +e?.total)
                    .reduce((a, b) => a + b, 0)
                )}
              </h1>
            </div>
            <div className="flex gap-5">
              <button
                onClick={() => sendApproveAnggaran(1)}
                className="border border-sky-400 w-full  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300"
              >
                Setujui
              </button>
              <button
                onClick={() => sendApproveAnggaran(2)}
                className="border border-red-400 w-full  px-8 py-2 hover:text-red-800 hover:border-red-600 transition-colors ease-in-out duration-300"
              >
                Tolak
              </button>
            </div>
          </div>
        </div>
        <div></div>
      </Dialog>
      <HeaderUi titleHeader="Status Anggaran" location="List" />
      <Table
        classSelect={"block"}
        // classSelect={role === "admin-lembaga" ? "invisible" : "block"}
        titleFirst="Tambah"
        optSelect={lembagaApakahOptions}
        setChange={setLem}
        change={lem}
        classFirst="hidden"
        filter={
          <div className="w-72">
            <label className="text-gray-700">Status</label>
            <ReactSelect
              options={approveOptions}
              onChange={(e) => setApprove(e)}
              value={approve}
            />
          </div>
        }
      >
        <div className={`flex flex-col justify-end items-end mr-4`}></div>
        <div className="flex justify-end my-2 gap-2">
          <Button title="download PDF" click={rekapDownload} />
          {rincian?.length > 0 && (
            <Button
              title="Pilih Semua"
              click={() => {
                setSelectedAnggaran(
                  rincian?.map((value) => {
                    return {
                      id: value.id,
                      total: value.total,
                      status:approve?.value
                    };
                  })
                );
                setshowApprove(true);
              }}
            />
          )}
          {selectedAnggaran.length > 0 ? (
            <Button title="Aksi" click={() => setshowApprove(true)} />
          ) : (
            <></>
          )}
        </div>
        {rincian.length === 0 ? (
          <div>
            <h1 className="font-light text-center my-10 text-xl">
              Tidak Ada Kegiatan
            </h1>
          </div>
        ) : (
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-3 pb-2">
            <table className="text-sm text-left text-gray-500 w-[100vw]">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nama Kegiatan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Rutin
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Bobot
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Total
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Pilih
                  </th>
                </tr>
              </thead>
              <tbody>
                {rincian?.map((d, id) => (
                  <tr
                    className={`bg-white border-b hover:bg-gray-100 `}
                    key={id}
                  >
                    <td className="px-6 py-3">{id + 1}</td>
                    <td className="px-6 py-3">{d.name}</td>
                    <td className="px-6 py-3">
                      {d.continue ? "Iya" : "Tidak"}
                    </td>
                    <td className="px-6 py-3">{d.weight}</td>
                    <td className="px-6 py-3">
                      {d.total ? currency(d.total) : currency(0)}
                    </td>
                    <td className="px-6 py-3">{approve?.label}</td>
                    <td className="px-6 py-3">
                      <input
                        type="checkbox"
                        checked={selectedAnggaran.includes(
                          selectedAnggaran?.find((x) => x?.id === d.id)
                        )}
                        onClick={() => handleSelectAnggaran(d)}
                        readOnly
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="flex justify-end">
          <div className="flex gap-3">
            Total Rincian :{" "}
            <p className="text-blue-700">
              {rincian?.length > 0 ? currency(rincian?.reduce((a,b)=>a+b?.total,0)) : currency(0)}
            </p>
          </div>
        </div>
      </Table>
    </div>
  );
}

export default RencanaKerja;
