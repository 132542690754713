import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useGetProgram from "../hooks/useGetProgram";

export default function useProgramOptions(lembaga,tahun,trigger) {
  const { program } = useSelector((state) => state.loadProgram);
  const [allProgramOptions, setAllProgramOptions] = useState([]);
  useGetProgram(lembaga,tahun,trigger)
  useEffect(() => {
    if (program.length > 0) {
      const allProgram = program.map((e) => {
        return { value: e.id, label: e.item };
      });
      setAllProgramOptions(allProgram);
    }
  }, [program,lembaga,tahun,trigger]);
  return allProgramOptions;
}
