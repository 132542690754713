import api from "./http";

const ApiLoadProgram = {
  getLembaga: () => {
    return api.get(`/apakah/institution`);
  },
  getProgram: (institution_number, academic_year) => {
    return api.get(`/apakah/program/${institution_number}?academic_year=${academic_year}`);
  },
  getProgramBylem: (academic_year) => {
    return api.get(`/apakah/program?academic_year=${academic_year}`);
  },
  getKomponen: (program_id) => {
    return api.get(`/apakah/component/${program_id}`);
  },
  getKegiatan: (komponenId) => {
    return api.get(`/apakah/activity/${komponenId}`);
  },
  getSubKegiatan: (activity_id) => {
    return api.get(`/apakah/sub-activity/${activity_id}`);
  },
  getSatuan: () => {
    return api.get(`/apakah/unit`);
  },
  getRincianKegiatan: (kegiatanId) => {
    return api.get(`/apakah/detail-of-activity/${kegiatanId}`);
  },
  getRincianSubKegiatan: (subKegiatanId) => {
    return api.get(`/apakah/detail-of-activity/sub/${subKegiatanId}`);
  },
  sistem: () => {
    return api.get(`/system`);
  },
  role: (uuid) => {
    return api.get(`/role/${uuid}`);
  },
  tahunActive: () => {
    return api.get(`/year-active-system?system=${`apakah`}`);
  },
  tahunAjr:()=>{
    return api.get(`/accounting-year`)
  },
  getRekapRencana: (no_lembaga) => {
    return api.get(`/apakah/list-lembaga/download-rekap/${no_lembaga}`);
  },
  getQuisioner:()=>{
    return api.get(`/apakah/weight-activity/question`)
  },
};
export default ApiLoadProgram;
