import React, { useEffect, useState } from "react";
import HeaderUi from "../component/HeaderUi";
import { useDispatch, useSelector } from "react-redux";
import {
  addSharingProgram,
  deleteSharingProgram,
  getAllSharingProgram,
  updateSharingProgram,
} from "../store/actions/sharing";
import { HiOutlinePencilSquare, HiOutlineTrash } from "react-icons/hi2";
import Table from "../component/Table";
import FormAddSharingProgram from "../component/FormAddSharingProgram";
import Loader from "../component/Loader";
import SnackBar from "../component/Snackbar";
import DialogDelete from "../component/DialogDelete";

export default function SharingProgram() {
  const dispatch = useDispatch();
  const { allSharingProgram, loadingSharingProgram, messageSharingProgram } =
    useSelector((state) => state.sharingProgram);
  const [showAddSharingProgram, setshowSharingProgram] = useState(false);
  const [showDeleteSharingProgram,setshowDeleteSharingProgram] = useState(false)
  const [saveOneUuidSharingProgram, setSaveUuidSharingProgram] = useState({});
  const [showMessage, setShowMessage] = useState("");
  useEffect(() => {
    dispatch(getAllSharingProgram());
  }, [showMessage]);

  const saveSharingProgram = (data) => {
    if (Object.keys(saveOneUuidSharingProgram)?.length === 0) {
      dispatch(addSharingProgram(data));
    } else {
      dispatch(updateSharingProgram({ uuid: saveOneUuidSharingProgram.uuid, data:data }));
    }
    setshowSharingProgram(false);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };

  const removeSharingProgram = ()=>{
    dispatch(deleteSharingProgram(saveOneUuidSharingProgram.uuid))
    setshowDeleteSharingProgram(false)
  }
  return (
    <div>
      <Loader show={loadingSharingProgram} />
      <SnackBar
        show={showMessage}
        toggle={setShowMessage}
        msg={messageSharingProgram}
      />
      <FormAddSharingProgram
        show={showAddSharingProgram}
        exitForm={setshowSharingProgram}
        submit={saveSharingProgram}
        data={saveOneUuidSharingProgram}
      />
      <DialogDelete show={showDeleteSharingProgram} close={setshowDeleteSharingProgram} onClick={removeSharingProgram} />
      <HeaderUi titleHeader="Program Bersama" location={"list"} />
      <Table
        classSelect="hidden"
        titleFirst="Tambah"
        clickFirst={() => {
          setSaveUuidSharingProgram({});
          setshowSharingProgram(!showAddSharingProgram);
        }}
      >
        {allSharingProgram?.length === 0 ? (
          <div>
            <h1 className="font-light text-center my-10 text-xl">
              Tidak Ada Program
            </h1>
          </div>
        ) : (
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-3 pb-2">
            <table className="text-sm text-left text-gray-500 w-full">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-3 py-3">
                    No
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Nama Program
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Edit
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {allSharingProgram?.map((d, id) => (
                  <tr
                    className={`bg-white border-b hover:bg-gray-100 `}
                    key={id}
                  >
                    <td className="px-3 py-3">{id + 1}</td>
                    <td className="px-4 py-3">{d.name}</td>
                    <td className="px-6 py-3">
                      <HiOutlinePencilSquare
                        className="w-5 h-5 cursor-pointer mx-1"
                        onClick={() => {
                          setSaveUuidSharingProgram({uuid:d.uuid,name:d.name});
                          setshowSharingProgram(!showAddSharingProgram);
                        }}
                      />
                    </td>
                    <td className="px-6 py-3">
                      <HiOutlineTrash
                        className="w-5 h-5 cursor-pointer mx-3"
                        onClick={() => {
                            setSaveUuidSharingProgram({uuid:d.uuid,name:d.name});
                            setshowDeleteSharingProgram(!showDeleteSharingProgram)
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Table>
    </div>
  );
}
