import React, { useEffect, useState } from "react";
import Dialog from "./Dialog";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AddButton from "./AddButton";
import TextArea from "./TextArea";
import Radio from "./Radio";

function FormEditKegiatan(props) {
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Tidak Boleh Kosong"),
        continue_activity: yup
          .string()
          .oneOf(["iya", "tidak"], "Pilih salah satu")
          .required("Tidak Boleh Kosong"),
      })
    ),
  });
  useEffect(() => {
    method.reset({ name: props.activityName.name,continue_activity:props.activityName?.continue ? "iya":"tidak" });
  }, [props.activityName]);

  return (
    <Dialog show={props.show} toggle={props.close}>
      <div className="bg-white md:w-[60vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl"> Edit Kegiatan</h1>
        </div>
        <form
          onSubmit={method.handleSubmit(props.submit)}
          className="px-6 py-7 space-y-1 "
        >
          <div className=" md:space-x-5 flex md:flex-row flex-col">
            <TextArea
              label="Kegiatan"
              method={method}
              methodName="name"
              className="md:w-[70vw]"
            />
          </div>
          <div className="mb-1">
            <h1 className="font-light text-lg mb-2">Kegiatan Rutin</h1>
            <div className="flex my-auto md:space-x-0 space-x-2">
              <Radio
                method={method}
                methodName={`continue_activity`}
                value="iya"
                label="Iya"
              />
              <Radio
                method={method}
                methodName={`continue_activity`}
                value="tidak"
                label="Tidak"
              />
            </div>
            {method.formState.errors[`continue_activity`] && (
              <p className="text-red-600 mt-1 mx-1">
                {method.formState.errors[`continue_activity`].message}
              </p>
            )}
          </div>
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}

export default FormEditKegiatan;
