import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiLoadProgram from "../../api/loadProgram";

export const allLembaga = createAsyncThunk(
    'lembaga/all',
    async (_, { rejectWithValue }) => {
        try {
            const res = await ApiLoadProgram.getLembaga()
            if (res.status === 200) {
                return res.data
            }
        }
        catch (_) {
            return rejectWithValue("error")
        }
    }
)
export const allProgram = createAsyncThunk(
    'program/all',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiLoadProgram.getProgram(data.no_lem,data.tahun)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (_) {
            return rejectWithValue("error")
        }
    }
)
export const allProgramByLem = createAsyncThunk(
    'program/lem/all',
    async (tahun, { rejectWithValue }) => {
        try {
            const res = await ApiLoadProgram.getProgramBylem(tahun)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (_) {
            return rejectWithValue("error")
        }
    }
)
export const allKomponen = createAsyncThunk(
    'subprogram/all',
    async (programId, { rejectWithValue }) => {
        try {
            const res = await ApiLoadProgram.getKomponen(programId)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (_) {
            return rejectWithValue("error")
        }
    }
)
export const allKegiatan = createAsyncThunk(
    'kegiatan/all',
    async (komponenId, { rejectWithValue }) => {
        try {
            const res = await ApiLoadProgram.getKegiatan(komponenId)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (_) {
            return rejectWithValue("error")
        }
    }
)
export const allSubKegiatan = createAsyncThunk(
    'subKegiatan/all',
    async (kegiatanId, { rejectWithValue }) => {
        try {
            const res = await ApiLoadProgram.getSubKegiatan(kegiatanId)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (_) {
            return rejectWithValue("error")
        }
    }
)
export const allRincianKegiatan = createAsyncThunk(
    'rincian/all',
    async (kegiatanId, { rejectWithValue }) => {
        try {
            const res = await ApiLoadProgram.getRincianKegiatan(kegiatanId)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (_) {
            return rejectWithValue("error")
        }
    }
)
export const allRincianSubKegiatan = createAsyncThunk(
    'sub/rincian/all',
    async (subKegiatanId, { rejectWithValue }) => {
        try {
            const res = await ApiLoadProgram.getRincianSubKegiatan(subKegiatanId)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (_) {
            return rejectWithValue("error")
        }
    }
)
export const allSatuan = createAsyncThunk(
    'satuan/all',
    async (_, { rejectWithValue }) => {
        try {
            const res = await ApiLoadProgram.getSatuan()
            if (res.status === 200) {
                return res.data
            }
        }
        catch (_) {
            return rejectWithValue("error")
        }
    }
)
export const getSistem = createAsyncThunk(
    'sistem/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiLoadProgram.sistem()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getRole = createAsyncThunk(
    'role/all',
    async(uuid,{rejectWithValue})=>{
        try{
            const res = await ApiLoadProgram.role(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getThnAjar = createAsyncThunk(
    'thnAjara/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiLoadProgram.tahunAjr()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getTahunActive = createAsyncThunk(
    'thnActive/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiLoadProgram.tahunActive()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const downloadRekapRencana = createAsyncThunk(
    'rekap/rencana',
    async(no_lembaga,{rejectWithValue})=>{
        try{
            const res = await ApiLoadProgram.getRekapRencana(no_lembaga)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const getAllQuisioner = createAsyncThunk(
    'quisioner/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiLoadProgram.getQuisioner()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)