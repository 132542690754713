import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Dialog from './Dialog'
import Input from './Input'
import * as yup from "yup"
import AddButton from './AddButton'
import useResetForm from '../hooks/custom/useResetForm'

function FormLembaga(props) {
    const method = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                name: yup.string().required("Lembaga Tidak Boleh Kosong"),
            })
        )
    })
    useResetForm(method,{name:''},props?.show)
    return (
        <Dialog show={props.show} toggle={props.close}>
            <div className='bg-white md:w-[40vw] w-[90vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'> Tambah Lembaga</h1>
                </div>
                <form onSubmit={method.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <div className='flex md:space-x-5'>
                        <Input method={method} methodName="name" label="Lembaga" className="md:w-[57.5vw]" />
                    </div>
                    <AddButton/>
                </form>
            </div>

        </Dialog>
    )
}

export default FormLembaga