import React, { useEffect } from "react";
import Dialog from "./Dialog";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "./Input";
import AddButton from "./AddButton";

export default function FormAddSharingProgram(props) {
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Nama program bersama tidak boleh kosong"),
      })
    ),
  });
  useEffect(() => {
    if (props.data && Object.keys(props.data)?.length !== 0) {
      method.reset({ name: props?.data?.name });
    } else {
      method.reset({ name: "" });
    }
  }, [props.data, props.show]);
  return (
    <Dialog show={props.show} toggle={props.exitForm}>
      <div className="bg-white md:w-[40vw] w-[90vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl"> Tambah Program Bersama</h1>
        </div>
        <form
          onSubmit={method.handleSubmit(props.submit)}
          className="px-6 py-7 space-y-1"
        >
          <div className="flex justify-between space-x-4">
            <div className="w-[100%]">
              <Input label="Nama Program" method={method} methodName="name" />
            </div>
          </div>
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}
