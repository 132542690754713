import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSistem } from "../store/actions/loadProgram";

export default function useGetSystem(trigger) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSistem());
  }, [trigger]);
}
