import ApiPencairan from "../../api/pencairan";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllPencairan = createAsyncThunk(
    'get-pencairan-anggaran',
    async(_,{rejectWithValue})=>{
        const res = await ApiPencairan.getAll()
        if(res.status === 200){
            return res.data
        }
    }
)

export const postPencairan = createAsyncThunk(
    'post-pencaiaran-anggaran',
    async(data,{rejectWithValue})=>{
        const res = await ApiPencairan.post(data)
        if(res.status === 200){
            return res.data
        }
    }
)