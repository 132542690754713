export default function Table({ head, children }) {
  return (
    <div className="overflow-y-auto h-[90%] scrollbar-hide">
      <table className="w-full text-sm text-left text-gray-700 ">
        <thead className="text-xs text-slate-700 uppercase bg-sky-100 sticky top-0">
          <tr>
            {head.map((d, id) => (
              <th key={id} scope="col" className="px-6 py-3">
                {d}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}
