import { createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import ApiAuth from "../../api/auth";



export const islogin = createAsyncThunk(
    'auth/login',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiAuth.login(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (_) {
            return rejectWithValue("Username Atau Password Salah")
        }
    }
)
export const isLogout = createAsyncThunk(
    'auth/logout',
    async (_, { rejectWithValue }) => {
        const navigate = useNavigate()
        try {
            const res = await ApiAuth.logout()
            if (res.status === 200) {
                navigate("/")
                return res.data
            }
        }
        catch (_) {
            return rejectWithValue("error")
        }
        finally {
            navigate("/")
        }
    }
)
