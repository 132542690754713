import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allProgram, allProgramByLem } from "../store/actions/loadProgram";

export default function useGetProgram(selectedLembaga, tahun, trigger) {
  const dispatch = useDispatch();
  const { role, lembaga } = useSelector((state) => state.auth);
  useEffect(() => {
    if (tahun) {
      if (selectedLembaga) {
        dispatch(allProgram({ no_lem: selectedLembaga, tahun }));
      } 
      // if (role !== "admin-lembaga" && selectedLembaga) {
      //   dispatch(allProgram({ no_lem: selectedLembaga, tahun }));
      // } else if (role === "admin-lembaga") {
      //   dispatch(allProgram({ no_lem: lembaga, tahun }));
      // }
    }
  }, [selectedLembaga, tahun, trigger]);
}
