import { createSlice } from "@reduxjs/toolkit";
import {
  getAllDashboard,
  allPengeluaran,
  getAllDashboardLembaga,
} from "../actions/dashboard";

export const dashboardStore = createSlice({
  name: "dashboard",
  initialState: {
    chartDashboard: [],
    pengeluaranAll: [],
    msgDashboard: "",
    loadingDashboard: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDashboard.pending, (state) => {
        state.loadingDashboard = true;
      })
      .addCase(getAllDashboard.fulfilled, (state, action) => {
        state.loadingDashboard = false;
        state.chartDashboard = action.payload;
      })
      .addCase(getAllDashboard.rejected, (state) => {
        state.loadingDashboard = false;
      })
      .addCase(getAllDashboardLembaga.pending, (state) => {
        state.loadingDashboard = true;
      })
      .addCase(getAllDashboardLembaga.fulfilled, (state, action) => {
        state.loadingDashboard = false;
        state.chartDashboard = action.payload;
      })
      .addCase(getAllDashboardLembaga.rejected, (state) => {
        state.loadingDashboard = false;
      })
      .addCase(allPengeluaran.pending, (state) => {
        state.loadingDashboard = true;
      })
      .addCase(allPengeluaran.fulfilled, (state, action) => {
        state.loadingDashboard = false;
        state.pengeluaranAll = action.payload;
      })
      .addCase(allPengeluaran.rejected, (state) => {
        state.loadingDashboard = false;
      });
  },
});
export default dashboardStore.reducer;
