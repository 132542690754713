import React, { useEffect, useState } from 'react'
import {HiOutlineXMark} from "react-icons/hi2"

function Dialog(props) {
    const [show, setShow] = useState(props.show)
    useEffect(() => {
        setShow(props.show)
    }, [props.show])
    return (
        <div className={`bg-black fixed top-12 left-0 z-10 w-full bg-opacity-10 h-full transition-all ease-in-out duration-300 ${show ? "visible" : "invisible"} overflow-y-auto`}>
            <div className='flex justify-center items-center'>
                <div className={`bg-white shadow-2xl relative rounded-md mx-1 z-50 ${props.classDialog}  ${show ? "scale-100" : "scale-0"} transition-all ease-in-out duration-700 ${props.scroll ? 'h-[90vh] overflow-y-auto':''}`}>
                    <div className={`absolute md:right-6 right-3 top-3 cursor-pointer transition-all ease-in-out duration-300 ${props.classExit}`} onClick={() => props.toggle(false)}><HiOutlineXMark className='w-8 h-8'/></div>
                    <div className={`${props.className}`}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dialog