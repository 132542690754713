import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useGetKomponen from "../hooks/useGetKomponen";

export default function useComponentOptions(programId,tahun,trigger) {
  const { komponen } = useSelector((state) => state.loadProgram);
  const [allComponentOptions, setAllComponentOptions] = useState([]);
  useGetKomponen(programId,tahun,trigger)
  useEffect(() => {
    if (komponen.length > 0) {
      const allKomponen = komponen.map((e) => {
        return { value: e.id, label: e.item };
      });
      setAllComponentOptions(allKomponen);
    }
  }, [komponen,programId,tahun,trigger]);
  return allComponentOptions;
}
