import React, { useEffect } from "react";
import Dialog from "./Dialog";
import AddButton from "./AddButton";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ArrayRincian from "./ArrayRincian";
import { destroyRincian } from "../store/actions/proker";
import useSatuanOptions from "../options/satuan";
import useResetForm from "../hooks/custom/useResetForm";
import usePendapatanOptions from "../options/pendapatan";

function FormRincian(props) {
  const dispatch = useDispatch();
  const {role,lembaga} = useSelector(state=>state.auth)
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        activity_id: yup.string().required("Tidak Boleh Kosong"),
        sub_activity_id: yup.string().nullable(),
        detail_of_activity_list: yup.array().of(
          yup.object().shape({
            description: yup.string().required("Tidak Boleh Kosong"),
            unit_id: yup.string().required("Tidak Boleh Kosong"),
            vol: yup.string().required("Tidak Boleh Kosong"),
            unit_price: yup.string().required("Tidak Boleh Kosong"),
            from: yup.number().required("Tidak Boleh Kosong"),
            until: yup
              .number()
              .min(yup.ref("from"), "Bulan Tidak Boleh Lebih Kecil")
              .required("Tidak Boleh Kosong"),
            total: yup.string().required("Tidak Boleh Kosong"),
            uuid: yup.string().nullable(),
            institution_income_id: yup.string().required("Tidak Boleh Kosong"),
            thawing_method: yup.string().required("Tidak Boleh Kosong"),
            post: yup.number().required("Tidak boleh kosong"),
            sharing_program:yup.number().required("Tidak boleh kosong")
          })
        ),
      })
    ),
    defaultValues: {
      sub_activity_id: null,
      detail_of_activity_list: [
        {
          description: "",
          unit_id: "",
          vol: "",
          unit_price: "",
          academic_year: "",
          from: "",
          until: "",
          total: "",
          income_id:"",
          thawing_method:"",
          post:0,
          uuid: null,
          institution_income_id:null
        },
      ],
    },
  });
  const { fields, append, remove,replace } = useFieldArray({
    control: method.control,
    name: "detail_of_activity_list",
  });
  const satuanOpt = useSatuanOptions(true);
  const institute = props?.lembaga?.value
  // const institute = role === 'admin-lembaga' ? lembaga : props?.lembaga?.value
  const pendapatanOptions = usePendapatanOptions(institute,props.tahun,3)
  const hapusRincian = (e, id) => {
    if (e.uuid !== null) {
      dispatch(destroyRincian(e.uuid));
      remove(id);
    } else {
      remove(id);
    }
  };
  useResetForm(
    method,
    {
      activity_id: props?.activityId,
      sub_activity_id: props?.subActivityId,
      detail_of_activity_list: [],
    },
    props?.show
  );
  useEffect(()=>{
    replace([])
  },[props?.show])
  return (
    <Dialog show={props.show} toggle={props.close} scroll>
      <div className="bg-white md:w-[80vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl"> Tambah Rincian</h1>
        </div>
        <form
          onSubmit={method.handleSubmit(props.submit)}
          className="px-6 space-y-1"
        >
          <h1 className="text-gray-600 text-xl">Rincian</h1>
          {fields.map((r, id) => (
            <div key={id}>
              <ArrayRincian
                methodRincian={method}
                id={id}
                satuanOpt={satuanOpt}
                show={props.show}
                pendapatanOpt={pendapatanOptions}
              />
              <button
                type="button"
                className="border border-red-700 px-7 py-2 my-2 hover:text-red-700 hover:border-red-900 transition-colors ease-in-out duration-300"
                onClick={() => hapusRincian(r, id)}
              >
                Hapus Rincian
              </button>
            </div>
          ))}
          <div className="my-2 ">
            <button
              type="button"
              onClick={() =>
                append({
                  description: "",
                  unit_id: "",
                  vol: "",
                  unit_price: "",
                  tahun: "",
                  from: "",
                  until: "",
                  total: "",
                  uuid: null,
                })
              }
              className="border border-sky-400 px-[1.37rem] py-2"
            >
              Tambah Rincian
            </button>
          </div>
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}

export default FormRincian;
