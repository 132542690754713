import { createSlice } from "@reduxjs/toolkit";
import { islogin, isLogout } from "../actions/auth";

export const authStore = createSlice({
    name:"auth",
    initialState:{
        token : "",
        nama : "",
        username : "",
        role : "",
        lembaga : "",
        generalUser : false,
        super_admin : false,
        loadingAuth : false,
        msgAuth : ""
    },
    extraReducers : builder => {
        builder
        .addCase(islogin.pending,(state)=>{
            state.loadingAuth = true
        })
        .addCase(islogin.fulfilled,(state,action)=>{
            state.loadingAuth = false
            state.token = action.payload.token
            state.nama = action.payload.user.nama
            state.username = action.payload.user.username
            state.role = action.payload.user.sistem?.role?.nama_role
            state.generalUser = action.payload.user.general_user
            state.super_admin =  action.payload.user.sistem.super_admin
            state.lembaga = action.payload.user?.apakahInstituion
        })
        .addCase(islogin.rejected,(state,action)=>{
            state.loadingAuth = false
            state.msgAuth = action.payload
        })
        .addCase(isLogout.pending,(state)=>{
            state.loadingAuth = true
        })
        .addCase(isLogout.fulfilled,(state)=>{
            state.loadingAuth = false
            state.token = ""
            state.username = ""
            state.role = ""
            state.lembaga = ""
            state.superAuth = false
        })
        .addCase(isLogout.rejected,(state)=>{
            state.loadingAuth = false
            state.token = ""
            state.username = ""
            state.role = ""
            state.lembaga = ""
            state.superAuth = false
        })
    }
})
export default authStore.reducer