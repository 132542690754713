import React, { useEffect, useState } from 'react'
import Dialog from './Dialog'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import AddButton from './AddButton'
import TextArea from './TextArea'

function FormEditKomponen(props) {
    const methodKomponen = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                item: yup.string().required("Tidak Boleh Kosong")
            })
        )
    })
    useEffect(()=>{
        methodKomponen.reset({item:props.componentName})
    },[props.componentName])
    return (
        <Dialog show={props.show} toggle={props.close}>
            <div className='bg-white md:w-[60vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'> Edit Komponen</h1>
                </div>
                <form onSubmit={methodKomponen.handleSubmit(props.submit)} className='px-6 py-7 space-y-1 '>
                    <div className=' md:space-x-5 flex md:flex-row flex-col'>
                        <TextArea label="Komponen" method={methodKomponen} methodName="item" className="md:w-[70vw]" />
                    </div>
                    <AddButton />
                </form>
            </div>
        </Dialog>
    )
}

export default FormEditKomponen