import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { allKomponen } from "../store/actions/loadProgram";

export default function useGetKomponen(programId,trigger) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (programId) {
      dispatch(allKomponen(programId));
    }
  }, [programId, trigger]);
}
