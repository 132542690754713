import api from "./http";

const ApiPencairan = {
  getAll: () => {
    return api.get("/apakah/disbursement-of-fund");
  },
  post: (data) => {
    return api.post("/apakah/disbursement-of-fund", data);
  },
};

export default ApiPencairan;
