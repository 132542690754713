import React, { useEffect, useState } from 'react'
import Dialog from './Dialog'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import AddButton from './AddButton'
import TextArea from './TextArea'

function FormEditSub(props) {
    const methodSubKegiatan = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                name: yup.string().required("Tidak Boleh Kosong")
            })
        )
    })
    useEffect(() => {
        methodSubKegiatan.reset({ name: props.subActivityName })
    }, [props.subActivityName])
    return (
        <Dialog show={props.show} toggle={props.close}>
            <div className='bg-white md:w-[60vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'> Edit Sub Kegiatan</h1>
                </div>
                <form onSubmit={methodSubKegiatan.handleSubmit(props.submit)} className='px-6 py-7 space-y-1 '>
                    <div className=' md:space-x-5 flex md:flex-row flex-col'>
                        <TextArea label="Sub Kegiatan" method={methodSubKegiatan} methodName="name" className="md:w-[70vw]" />
                    </div>
                    <AddButton />
                </form>
            </div>
        </Dialog>
    )
}

export default FormEditSub