import { createSlice } from "@reduxjs/toolkit";
import { allLembaga, allProgram, allKomponen, allKegiatan, allSatuan, allSubKegiatan, allRincianKegiatan, allProgramByLem, getSistem, getRole, getThnAjar, downloadRekapRencana, allRincianSubKegiatan, getTahunActive, getAllQuisioner } from "../actions/loadProgram";

export const loadProgramStore = createSlice({
    name: "program",
    initialState: {
        lembaga: [],
        program: [],
        komponen: [],
        kegiatan: [],
        subKegiatan: [],
        satuan: [],
        rincian: [],
        sistem: [],
        role: [],
        thn_ajar: [],
        thn_active:{},
        rekap : [],
        quisioner:[],
        loadingProgram: false,
        msgProgram : ""
    },
    extraReducers: builder => {
        builder
            .addCase(allLembaga.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(allLembaga.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.lembaga = action.payload
            })
            .addCase(allLembaga.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(allProgram.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(allProgram.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.program = action.payload
            })
            .addCase(allProgram.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(allKomponen.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(allKomponen.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.komponen = action.payload
            })
            .addCase(allKomponen.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(allKegiatan.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(allKegiatan.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.kegiatan = action.payload
            })
            .addCase(allKegiatan.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(allSubKegiatan.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(allSubKegiatan.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.subKegiatan = action.payload
            })
            .addCase(allSubKegiatan.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(allRincianKegiatan.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(allRincianKegiatan.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.rincian = action.payload
            })
            .addCase(allRincianKegiatan.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(allRincianSubKegiatan.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(allRincianSubKegiatan.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.rincian = action.payload
            })
            .addCase(allRincianSubKegiatan.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(allSatuan.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(allSatuan.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.satuan = action.payload
            })
            .addCase(allSatuan.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(allProgramByLem.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(allProgramByLem.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.program = action.payload
            })
            .addCase(allProgramByLem.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(getSistem.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(getSistem.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.sistem = action.payload
            })
            .addCase(getSistem.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(getRole.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(getRole.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.role = action.payload
            })
            .addCase(getRole.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(getThnAjar.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(getThnAjar.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.thn_ajar = action.payload
            })
            .addCase(getThnAjar.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(getTahunActive.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(getTahunActive.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.thn_active = action.payload
            })
            .addCase(getTahunActive.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(downloadRekapRencana.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(downloadRekapRencana.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.rekap = action.payload
            })
            .addCase(downloadRekapRencana.rejected, (state) => {
                state.loadingProgram = false
            })
            .addCase(getAllQuisioner.pending, (state) => {
                state.loadingProgram = true
            })
            .addCase(getAllQuisioner.fulfilled, (state, action) => {
                state.loadingProgram = false
                state.quisioner = action.payload
            })
            .addCase(getAllQuisioner.rejected, (state) => {
                state.loadingProgram = false
            })



    }
})
export default loadProgramStore.reducer