import React, { useState } from "react";
import HeaderUi from "../component/HeaderUi";
import Selector from "../templates/Selector";
import { useDispatch, useSelector } from "react-redux";
import { currency } from "../component/Currency";
import FormPengajuan from "../templates/FormPengajuan";
import { postAntrian } from "../store/actions/antrian";
import SnackBar from "../component/Snackbar";
import Loader from "../component/Loader";
import { convertToRupiah, formatRupiah } from "../component/FormatMoney";
import useSharingProgramOptions from "../options/sharingProgram";
import useGetPencairan from "../hooks/useGetPencaiaran";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi2";
import { bulan } from "../component/constant";

export default function ApproveAnggaran() {
  const dispatch = useDispatch();
  const { loadingAntrian, msgAntrian } = useSelector((state) => state.antrian);
  const { allPencairan } = useSelector((state) => state.pencairan);
  const [selectedAnggaran, setSelectedAnggaran] = useState([]);
  const [selectedSharingProgram, setSelectedSharingProgram] = useState({});
  const [show, setshow] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [idValue, setIdValue] = useState(null);
  const [dropdownKegiatan, setDropdwonKegiatan] = useState(false);
  useGetPencairan(showMessage);
  const handleSelectAnggaran = (e) => {
    if (
      !selectedAnggaran.includes(
        selectedAnggaran?.find((x) => x?.uuid === e?.id)
      )
    ) {
      setSelectedAnggaran([
        ...selectedAnggaran,
        {
          uuid: e.id,
          name: e.name,
          accounting_year: e.academic_year,
          amount: "",
          sharing_program: e.sharing_program,
        },
      ]);
    } else {
      setSelectedAnggaran(selectedAnggaran.filter((r) => r.uuid !== e.id));
    }
  };
  const handleAmount = (value, selected) => {
    setSelectedAnggaran(
      selectedAnggaran.map((e) => {
        if (selected?.id === e.uuid) {
          let money = convertToRupiah(value);
          let toIdr = formatRupiah(money, "");
          return {
            uuid: selected.id,
            accounting_year: selected.academic_year,
            amount: toIdr,
            sharing_program: selected.sharing_program,
          };
        } else {
          return e;
        }
      })
    );
  };
  const savePengajuan = () => {
    let anggaran = [];
    for (let i of selectedAnggaran) {
      let newAnggaran = {};
      newAnggaran.amount = convertToRupiah(i.amount);
      newAnggaran.accounting_year = i.accounting_year;
      newAnggaran.uuid = i.uuid;
      newAnggaran.sharing_program = i.sharing_program;
      anggaran.push(newAnggaran);
    }
    console.log(anggaran);
    dispatch(
      postAntrian({
        activity: anggaran,
        sharing_program_id: selectedSharingProgram.value,
      })
    );
    setshow(false);
    setShowMessage(true);
    setSelectedAnggaran([]);
    setDropdwonKegiatan(false);
  };
  const sharingProgramOptions = useSharingProgramOptions(true);
  return (
    <div>
      <Loader show={loadingAntrian} />
      <SnackBar
        show={showMessage}
        toggle={setShowMessage}
        msg={msgAntrian}
      />
      <HeaderUi titleHeader="Pencairan Anggaran" />
      <FormPengajuan
        show={show}
        close={() => setshow(!show)}
        submit={savePengajuan}
      >
        {selectedAnggaran?.find((data) => data.sharing_program) ? (
          <Selector
            title="Program Bersama"
            value={selectedSharingProgram}
            onChange={(val) => setSelectedSharingProgram(val)}
            options={sharingProgramOptions}
          />
        ) : (
          <></>
        )}
      </FormPengajuan>
      <div className="relative overflow-x-auto shadow-md rounded-lg m-5 px-2 py-5">
        <div className="my-3 justify-end flex">
          <button
            className={`bg-sky-500 text-white px-10 py-1 rounded-md ${
              selectedAnggaran?.length > 0 ? "flex" : "hidden"
            }`}
            onClick={() => {
              setshow(true);
            }}
          >
            Simpan
          </button>
        </div>
        <div className="flex  text-xs text-gray-700 uppercase bg-sky-100">
          <h1 className="px-6 py-3 w-[7%]">Detail</h1>
          <h1 className="px-6 py-3 w-[10%]">No</h1>
          <h1 className="px-6 py-3 w-[50%]">Nama</h1>
          <h1 className="px-12 py-3 w-[50%]">Bobot</h1>
          <h1 className="px-12 py-3 w-[50%]">Program Berkelanjutan</h1>
        </div>
        {allPencairan?.map((data, id) => (
          <div key={id}>
            <div className="flex  w-full text-sm text-left text-gray-500 hover:bg-gray-100">
              <h1 className="px-6 py-3 w-[7%]">
                {idValue === id && dropdownKegiatan ? (
                  <HiOutlineChevronUp
                    className="w-5 h-5 mx-2  cursor-pointer"
                    onClick={() => {
                      setIdValue(id);
                      setDropdwonKegiatan(false);
                    }}
                  />
                ) : (
                  <HiOutlineChevronDown
                    className="w-5 h-5 mx-2  cursor-pointer"
                    onClick={() => {
                      setIdValue(id);
                      setDropdwonKegiatan(true);
                    }}
                  />
                )}
              </h1>
              <h1 className="px-6 py-3 w-[10%]">{data?.activity_no}</h1>
              <h1 className="px-6 py-3 w-[50%]">{data?.name}</h1>
              <h1 className="px-12 py-3 w-[50%]">{data?.weight}</h1>
              <h1 className="px-12 py-3 w-[50%]">
                {data.continue ? "Iya" : "Tidak"}
              </h1>
            </div>
            <div
              className={`relative overflow-x-auto sm:rounded-lg my-5 ml-5 transition-all ease-in-out duration-100 ${
                idValue === id && dropdownKegiatan
                  ? "visible mt-0 bg-opacity-100 text-opacity-100"
                  : "bg-opacity-0 -mt-1 text-transparent invisible hidden"
              }`}
            >
              <div className="flex text-xs text-gray-700 uppercase bg-sky-100">
                <h1 className="px-6 py-3 w-[5%]">Dari</h1>
                <h1 className="px-6 py-3 w-[5%]">Sampai</h1>
                <h1 className="px-6 py-3 w-[30%]">Nama</h1>
                <h1 className="px-12 py-3 w-[20%]">Total</h1>
                <h1 className="px-12 py-3 w-[20%]">Program Bersama</h1>
                <h1 className="px-12 py-3 w-[5%]">Pilih</h1>
                <h1 className="px-12 py-3 w-[15%]">Nilai</h1>
              </div>
              {data.detail_of_activities?.map((value, id) => (
                <div key={id}>
                  <div className="flex  w-full text-sm text-left text-gray-500 hover:bg-gray-100">
                    <h1 className="px-6 py-3 w-[5%]">
                      {bulan[value?.from - 1]}
                    </h1>
                    <h1 className="px-6 py-3 w-[5%]">
                      {bulan[value?.until - 1]}
                    </h1>
                    <h1 className="px-6 py-3 w-[30%]">{value?.description}</h1>
                    <h1 className="px-12 py-3 w-[20%]">
                      {value?.total ? currency(value?.total) : currency(0)}
                    </h1>
                    <h1 className="px-12 py-3 w-[20%]">
                      {value.sharing_program ? "Iya" : "Tidak"}
                    </h1>
                    <div className="px-12 py-3 w-[5%]">
                      <input
                        type="checkbox"
                        checked={selectedAnggaran.includes(
                          selectedAnggaran?.find((x) => x?.uuid === value?.id)
                        )}
                        onClick={() => handleSelectAnggaran(value)}
                        readOnly
                      />
                    </div>
                    <div className="px-12 py-3 w-[15%]">
                      {selectedAnggaran.includes(
                        selectedAnggaran?.find((x) => x?.uuid === value?.id)
                      ) ? (
                        <input
                          className="px-2 py-1 w-[120%]"
                          value={
                            selectedAnggaran?.find((x) => x?.uuid === value?.id)
                              ?.amount || ""
                          }
                          onChange={(x) =>
                            handleAmount(x?.target?.value, value)
                          }
                        />
                      ) : (
                        <h1>-</h1>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
