import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllQuisioner } from "../store/actions/loadProgram";

export default function useGetQuisioner(trigger) {
  const dispatch = useDispatch();
  useEffect(() => {
    if(trigger){
        dispatch(getAllQuisioner());
    }
  }, [trigger]);
}
