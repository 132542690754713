import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useGetGroupIncome from "../hooks/useGetGroupIncome";

export default function useGroupIncomeOptions(trigger) {
  const { groupPendapatan } = useSelector((state) => state.pendapatan);
  const [allGroupIncomeOptions, setAllGroupIncomeOptions] = useState([]);
  useGetGroupIncome(trigger);
  useEffect(() => {
    if (groupPendapatan.length > 0) {
      const allGroupIncome = groupPendapatan.map((e) => {
        return { value: e.id, label: e.name };
      });
      setAllGroupIncomeOptions(allGroupIncome);
    }
  }, [groupPendapatan]);
  return allGroupIncomeOptions;
}
