import api from "./http";

const ApiPendapatan = {
  postPendapatan: (data) => {
    return api.post(`/apakah/institution-income`, data);
  },
  postGroupPendapatan: (data) => {
    return api.post(`/apakah/income-group`, data);
  },
  getPendapatan: (institution, academic_year, status) => {
    return api.get(
      `/apakah/institution-income/${institution}?academic_year=${academic_year}&status=${status}`
    );
  },
  getAllGroupPendapatan:()=>{
    return api.get(`/apakah/income-group`)
  },
  getPendapatanByLem: (no_lem) => {
    return api.get(`/apakah/institution-income/${no_lem}`);
  },
  getPendapatanByNoPendapatan: (no_pendapatan) => {
    return api.get(`/apakah/list-pendapatan/${no_pendapatan}`);
  },
  putPendapatan: (id, data) => {
    return api.put(`/apakah/institution-income/${id}`, data);
  },
  deletePendapatan: (uuid) => {
    return api.delete(`/apakah/institution-income/${uuid}`);
  },
  deleteRincianPendapatan: (uuid) => {
    return api.delete(`/apakah/list-pendapatan/rincian/${uuid}`);
  },
};
export default ApiPendapatan;
