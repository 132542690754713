import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import Dialog from "./Dialog";
import Input from "./Input";
import AddButton from "./AddButton";
import ArrayRincian from "./ArrayRincian";
import { useDispatch, useSelector } from "react-redux";
import { allSatuan, getThnAjar } from "../store/actions/loadProgram";
import Option from "./Option";
import TextArea from "./TextArea";
import { convertToRupiah, formatRupiah } from "./FormatMoney";
import { listPendapatan } from "./constant";
import useLembagaApakahOptions from "../options/lembaga";
import useTahunAjarOptions from "../options/tahunActive";
import useResetForm from "../hooks/custom/useResetForm";
import useGroupIncomeOptions from "../options/groupIncome";

function FormPendapatan(props) {
  const dispatch = useDispatch();
  const [lem, setLem] = useState({});
  const [pendapatan, setPendapatan] = useState({});
  const { role, lembaga: lembagaAuth } = useSelector((state) => state.auth);
  const { lembaga } = useSelector((state) => state.loadProgram);
  const methodPendapatan = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        academic_year: yup.string().required("Tidak Boleh Kosong"),
        institution_id: yup.string().nullable(),
        name: yup.string().required("Tidak Boleh Kosong"),
        income_group_id: yup.string().required("Tidak boleh kosong"),
        total: yup.string().required("Tidak Boleh Kosong"),
      })
    ),
    defaultValues: { institution_id: lembagaAuth },
  });
  const watchTotal = methodPendapatan.watch("total");
  const lembagaOptions = useLembagaApakahOptions(props.show, false);
  const groupIncomeOptions = useGroupIncomeOptions(props.show);
  const { year } = useTahunAjarOptions();
  useEffect(() => {
    if (watchTotal !== null && watchTotal !== undefined && watchTotal !== "") {
      let reset = convertToRupiah(watchTotal);
      let change = formatRupiah(reset);
      methodPendapatan.setValue("total", change);
    }
  }, [watchTotal]);
  useResetForm(
    methodPendapatan,
    {
      academic_year: year,
      institution_id: "",
      // institution_id: role === "admin-lembaga" ? lembagaAuth : "",
      name: "",
      total: 0,
    },
    props?.show
  );
  return (
    <Dialog show={props.show} toggle={props.close}>
      <div className="bg-white md:w-[70vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl"> Tambah Pendapatan</h1>
        </div>
        <form
          onSubmit={methodPendapatan.handleSubmit(props.submit)}
          className="px-6 py-7 space-y-1"
        >
          <div className="md:grid md:grid-cols-1 gap-4">
            <div className={`${"block"}`}>
            {/* <div className={`${role !== "bendahara" ? "hidden" : "block"}`}> */}
              <Option
                label="Lembaga"
                method={methodPendapatan}
                methodName="institution_id"
                options={lembagaOptions}
                onChange={(e) => setLem(e)}
                value={{ value: lem.value, label: lem.label }}
              />
            </div>
            <Option
              label="Pendapatan"
              method={methodPendapatan}
              methodName="income_group_id"
              options={groupIncomeOptions}
              onChange={(e) => setPendapatan(e)}
              value={{ value: pendapatan.value, label: pendapatan.label }}
            />
            <Input label="Nama" method={methodPendapatan} methodName="name" />
            <Input label="Total" method={methodPendapatan} methodName="total" />
          </div>
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}

export default FormPendapatan;
