import React from 'react'

function AddButton() {
    return (
        <div className='flex justify-end pt-5'>
            <button type='submit' className='border border-sky-400  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300'>Simpan</button>
        </div>
    )
}

export default AddButton