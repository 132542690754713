import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getTahunActive } from "../store/actions/loadProgram";

export default function useGetTahunActive(trigger) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTahunActive());
  }, [trigger]);
}
