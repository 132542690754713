import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPendapatan } from "../store/actions/pendapatan";

export default function useGetPendapatan(
  selectedLembaga,
  tahun,
  status,
  trigger
) {
  const dispatch = useDispatch();
  const { role, lembaga } = useSelector((state) => state.auth);
  useEffect(() => {
    if (tahun && status) {
      if (selectedLembaga) {
        dispatch(getAllPendapatan({ lembaga: selectedLembaga, tahun, status }));
      }
      // if (role !== "admin-lembaga" && selectedLembaga) {
      //   dispatch(getAllPendapatan({ lembaga: selectedLembaga, tahun, status }));
      // } else if(role === 'admin-lembaga' && lembaga) {
      //   dispatch(getAllPendapatan({ lembaga, tahun, status }));
      // }
    }
  }, [selectedLembaga, tahun, status, trigger, lembaga, role]);
}
