import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiAntrian from "../../api/antrian";

export const postAntrian = createAsyncThunk(
    'post/antrian',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiAntrian.post(data)
            if(res.status === 200){
                return res.data
            }
        }catch(err){
            return rejectWithValue(err?.response?.data)
        }
    }
)