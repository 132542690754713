import React from "react";
import Dialog from "./Dialog";
import Option from "./Option";
import TextArea from "./TextArea";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import AddButton from "./AddButton";
import Loader from "./Loader";
import useResetForm from "../hooks/custom/useResetForm";
import useProgramOptions from "../options/program";

function FormKomponen(props) {
  const {role,lembaga} = useSelector(state=>state.auth)
  const { loadingProgram } = useSelector((state) => state.loadProgram);
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        program_id: yup.string().required("Tidak Boleh Kosong"),
        item: yup.string().required("Tidak Boleh Kosong"),
      })
    ),
  });
  const institute = props?.lem?.value
  // const institute = role === 'admin-lembaga' ? lembaga : props?.lem?.value
  const programOpt = useProgramOptions(institute,props?.tahun,props?.show)
  useResetForm(method, { program_id:props.programId, item: "" }, props?.show);
  return (
    <Dialog show={props.show} toggle={props.close}>
      <Loader show={loadingProgram} />
      <div className="bg-white md:w-[60vw] w-[90vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl"> Tambah Komponen</h1>
        </div>
        <form
          onSubmit={method.handleSubmit(props.submit)}
          className="px-6 py-7 space-y-1"
        >
          <div className="flex md:space-x-5 md:flex-row flex-col">
            <Option
              label="Program"
              method={method}
              methodName="program_id"
              options={programOpt}
            />
            <TextArea
              label="Komponen"
              method={method}
              methodName="item"
              className="md:w-[70vw]"
            />
          </div>
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}

export default FormKomponen;
