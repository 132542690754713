import api from "./http";

const ApiProgram = {
  getKegiatan: (lembaga, status) => {
    return api.get(`/apakah/activity/status/${lembaga}?status=${status}`);
  },
  getKegiatanByLembaga: (no_lembaga,tahun) => {
    return api.get(`/apakah/list-kegiatan/lembaga?no_lembaga=${no_lembaga}&tahun=${tahun}`);
  },
  getRincian: (tahun_ajar, no) => {
    return api.get(
      `/apakah/rincian-kegiatan?tahun_ajar=${tahun_ajar}&no_kegiatan=${no}`
    );
  },
  getTotalKegiatan: (no_lem) => {
    return api.get(`/apakah/rincian-kegiatan/total/${no_lem}`);
  },
  getTotalPendapatan: (no_lem) => {
    return api.get(`/apakah/list-pendapatan/total/${no_lem}`);
  },
  getRincianByLembaga: (no_lembaga, status) => {
    return api.get(
      `/apakah/rincian-kegiatan/filter/rincian/by-lembaga/${no_lembaga}/${status}`
    );
  },
};
export default ApiProgram;
