import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiPtk from "../../api/ptk";

export const getAllPtk = createAsyncThunk(
  "ptk/all",
  async (_, { rejectWithValue }) => {
    try {
      const res = await ApiPtk.getAll();
      if (res.status === 200) {
        return res.data;
      }
    } catch (r) {
      return rejectWithValue(r?.response?.data?.msg);
    }
  }
);
