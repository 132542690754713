import { useEffect, useState } from "react";
import useGetTahun from "../hooks/useGetTahun";
import { useSelector } from "react-redux";

export default function useTahunOptions() {
    const { thn_ajar } = useSelector((state) => state.loadProgram);
    const [allTahunOptions,setTahunOptions] = useState([])
    useGetTahun(true);
    useEffect(()=>{
        if(thn_ajar?.length > 0){
            let allTahun = thn_ajar?.map((val)=>{
                return {value:val.tahun,label:val.tahun}
            })
            setTahunOptions(allTahun)
        }
    },[thn_ajar])
    return allTahunOptions;
  }
  