import React, { useState, useEffect } from "react";
import Dialog from "./Dialog";
import Option from "./Option";
import TextArea from "./TextArea";
import AddButton from "./AddButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useKegiatanOptions from "../options/kegiatan";
import useResetForm from "../hooks/custom/useResetForm";
import useGetQuisioner from "../hooks/useGetQuisioner";
import { useSelector } from "react-redux";
import Radio from "./Radio";

function FormSubKegiatan(props) {
  const { quisioner } = useSelector((state) => state.loadProgram);
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        sub: yup.object().shape({
          activity_id: yup.string().required("Tidak Boleh Kosong"),
          name: yup.string().required("Tidak Boleh Kosong"),
        }),
        answer_list: yup.array().of(
          yup.object().shape({
            answer_id: yup
              .number()
              .typeError("Tidak boleh kosong")
              .required("Tidak boleh kosong"),
            question_id: yup
              .number()
              .typeError("Tidak boleh kosong")
              .required("Tidak boleh kosong"),
            weight: yup
              .number()
              .typeError("Tidak boleh kosong")
              .required("Tidak boleh kosong"),
          })
        ),
      })
    ),
  });
  useGetQuisioner(props?.show);
  const kegiatanOpt = useKegiatanOptions(
    props?.komponenId,
    props.tahun,
    props?.show
  );
  useResetForm(
    method,
    { sub: { activity_id: props.kegiatanId, name: "" } },
    props?.show
  );
  return (
    <Dialog show={props.show} toggle={props.close} scroll>
      <div className="bg-white md:w-[60vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl"> Tambah Sub Kegiatan</h1>
        </div>
        <form
          onSubmit={method.handleSubmit(props.submit)}
          className="px-6 py-7 space-y-1"
        >
          <div className="flex md:space-x-5 md:flex-row flex-col">
            <div className="md:w-[50vw]">
              <Option
                label="Kegiatan"
                method={method}
                methodName="sub.activity_id"
                options={kegiatanOpt}
              />
            </div>
            <TextArea
              label="Sub Kegiatan"
              method={method}
              methodName="sub.name"
              className="md:w-[50vw]"
            />
          </div>
          <div className="my-5">
            {quisioner &&
              quisioner?.map((value, id) => (
                <div key={id}>
                  <h1>
                    {id + 1}. {value?.question}
                  </h1>
                  <div className="grid grid-cols-2 gap-4 my-2">
                    {value?.weight_answers &&
                      value?.weight_answers?.map((answers, idx) => (
                        <div
                          key={idx}
                          onClick={() => {
                            method.setValue(
                              `answer_list.${id}.question_id`,
                              value.id
                            );
                            method.setValue(
                              `answer_list.${id}.weight`,
                              answers.weight
                            );
                          }}
                        >
                          <Radio
                            method={method}
                            methodName={`answer_list.${id}.answer_id`}
                            value={`${answers?.id}`}
                            label={answers?.answer}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}

export default FormSubKegiatan;
