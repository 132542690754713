import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { allLembaga, getRole, getSistem } from "../store/actions/loadProgram";
import {
  destroySistem,
  editSistemOne,
  getAllUser,
} from "../store/actions/user";
import Dialog from "./Dialog";
import InputField from "./InputField";
import Options from "./Option";
import Radio from "./Radio";
import SnackBar from "./Snackbar";
import useLembagaApakahOptions from "../options/lembaga";
import useSystemOptions from "../options/system";
import useRoleOptions from "../options/role";

function FormEditSistem(props) {
  const dispatch = useDispatch();
  const { msgUser } = useSelector((state) => state.user);
  const [change, setChange] = useState(false);
  const [snackEdit, setSnackEdit] = useState(false);
  const [saveId, setSaveId] = useState();
  const [uuidUsr, setUuidUsr] = useState("");
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        sistem: yup.array().of(
          yup.object().shape({
            system_id: yup.string().required("Tidak Boleh Kosong"),
            role_id: yup.string().required("Tidak Boleh Kosong"),
            super_admin: yup
              .string()
              .oneOf(["true", "false"], "Pilih Salah Satu")
              .required("Tidak Boleh Kosong"),
            institution_apakah: yup.number(),
          })
        ),
      })
    ),
    defaultValues: {
      sistem: [
        {
          system_id: "",
          role_id: "",
          super_admin: "false",
          institution_apakah: null,
        },
      ],
    },
  });
  const { fields, remove, replace } = useFieldArray({
    control: method.control,
    name: "sistem",
  });
  const hapusSistem = (e, id) => {
    if (e.uuid_sistem !== "") {
      remove(id);
      dispatch(
        destroySistem({ uuid: props.data.uuid, uuid_sis: e.system_id })
      );
      props.close();
      props.setSnackEdit(true);
    } else {
      remove(id);
    }
  };
  const getSis = (e) => {
    const watchSistem = method.watch(`sistem[${e}].system_id`);
    const watchUuidRole = method.watch(`sistem[${e}].role_id`);
    const watchSuper = method.watch(`sistem[${e}].super_admin`);
    const watchLembaga = method.watch(`sistem[${e}].institution_apakah`);
    dispatch(
      editSistemOne({
        uuid: uuidUsr,
        uuid_sistem: watchSistem,
        update: {
          role_id: watchUuidRole,
          super_admin: watchSuper,
          institution_apakah: watchLembaga,
        },
      })
    );
    setSaveId();
    props.close();
    props.setSnackEdit(true);
  };
  const roleOpt = useRoleOptions(
    method.watch(`sistem[${saveId}].system_id`),
    true
  );
  const sistemOpt = useSystemOptions(true);
  const lembagaOpt = useLembagaApakahOptions(true, false);
  useEffect(() => {
    let save = [];
    if (Object.keys(props.data).length !== 0) {
      props.data.sistem.map((d) =>
        save.push({
          system_id: d.uuid_sistem,
          role_id: d.uuid_role,
          super_admin: d.super_admin.toString(),
          institution_apakah: d.lembaga_apakah,
        })
      );
      setUuidUsr(props.data.uuid);
      replace(save);
    }
  }, [props.show, props.data, snackEdit]);
  return (
    <div>
      <SnackBar show={snackEdit} toggle={setSnackEdit} msg={msgUser} />
      <Dialog show={props.show} toggle={props.close} scroll>
        <div
          className="bg-white md:w-[40vw] w-[95vw]"
          onClick={() => setSnackEdit(false)}
        >
          <div className="py-4 px-6 border-b border-slate-200">
            <h1 className="text-xl">Edit Sistem</h1>
          </div>
          <form
            onSubmit={method.handleSubmit(props.submit)}
            className="px-6 py-7 space-y-1"
          >
            {fields.map((d, id) => {
              return (
                <div key={d.id}>
                  <Options
                    label="Sistem"
                    method={method}
                    methodName={`sistem[${id}].system_id`}
                    options={sistemOpt}
                  />
                  {saveId === id ? (
                    <Options
                      label="Role"
                      method={method}
                      methodName={`sistem[${id}].role_id`}
                      options={roleOpt}
                    />
                  ) : (
                    <InputField
                      label="Role"
                      value={
                        Object.keys(props.data).length !== 0 &&
                        props.data.sistem[id]?.uuid_role !== undefined
                          ? props.data?.sistem[id]?.nama_role
                          : ""
                      }
                      read={true}
                    />
                  )}
                  <div className="mb-1">
                    <h1 className="font-light text-lg mb-2">Super Admin</h1>
                    <div className="flex my-auto md:space-x-0 space-x-2">
                      <Radio
                        method={method}
                        methodName={`sistem[${id}].super_admin`}
                        value={"true"}
                        label="Iya"
                      />
                      <Radio
                        method={method}
                        methodName={`sistem[${id}].super_admin`}
                        value={"false"}
                        label="Tidak"
                      />
                    </div>
                  </div>
                  <div
                    className={`${
                      Object.keys(props.data).length !== 0
                        ? props.data?.sistem[id]?.nama_role === "admin-lembaga"
                          ? "block"
                          : "hidden"
                        : "hidden"
                    }`}
                  >
                    <Options
                      label="Lembaga"
                      method={method}
                      methodName={`sistem[${id}].institution_apakah`}
                      options={lembagaOpt}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => hapusSistem(d, id)}
                    className="border border-red-700 my-1 px-5 py-[2px] hover:border-red-800 transition-colors ease-in-out duration-300 hover:text-red-800"
                  >
                    Hapus
                  </button>
                  {saveId === id ? (
                    <button
                      type="button"
                      onClick={() => getSis(id)}
                      className="ml-2 border border-blue-700 my-1 px-5 py-[2px] hover:border-blue-800 transition-colors ease-in-out duration-300 hover:text-blue-800"
                    >
                      Simpan
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        setSaveId(id);
                        setChange(true);
                      }}
                      className="ml-2 border border-blue-700 my-1 px-5 py-[2px] hover:border-blue-800 transition-colors ease-in-out duration-300 hover:text-blue-800"
                    >
                      Edit
                    </button>
                  )}
                </div>
              );
            })}
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => props.close(false)}
                className="border border-sky-400  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300 flex"
              >
                Simpan
              </button>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
}

export default FormEditSistem;
