import React from "react";
import Button from "./Button";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi2";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function Table(props) {
  const { super_admin, lembagaAuth, role } = useSelector(
    (state) => state.auth
  );
  const location = useLocation();
  return (
    <div className="bg-white my-7 md:mx-5 mx-3 h-full md:py-7 py-5 md:px-12 px-3">
      <div className="flex md:justify-end md:space-x-4 space-x-3">
        <div className={` md:-mt-5 -mt-2 flex gap-5`}>
          <div className={`w-72 ${props.classSelect}`}>
            <label className="text-gray-700">Lembaga</label>
            <Select
              options={props.optSelect}
              onChange={(e) => props?.setChange(e)}
              value={
                props.classSelect === "hidden"
                  ? { value: props?.change, label: props?.change }
                  : { value: props?.change?.value, label: props?.change?.label }
              }
              defaultValue={props.defaultValue || "select"}
            />
          </div>
          {props.filter}
        </div>
        <div
          className={`relative md:mt-0 mt-3 ${
            role === "bendahara" || super_admin
              ? "block"
              : location.pathname === "/home/pendapatan" &&
                role === "admin-lembaga"
              ? "block"
              : "hidden"
          } ${props.classButton}`}
        >
          <Button
            classname={props.classFirst}
            title={props.titleFirst}
            click={props.clickFirst}
            icon={
              props.dropDown ? (
                <HiOutlineChevronUp className="my-auto ml-2" />
              ) : (
                <HiOutlineChevronDown className={`my-auto ml-2`} />
              )
            }
          />
          <div
            className={`z-10 absolute -bottom-5 transition-all ease-in-out duration-200 bg-gray-100 w-full ${
              props.dropDown
                ? "visible -bottom-[7.5rem] bg-opacity-100 text-opacity-100"
                : "invisible -bottom-[7rem] bg-opacity-0 text-transparent"
            }`}
          >
            <div
              onClick={props.formAdd}
              className="p-2 w-full hover:bg-gray-300 cursor-pointer"
            >
              Tambah
            </div>
            <div
              onClick={props.formEdit}
              className="p-2 w-full hover:bg-gray-300 cursor-pointer"
            >
              Edit
            </div>
            <div
              onClick={props.formDelete}
              className="p-2 w-full hover:bg-gray-300 cursor-pointer"
            >
              Delete
            </div>
          </div>
        </div>

        {/* <Button classname={props.classSecond} title={props.titleSecond} click={props.clickSecond} icon={<HiOutlineChevronDown className={`my-auto ml-2`} />} />
                <Button classname={props.classThird} title={props.titleThird} click={props.clickThird} icon={<HiOutlineChevronDown className={`my-auto ml-2`} />} /> */}
      </div>
      {props.children}
    </div>
  );
}

export default Table;
