import React, { useEffect, useState } from "react";
import HeaderUi from "../component/HeaderUi";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { allListKegiatanByLembaga } from "../store/actions/program";
import Loader from "../component/Loader";
import Table from "../templates/Table";
import { currency } from "../component/Currency";
import Button from "../component/Button";
import Anggaran from "./Anggaran";
import { convertToRupiah, formatRupiah } from "../component/FormatMoney";
import Dialog from "../component/Dialog";
import useTahunAjarOptions from "../options/thn_ajar";
import SnackBar from "../component/Snackbar";
import { HiTrash } from "react-icons/hi2";
import { bulanOpt } from "../component/constant";
import { postNewAnggaran } from "../store/actions/proker";
import useLembagaApakahOptions from "../options/lembaga";
import useSatuanOptions from "../options/satuan";

const head = ["No", "Kegiatan"];

export default function NewKegiatan() {
  const dispatch = useDispatch();
  const [showMessage, setShowMessage] = useState(false);
  const [lembagaValue, setLembagaValue] = useState({});
  const [tahunValue, setTahunValue] = useState({});
  const lembagaOptions = useLembagaApakahOptions();
  const [selectedAnggaran, setSelectedAnggaran] = useState([]);
  const [showSaveContinueAnggaran, setShowSaveContinueAnggaran] =
    useState(false);
  const { role, lembagaAuth } = useSelector((state) => state.auth);
  const { loadingProgram, kegiatanByLembaga } = useSelector(
    (state) => state.program
  );
  const { loadingProker, msgProker } = useSelector((state) => state.proker);
  const [showNewValue, setShowNewValue] = useState(false);
  const handleSelectAnggaran = (e) => {
    if (
      !selectedAnggaran.includes(
        selectedAnggaran?.find((x) => x?.uuid === e?.uuid)
      )
    ) {
      setSelectedAnggaran([
        ...selectedAnggaran,
        {
          harga_satuan: formatRupiah(e.harga_satuan),
          dari: e.dari,
          sampai: e.sampai,
          vol: e.vol,
          id_satuan: e.id_satuan,
          total: e.total,
          tahun_ajar: e.tahun_ajar,
          uuid: e.uuid,
          uraian: e.uraian,
          no_kegiatan: e.no_kegiatan,
        },
      ]);
    } else {
      setSelectedAnggaran(selectedAnggaran.filter((r) => r.uuid !== e.uuid));
    }
  };
  const handleValue = (value, uuid) => {
    setSelectedAnggaran(
      selectedAnggaran.map((e) => {
        if (uuid === e.uuid) {
          let total_ = formatRupiah(convertToRupiah(value.total), "");
          let harga_satuan_ = formatRupiah(
            convertToRupiah(value.harga_satuan),
            ""
          );
          return { ...value, total: total_, harga_satuan: harga_satuan_ };
        } else {
          return e;
        }
      })
    );
  };
  useEffect(() => {
    if (tahunValue?.value) {
      dispatch(
        allListKegiatanByLembaga({
          no_lembaga: lembagaValue?.value,
          tahun: tahunValue?.value,
        })
      );
    }
  }, [lembagaValue?.value, showMessage, tahunValue?.value]);
  const sendNewAnggaran = () => {
    let anggaran = [];
    for (let i of selectedAnggaran) {
      let data = {
        ...i,
        harga_satuan: convertToRupiah(i.harga_satuan, ""),
        total: convertToRupiah(i.total, ""),
      };
      anggaran.push(data);
    }
    dispatch(postNewAnggaran({ tahun: tahunValue?.value, rincian: anggaran }));
    setShowSaveContinueAnggaran(false);
    setShowMessage(true);
    setSelectedAnggaran([]);
  };
  const tahunAjarOptions = useTahunAjarOptions();
  const satuanOpt = useSatuanOptions();
  return (
    <div>
      <Loader show={loadingProgram || loadingProker} />
      <SnackBar show={showMessage} toggle={setShowMessage} msg={msgProker} />
      <Dialog
        show={showSaveContinueAnggaran}
        toggle={setShowSaveContinueAnggaran}
      >
        <div className="bg-white md:w-[60vw] w-[95vw]">
          <div className="py-4 px-6 border-b border-slate-200">
            <h1 className="text-xl">Anda yakin untuk memperoses data ini?</h1>
          </div>
          <h1 className="text-xl px-6 text-red-700">
            Pastikan semua data sudah benar dan sudah memilih tahun di atas
            sebelum menyimpan!
          </h1>
          <div className="p-5 w-full ">
            {tahunValue?.value ? (
              <button
                onClick={sendNewAnggaran}
                className="border border-sky-400 w-full  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300"
              >
                Iya
              </button>
            ) : (
              <h1 className="text-xl text-center text-red-700">
                Silahkan pilih tahun terlebih dahulu!
              </h1>
            )}
          </div>
        </div>
      </Dialog>
      <HeaderUi
        titleHeader="Perncanaan Anggaran Awal Tahun"
        location={`list`}
      />
      <div className=" my-7  h-full md:py-7 py-5 md:px-12 px-3 flex justify-between">
        <div className="bg-white w-[49%] p-4 shadow-lg">
          <div
            className={`w-72 ${
              "block"
              // role === "admin-lembaga" ? "hidden" : "block"
            }`}
          >
            <label className="text-gray-700">Lembaga</label>
            <Select
              options={lembagaOptions}
              onChange={(e) => setLembagaValue(e)}
              value={lembagaValue}
            />
          </div>
          <div className="my-3 overflow-y-auto">
            <div className="flex gap-5 bg-sky-200 p-2 w-[100%]">
              <h1 className="w-[10%]">No</h1>
              <h1 className="w-[90%]">Kegiatan</h1>
            </div>
            {kegiatanByLembaga?.map((value, id) => (
              <div key={id}>
                <div className="flex gap-5 bg-sky-50 border-b border-b-sky-200 p-2 w-[100%]">
                  <h1 className="w-[10%]">{value?.no_kegiatan}</h1>
                  <h1 className="w-[90%]">{value?.item_kegiatan}</h1>
                </div>
                <div
                  className={`${
                    value?.list_sub_kegiatans?.length > 0
                      ? "block ml-10"
                      : "hidden"
                  }`}
                >
                  <div className="flex gap-5 bg-sky-200 p-2 w-[100%]">
                    <h1 className="w-[10%]">No</h1>
                    <h1 className="w-[90%]">Sub Kegiatan</h1>
                  </div>
                  {value?.list_sub_kegiatans?.map((value, id) => (
                    <div key={id}>
                      <div
                        key={id}
                        className="flex gap-5 bg-sky-50 border-b border-b-sky-200 p-2 w-[100%]"
                      >
                        <h1 className="w-[10%]">{value?.no_sub_kegiatan}</h1>
                        <h1 className="w-[90%]">{value?.item_sub_kegiatan}</h1>
                      </div>
                      <div
                        className={`${
                          value?.rincian_kegiatans?.length > 0
                            ? "block ml-10"
                            : "hidden"
                        }`}
                      >
                        <div className="flex gap-5 bg-sky-200 p-2 w-[100%]">
                          <h1 className="w-[70%]">Uraian</h1>
                          <h1 className="w-[20%]">Total</h1>
                          <h1 className="w-[10%]">Pilih</h1>
                        </div>
                        {value?.rincian_kegiatans?.map((value, id) => (
                          <div
                            key={id}
                            className="flex gap-5 bg-sky-50 border-b border-b-sky-200 p-2 w-[100%]"
                          >
                            <h1 className="w-[80%]">{value?.uraian}</h1>
                            <h1 className="w-[20%]">
                              {value?.total
                                ? currency(value?.total)
                                : currency(0)}
                            </h1>
                            <div className="w-[10%]">
                              <input
                                type="checkbox"
                                checked={selectedAnggaran.includes(
                                  selectedAnggaran?.find(
                                    (x) => x?.uuid === value.uuid
                                  )
                                )}
                                onClick={() => handleSelectAnggaran(value)}
                                readOnly
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                  <div
                    className={`${
                      value?.rincian_kegiatans?.length > 0
                        ? "block ml-20"
                        : "hidden"
                    }`}
                  >
                    <div className="flex gap-5 bg-sky-200 p-2 w-[100%]">
                      <h1 className="w-[70%]">Uraian</h1>
                      <h1 className="w-[20%]">Total</h1>
                      <h1 className="w-[10%]">Pilih</h1>
                    </div>
                    {value?.rincian_kegiatans?.map((value, id) => (
                      <div
                        key={id}
                        className="flex gap-5 bg-sky-50 border-b border-b-sky-200 p-2 w-[100%]"
                      >
                        <h1 className="w-[80%]">{value?.uraian}</h1>
                        <h1 className="w-[20%]">
                          {value?.total ? currency(value?.total) : currency(0)}
                        </h1>
                        <div className="w-[10%]">
                          <input
                            type="checkbox"
                            checked={selectedAnggaran.includes(
                              selectedAnggaran?.find(
                                (x) => x?.uuid === value.uuid
                              )
                            )}
                            onClick={() => handleSelectAnggaran(value)}
                            readOnly
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className={`${
                    value?.rincian_kegiatans?.length > 0
                      ? "block ml-20"
                      : "hidden"
                  }`}
                >
                  <div className="flex gap-5 bg-sky-200 p-2 w-[100%]">
                    <h1 className="w-[70%]">Uraian</h1>
                    <h1 className="w-[20%]">Total</h1>
                    <h1 className="w-[10%]">Pilih</h1>
                  </div>
                  {value?.rincian_kegiatans?.map((value, id) => (
                    <div
                      key={id}
                      className="flex gap-5 bg-sky-50 border-b border-b-sky-200 p-2 w-[100%]"
                    >
                      <h1 className="w-[80%]">{value?.uraian}</h1>
                      <h1 className="w-[20%]">
                        {value?.total ? currency(value?.total) : currency(0)}
                      </h1>
                      <div className="w-[10%]">
                        <input
                          type="checkbox"
                          checked={selectedAnggaran.includes(
                            selectedAnggaran?.find(
                              (x) => x?.uuid === value.uuid
                            )
                          )}
                          onClick={() => handleSelectAnggaran(value)}
                          readOnly
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-[49%] gap-5">
          <div className="bg-white w-[100%] shadow-lg p-4">
            <div className="flex gap-5">
              <div className="mt-5">
                <Button
                  title={showNewValue ? "Tutup" : "Tambah / Edit Anggaran Baru"}
                  click={() => setShowNewValue(!showNewValue)}
                />
              </div>
              <div className="w-72">
                <label className="text-gray-700">Tahun Ajar</label>
                <Select
                  options={tahunAjarOptions}
                  onChange={(e) => setTahunValue(e)}
                  value={tahunValue}
                />
              </div>
            </div>
            <div className={`${showNewValue ? "block" : "hidden"}`}>
              <Anggaran
                no_lembaga={lembagaValue}
                tahun_ajar={tahunValue?.value}
                trigger={showMessage}
              />
            </div>
          </div>
          <div className="bg-white w-[100%] h-[80vh] shadow-lg p-4">
            <div className="my-2 flex justify-end">
              <Button
                title={"Simpan"}
                click={() => setShowSaveContinueAnggaran(true)}
              />
            </div>
            <Table
              head={[
                "Nama",
                "Dari",
                "Sampai",
                "Vol",
                "Harga Satuan",
                "satuan",
                "Total",
                "Tahun Referensi",
                "Hapus",
              ]}
            >
              {selectedAnggaran?.map((value, id) => (
                <tr key={id} className={`bg-white border-b hover:bg-gray-100 `}>
                  <td className="px-6 py-3">{value.uraian}</td>
                  <td className="px-6 py-3">
                    <Select
                      className="w-32"
                      options={bulanOpt}
                      onChange={(e) =>
                        handleValue(
                          {
                            ...value,
                            dari: e?.value,
                            total:
                              (value.sampai - e?.value + 1) *
                              (convertToRupiah(value.harga_satuan) * value.vol),
                          },
                          value?.uuid
                        )
                      }
                      value={{
                        value:
                          selectedAnggaran?.find((x) => x?.uuid === value?.uuid)
                            ?.dari || "",
                        label: bulanOpt?.find(
                          (e) =>
                            e.value ===
                              selectedAnggaran?.find(
                                (x) => x?.uuid === value?.uuid
                              )?.dari || ""
                        )?.label,
                      }}
                    />
                  </td>
                  <td className="px-6 py-3">
                    <Select
                      className="w-32"
                      options={bulanOpt}
                      onChange={(e) =>
                        handleValue(
                          {
                            ...value,
                            sampai: e?.value,
                            total:
                              (e?.value - value.dari + 1) *
                              (convertToRupiah(value.harga_satuan) * value.vol),
                          },
                          value?.uuid
                        )
                      }
                      value={{
                        value:
                          selectedAnggaran?.find((x) => x?.uuid === value?.uuid)
                            ?.sampai || "",
                        label: bulanOpt?.find(
                          (e) =>
                            e.value ===
                              selectedAnggaran?.find(
                                (x) => x?.uuid === value?.uuid
                              )?.sampai || ""
                        )?.label,
                      }}
                    />
                  </td>
                  <td className="px-6 py-3">
                    {selectedAnggaran.includes(
                      selectedAnggaran?.find((x) => x?.uuid === value?.uuid)
                    ) ? (
                      <input
                        className="w-20"
                        value={
                          selectedAnggaran?.find((x) => x?.uuid === value?.uuid)
                            ?.vol || ""
                        }
                        onChange={(x) =>
                          handleValue(
                            {
                              ...value,
                              vol: x?.target?.value,
                              total:
                                (value.sampai - value.dari + 1) *
                                (convertToRupiah(value.harga_satuan) *
                                  x?.target?.value),
                            },
                            value?.uuid
                          )
                        }
                      />
                    ) : (
                      <h1>-</h1>
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {selectedAnggaran.includes(
                      selectedAnggaran?.find((x) => x?.uuid === value?.uuid)
                    ) ? (
                      <input
                        className="w-20"
                        value={
                          selectedAnggaran?.find((x) => x?.uuid === value?.uuid)
                            ?.harga_satuan || ""
                        }
                        onChange={(x) =>
                          handleValue(
                            {
                              ...value,
                              harga_satuan: x?.target?.value,
                              total:
                                (value.sampai - value.dari + 1) *
                                (value.vol * convertToRupiah(x?.target?.value)),
                            },
                            value?.uuid
                          )
                        }
                      />
                    ) : (
                      <h1>-</h1>
                    )}
                  </td>
                  <td className="px-6 py-3">
                    <Select
                      className="w-32"
                      options={satuanOpt}
                      onChange={(e) =>
                        handleValue(
                          {
                            ...value,
                            id_satuan: e?.value,
                          },
                          value?.uuid
                        )
                      }
                      value={{
                        value:
                          selectedAnggaran?.find((x) => x?.uuid === value?.uuid)
                            ?.id_satuan || "",
                        label: satuanOpt?.find(
                          (e) =>
                            e.value ===
                              selectedAnggaran?.find(
                                (x) => x?.uuid === value?.uuid
                              )?.id_satuan || ""
                        )?.label,
                      }}
                    />
                  </td>
                  <td className="px-6 py-3">
                    {value?.total
                      ? typeof value.total === "string"
                        ? currency(convertToRupiah(value.total))
                        : currency(value?.total)
                      : currency(0)}
                  </td>
                  <td className="px-6 py-3">{value?.tahun_ajar}</td>
                  <td className="px-6 py-3">
                    <HiTrash
                      className="w-6 h-6 cursor-pointer"
                      onClick={() =>
                        setSelectedAnggaran([
                          ...selectedAnggaran.filter(
                            (e) => e.uuid !== value?.uuid
                          ),
                        ])
                      }
                    />
                  </td>
                </tr>
              ))}
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
