import React, { useEffect } from "react";
import { convertToRupiah, formatRupiah } from "./FormatMoney";
import Input from "./Input";
import Option from "./Option";
import TextArea from "./TextArea";
import { bulanOpt, jenisBelanja, metodeOpt } from "./constant";

function ArrayRincian(props) {
  const volWatch = props.methodRincian.watch(
    `detail_of_activity_list[${props.id}].vol`
  );
  const hargaWatch = props.methodRincian.watch(
    `detail_of_activity_list[${props.id}].unit_price`
  );
  const fromWatch = props.methodRincian.watch(
    `detail_of_activity_list[${props.id}].from`
  );
  const untilWatch = props.methodRincian.watch(
    `detail_of_activity_list[${props.id}].until`
  );
  const driWatch = props.methodRincian.watch("from");
  const smpiWatch = props.methodRincian.watch("until");
  const totalBulan = untilWatch - fromWatch + 1;
  const ttlbln = smpiWatch - driWatch + 1;
  useEffect(() => {
    if (props.classDate === "hidden") {
      if (
        volWatch !== NaN &&
        hargaWatch !== NaN &&
        volWatch !== "" &&
        hargaWatch !== ""
      ) {
        const conv = convertToRupiah(hargaWatch);
        const ttl = parseInt(volWatch) * parseInt(conv) * totalBulan;
        const reset = convertToRupiah(ttl);
        const change = formatRupiah(reset);
        props.methodRincian.setValue(
          `detail_of_activity_list[${props.id}].total`,
          change
        );
      }
    } else {
      if (
        volWatch !== NaN &&
        hargaWatch !== NaN &&
        volWatch !== "" &&
        hargaWatch !== ""
      ) {
        const conv = convertToRupiah(hargaWatch);
        const ttl = parseInt(volWatch) * parseInt(conv) * totalBulan;
        const reset = convertToRupiah(ttl);
        const change = formatRupiah(reset);
        props.methodRincian.setValue(
          `detail_of_activity_list[${props.id}].total`,
          change
        );
      }
    }
  }, [
    volWatch,
    hargaWatch,
    totalBulan,
    untilWatch,
    fromWatch,
    ttlbln,
    driWatch,
    smpiWatch,
  ]);
  useEffect(() => {
    if (hargaWatch !== undefined && hargaWatch !== "" && hargaWatch !== null) {
      let reset = convertToRupiah(hargaWatch);
      let chnge = formatRupiah(reset);
      props.methodRincian.setValue(
        `detail_of_activity_list[${props.id}].unit_price`,
        chnge
      );
    }
  }, [hargaWatch]);
  const sharingOptions = [
    { value: 0, label: "Tidak" },
    { value: 1, label: "Iya" },
  ];
  return (
    <div key={props.id} className="md:grid md:grid-cols-2 gap-4 ">
      <TextArea
        label="Uraian"
        method={props.methodRincian}
        methodName={`detail_of_activity_list[${props.id}.description]`}
      />
      <div className={`flex flex-col justify-between ${props.classDate}`}>
        <Option
          label="Dari"
          method={props.methodRincian}
          methodName={`detail_of_activity_list[${props.id}].from`}
          options={bulanOpt}
        />
        <Option
          label="Sampai"
          method={props.methodRincian}
          methodName={`detail_of_activity_list[${props.id}].until`}
          options={bulanOpt}
        />
      </div>
      <Option
        label="Satuan"
        method={props.methodRincian}
        methodName={`detail_of_activity_list[${props.id}].unit_id`}
        options={props.satuanOpt}
      />
      <Input
        type="number"
        label="Vol"
        method={props.methodRincian}
        methodName={`detail_of_activity_list[${props.id}].vol`}
      />
      <Input
        label="Harga Satuan"
        method={props.methodRincian}
        methodName={`detail_of_activity_list[${props.id}].unit_price`}
      />
      <Input
        label="Total"
        method={props.methodRincian}
        methodName={`detail_of_activity_list[${props.id}].total`}
        read={true}
      />
      <div className={props.classSumber}>
        <Option
          label="Metode Pencairan"
          method={props.methodRincian}
          methodName={`detail_of_activity_list[${props.id}].thawing_method`}
          options={metodeOpt}
        />
      </div>
      <div className={props.classSumber}>
        <Option
          label="Pendapatan"
          method={props.methodRincian}
          methodName={`detail_of_activity_list[${props.id}].institution_income_id`}
          options={props.pendapatanOpt}
        />
      </div>
      <div className={props.classSumber}>
        <Option
          label="Jenis Belanja"
          method={props.methodRincian}
          methodName={`detail_of_activity_list[${props.id}].post`}
          options={jenisBelanja}
        />
      </div>
      <div className={props.classSumber}>
        <Option
          label="Program Bersama"
          method={props.methodRincian}
          methodName={`detail_of_activity_list[${props.id}].sharing_program`}
          options={sharingOptions}
        />
      </div>
    </div>
  );
}

export default ArrayRincian;
