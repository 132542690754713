import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import authStore from "./slice/auth";
import dashboardStore from "./slice/dashboard";
import loadProgramStore from "./slice/loadProgram";
import pendapatanStore from "./slice/pendapatan";
import programStore from "./slice/program";
import prokerStore from "./slice/proker";
import userStore from "./slice/user";
import PtkStore from "./slice/ptk";
import AntrianStore from "./slice/antrian";
import SharingProgram from "./slice/sharing";
import PencaiaranStore from "./slice/pencaiaran";
import RealizationStore from "./slice/realization";

export const store = configureStore({
  reducer: {
    auth: persistReducer(
      {
        key: "apakah",
        storage,
      },
      authStore
    ),
    loadProgram: loadProgramStore,
    proker: prokerStore,
    program: programStore,
    user: userStore,
    pendapatan: pendapatanStore,
    dashboard: dashboardStore,
    ptk: PtkStore,
    antrian: AntrianStore,
    sharingProgram: SharingProgram,
    pencairan: PencaiaranStore,
    realization:RealizationStore
  },
  middleware: [thunk],
});
export const persistor = persistStore(store);
