import React,{useState,useEffect} from 'react'

function SnackBar(props) {
    const [show, setShow] = useState(props.show)
    useEffect(() => {
      setShow(props.show)
    }, [props.show])
  
  return (
    <div className={`w-full h-full fixed top-0 left-0 z-10 flex justify-center ${show ? "visible" : "invisible"}`} onClick={() => props.toggle(false)}>
      <div className={`bg-slate-200 md:w-[40vw] absolute w-[95vw] transition-all ease-in-out duration-500 mx-auto border-4 ${show ? "bottom-10" : "-bottom-20"}`}>
        <div className='flex absolute right-0 justify-end p-1 shadow-xl cursor-pointer'></div>
        <div className=' mx-10'>
          <h1 className="text-center font-light md:text-xl py-7">{props.msg}</h1>
        </div>
      </div>
    </div>
  )
}

export default SnackBar