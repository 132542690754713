import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllPencairan } from "../store/actions/pencairan";

export default function useGetPencairan(trigger) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPencairan());
  }, [trigger]);
}
