import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDashboard,
  getAllDashboardLembaga,
} from "../store/actions/dashboard";

export default function useGetDashboard(trigger) {
  const dispatch = useDispatch();
  const { role, lembaga } = useSelector((state) => state.auth);
  useEffect(() => {
    if (role === "admin-lembaga") {
      dispatch(getAllDashboardLembaga(lembaga));
    } else if(role === 'bendahara'){
      dispatch(getAllDashboard());
    }
  }, [trigger]);
}
