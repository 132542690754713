import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getIncomeGroup } from "../store/actions/pendapatan";

export default function useGetGroupIncome(trigger) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIncomeGroup());
  }, [trigger]);
}
