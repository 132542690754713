import React from "react";
import Dialog from "../component/Dialog";

export default function FormPengajuan({ show, close, submit, children }) {
  return (
    <Dialog show={show} toggle={close}>
      <div className="bg-white md:w-[60vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl"> Pengajuan Dana</h1>
        </div>
        <div className="p-5 w-full">{children}</div>
        <div className="flex justify-end p-5">
          <button
            onClick={submit}
            className="border border-sky-400  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300"
          >
            Ajukan
          </button>
        </div>
      </div>
    </Dialog>
  );
}
