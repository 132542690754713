import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiProgram from "../../api/Program";

export const allKegiatanRinci = createAsyncThunk(
  "kegiatan/rincian/all",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProgram.getKegiatan(data.lembaga, data.status);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue("error");
    }
  }
);

export const allListKegiatanByLembaga = createAsyncThunk(
  "list-kegiatan/by/lembaga",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProgram.getKegiatanByLembaga(data.no_lembaga,data.tahun);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue("error");
    }
  }
);
export const allRincianKegiatan = createAsyncThunk(
  "rincian/kegiatan/all",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProgram.getRincian(
        data.tahun_ajar,
        data.no_kegiatan,
        data.no_sub_kegiatan
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue("error");
    }
  }
);

export const allTotalKegiatan = createAsyncThunk(
  "total/kegiatan",
  async (noLem, { rejectWithValue }) => {
    try {
      const res = await ApiProgram.getTotalKegiatan(noLem);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);

export const allTotalPendapatan = createAsyncThunk(
  "total/pendapatan",
  async (no_lem, { rejectWithValue }) => {
    try {
      const res = await ApiProgram.getTotalPendapatan(no_lem);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);

export const allRincianByLembaga = createAsyncThunk(
  "rincian-kegiatan/filter/by-lembaga",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiProgram.getRincianByLembaga(
        data.no_lembaga,
        data.status
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);
