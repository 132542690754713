import { useSelector } from "react-redux";
import useGetRealization from "../hooks/useRealization";
import HeaderUi from "../component/HeaderUi";
import { formatRupiah } from "../component/FormatMoney";

function Realization() {
  const { realization, loadingRealization } = useSelector(
    (state) => state.realization
  );
  useGetRealization(true);
  return (
    <div>
      <HeaderUi titleHeader="Realisasi" />
      {loadingRealization ? (
        <div>Loading...</div>
      ) : (
        <div className="relative overflow-x-auto mt-10">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  No
                </th>
                <th scope="col" className="px-6 py-3">
                  Kegiatan
                </th>
                <th scope="col" className="px-6 py-3">
                  Realisasi
                </th>
                <th scope="col" className="px-6 py-3">
                  Anggaran
                </th>
                <th scope="col" className="px-6 py-3">
                  Persentasi
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {realization.map((e, i) => (
                <tr key={i}>
                  <td className="px-6 py-4 text-left">{i + 1}</td>
                  <td className="px-6 py-4 text-left">
                    {e.activity.toUpperCase()}
                  </td>
                  <td className="px-6 py-4 text-left">
                    Rp {formatRupiah(e.realization, 0)}
                  </td>
                  <td className="px-6 py-4 text-right">
                    Rp {formatRupiah(e.budget)}
                  </td>
                  <td className="px-6 py-4 text-right">
                  <div class="w-full bg-gray-200 rounded-full ">
                      <div
                        className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{width:`${parseInt(e.persentation)}%`}}
                      >
                      </div>
                    
                    </div>
                    {` ${e.persentation}`}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Realization;
