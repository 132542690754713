import React from "react";
import Chart from "../component/Chart";
import HeaderUi from "../component/HeaderUi";
import { useSelector } from "react-redux";
import useGetDashboard from "../hooks/useGetDashboard";

function Dashboard() {
  const { chartDashboard } = useSelector((state) => state.dashboard);
  useGetDashboard(true);
  const data = {
    labels: chartDashboard?.labels,
    datasets: [
      {
        label: "Anggaran",
        data: chartDashboard?.total,
        backgroundColor: "rgb(53, 162, 235)",
        borderColor: "rgb(53, 162, 235)",
      },
      {
        label: "Dialokasikan",
        data: chartDashboard?.budgeted,
        backgroundColor: "rgb(210, 0, 0)",
        borderColor: "rgb(210, 0, 0)",
      },
    ],
  };
  return (
    <div>
      <HeaderUi titleHeader="Dashboard" />
      <div className="m-7 p-4 bg-white">
        <Chart data={data} />
      </div>
    </div>
  );
}

export default Dashboard;
