import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllSharingProgram } from "../store/actions/sharing";

export default function useGetSharingProgram(trigger) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllSharingProgram());
  }, [trigger]);
}
