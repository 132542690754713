import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

function Chart(props) {
  // const dispatch = useDispatch()
  // const {listAll,pengeluaranAll} = useSelector((state)=>state.dashboard)
  // useEffect(()=>{
  //     dispatch(allList())
  //     dispatch(allPengeluaran())
  // },[])
  // const labels = []
  // const rekap = []
  // const pengeluaran = []
  // listAll.map((e)=>{
  //     labels.push(e.bulan)
  //     rekap.push(e.total)
  // })
  // pengeluaranAll.map((e)=>{
  //     pengeluaran.push(e.total)
  // })
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 15,
          },
        },
      },
      title: {
        display: true,
        text: "Rekap Anggaran Ponpes Abu Hurairah Mataram",
        labels: {
          font: {
            size: 100,
          },
        },
      },
    },
  };
  return (
    <Bar
      height={props.height}
      width={props.width}
      options={options}
      data={props.data}
    />
  );
}

export default Chart;
