import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allKegiatanRinci } from "../store/actions/program";

export default function useGetKegiatanByStatus(lembaga, status, trigger) {
  const dispatch = useDispatch();
  const statusList = [0, 1, 2];
  useEffect(() => {
    if (lembaga && statusList.includes(status)) {
      dispatch(allKegiatanRinci({ lembaga, status }));
    }
  }, [lembaga, status, trigger]);
}
