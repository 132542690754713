import api from "./http";

const ApiProker = {
  postLembaga: (data) => {
    return api.post(`/institution`, data);
  },
  postProgram: (data) => {
    return api.post(`/apakah/program`, data);
  },
  postKomponen: (data) => {
    return api.post(`/apakah/component`, data);
  },
  postKegiatan: (data) => {
    return api.post(`/apakah/activity`, data);
  },
  posSubKegiatan: (data) => {
    return api.post(`/apakah/sub-activity`, data);
  },
  postRincian: (data) => {
    return api.post(`/apakah/detail-of-activity`, data);
  },
  putLembaga: (no_lem, data) => {
    return api.put(`/apakah/institution/${no_lem}`, data);
  },
  putProgram: (programId, data) => {
    return api.put(`/apakah/program/${programId}`, data);
  },
  putKomponen: (no_kom, data) => {
    return api.put(`/apakah/component/${no_kom}`, data);
  },
  putKegiatan: (no_keg, data) => {
    return api.put(`/apakah/activity/${no_keg}`, data);
  },
  putSubKegiatan: (no_sub, data) => {
    return api.put(`/apakah/sub-activity/${no_sub}`, data);
  },
  putDetail: (id, data) => {
    return api.put(`/apakah/detail-of-activity/${id}`, data);
  },
  deleteLembaga: (no_lem) => {
    return api.delete(`/apakah/institution/${no_lem}`);
  },
  deleteProgram: (no_prog) => {
    return api.delete(`/apakah/program/${no_prog}`);
  },
  deleteKomponen: (no_kom) => {
    return api.delete(`/apakah/component/${no_kom}`);
  },
  deleteKegiatan: (idKegiatan) => {
    return api.delete(`/apakah/activity/${idKegiatan}`);
  },
  deleteSubKegiatan: (idSub) => {
    return api.delete(`/apakah/sub-activity/${idSub}`);
  },
  deleteRincian: (uuid) => {
    return api.delete(`/apakah/detail-of-activity/${uuid}`);
  },
  postPersetujuan: (data) => {
    return api.post(`/apakah/activity/approve`, data);
  },
  addNewAnggaran: (data) => {
    return api.post(`/apakah/rincian-kegiatan/activity/new-year`, data);
  },
  addQuisioener: (data) => {
    return api.post(`/apakah/weight-activity/answer`, data);
  },
};
export default ApiProker;
