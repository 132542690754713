import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useGetRoleBySystem from "../hooks/useGetRole";

export default function useRoleOptions(system_id,trigger) {
  const { role } = useSelector((state) => state.loadProgram);
  const [allRoleOptions, setAllRoleOptions] = useState([]);
  useGetRoleBySystem(system_id,trigger);
  useEffect(() => {
    if (role.length > 0) {
      const allRole = role.map((e) => {
        return { value: e.uuid, label: e.nama_role };
      });
      setAllRoleOptions(allRole);
    }
  }, [role]);
  return allRoleOptions;
}
