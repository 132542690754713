import React, { useEffect, useState } from "react";
import { HiOutlinePencilSquare, HiOutlineTrash } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { currency } from "../component/Currency";
import DialogDelete from "../component/DialogDelete";
import { convertToRupiah } from "../component/FormatMoney";
import FormEditPendapatan from "../component/FormEditPendapatan";
import FormPendapatan from "../component/FormPendapatan";
import HeaderUi from "../component/HeaderUi";
import Loader from "../component/Loader";
import SnackBar from "../component/Snackbar";
import Table from "../component/Table";
import {
  addIncomeGroup,
  addPendapatan,
  destroyPendapatan,
  updatePendapatan,
} from "../store/actions/pendapatan";
import useActiveYear from "../options/tahunActive";
import useLembagaApakahOptions from "../options/lembaga";
import useGetPendapatan from "../hooks/useGetPendapatan";
import Button from "../component/Button";
import FormGrupPendapatan from "../component/FormGrupPendapatan";

function Pendapatan() {
  const [formPedapatan, setFormPendapatan] = useState(false);
  const [formEditPendapatan, setFormEditPendapatan] = useState(false);
  const [formAddIncomeGroup, setFormAddIncomeGroup] = useState(false);
  const [dialogDeletePendapatan, setDialogDeletePendapatan] = useState(false);
  const [snackForm, setSnackForm] = useState(false);
  const [lembagaValue, setLembagValue] = useState({});
  const [saveOne, setSaveOne] = useState({});
  const [incomeId, setIncomeId] = useState("");
  const { role } = useSelector((state) => state.auth);
  const { msgPendapatan, pendapatanAll, loadingPendapatan } = useSelector(
    (state) => state.pendapatan
  );
  const dispatch = useDispatch();
  const tambahPendapatan = (e) => {
    e.total = convertToRupiah(e.total);
    dispatch(addPendapatan(e));
    setFormPendapatan(!formPedapatan);
    setSnackForm(!snackForm);
  };
  const editPendapatan = (e) => {
    e.total = convertToRupiah(e.total);
    dispatch(updatePendapatan({ uuid: incomeId, edit: e }));
    setFormEditPendapatan(!formEditPendapatan);
    setSnackForm(!snackForm);
  };
  const hapusPendapatan = () => {
    dispatch(destroyPendapatan(incomeId));
    setDialogDeletePendapatan(!dialogDeletePendapatan);
    setSnackForm(!snackForm);
  };
  const saveGroupPendapatan = (value) => {
    dispatch(addIncomeGroup(value));
    setFormAddIncomeGroup(false);
    setSnackForm(true);
  };
  const { year } = useActiveYear();
  useGetPendapatan(lembagaValue?.value, year, 3, snackForm);
  const lembagaApakahOptions = useLembagaApakahOptions();
  const loadPendapatan = lembagaValue?.value ? pendapatanAll : [];
  // const loadPendapatan = role !== 'admin-lembaga' && lembagaValue?.value ? pendapatanAll : role === 'admin-lembaga' ? pendapatanAll : [];
  return (
    <div>
      <Loader show={loadingPendapatan} size="w-5 h-5" />
      <SnackBar show={snackForm} toggle={setSnackForm} msg={msgPendapatan} />
      <FormPendapatan
        show={formPedapatan}
        close={setFormPendapatan}
        submit={tambahPendapatan}
      />
      <FormGrupPendapatan
        show={formAddIncomeGroup}
        close={setFormAddIncomeGroup}
        submit={saveGroupPendapatan}
      />
      <FormEditPendapatan
        show={formEditPendapatan}
        close={setFormEditPendapatan}
        submit={editPendapatan}
        data={saveOne}
      />
      <DialogDelete
        show={dialogDeletePendapatan}
        close={setDialogDeletePendapatan}
        click={hapusPendapatan}
      />
      <HeaderUi titleHeader="Pendapatan" location={"list"} />
      <Table
        classSelect={"block"}
        // classSelect={role === "admin-lembaga" ? "invisible" : "block"}
        titleFirst="Tambah"
        optSelect={lembagaApakahOptions}
        setChange={setLembagValue}
        change={lembagaValue}
        clickFirst={() => {
          setFormPendapatan(!formPedapatan);
        }}
        filter={
          <>
            <div className="mt-5">
              <Button
                title="Tambah Group"
                click={() => setFormAddIncomeGroup(true)}
              />
            </div>
          </>
        }
      >
        {loadPendapatan?.length > 0 && loadPendapatan.length === 0 ? (
          <div>
            <h1 className="font-light text-center my-10 text-xl">
              Tidak Ada Pendapatan
            </h1>
          </div>
        ) : (
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-3 pb-2">
            <table className="text-sm text-left text-gray-500 w-full">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-3 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    No Lembaga
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Pendapatan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tahun Ajar
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Total
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Edit
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {pendapatanAll.map((d, id) => (
                  <tr
                    className={`bg-white border-b hover:bg-gray-100 `}
                    key={id}
                  >
                    <td className="px-3 py-3">{id + 1}</td>
                    <td className="px-6 py-3">{d.institution_id}</td>
                    <td className="px-6 py-3">{d.name}</td>
                    <td className="px-6 py-3">{d.academic_year}</td>
                    <td className="px-6 py-3">{currency(parseInt(d.total))}</td>
                    <td className="px-6 py-3">
                      <HiOutlinePencilSquare
                        className="w-5 h-5 cursor-pointer mx-1"
                        onClick={() => {
                          // dispatch(getOneByNoPendapatan(d.no_pendapatan));
                          setFormEditPendapatan(!formEditPendapatan);
                          setIncomeId(d.id);
                          setSaveOne(d);
                        }}
                      />
                    </td>
                    <td className="px-6 py-3">
                      <HiOutlineTrash
                        className="w-5 h-5 cursor-pointer mx-3"
                        onClick={() => {
                          setDialogDeletePendapatan(!dialogDeletePendapatan);
                          setIncomeId(d.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Table>
    </div>
  );
}

export default Pendapatan;
