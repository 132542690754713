import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Dialog from "./Dialog";
import usePendapatanOptions from "../options/pendapatan";
import useSatuanOptions from "../options/satuan";
import { useSelector } from "react-redux";
import useResetForm from "../hooks/custom/useResetForm";
import ArrayRincian from "./ArrayRincian";
import AddButton from "./AddButton";

export default function FormEditDetail(props) {
  const { role, lembaga } = useSelector((state) => state.auth);
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        detail_of_activity_list: yup.array().of(
          yup.object().shape({
            description: yup.string().required("Tidak Boleh Kosong"),
            unit_id: yup.string().required("Tidak Boleh Kosong"),
            vol: yup.string().required("Tidak Boleh Kosong"),
            unit_price: yup.string().required("Tidak Boleh Kosong"),
            from: yup.number().required("Tidak Boleh Kosong"),
            until: yup
              .number()
              .min(yup.ref("from"), "Bulan Tidak Boleh Lebih Kecil")
              .required("Tidak Boleh Kosong"),
            total: yup.string().required("Tidak Boleh Kosong"),
            uuid: yup.string().nullable(),
            institution_income_id: yup.string().required("Tidak Boleh Kosong"),
            thawing_method: yup.string().required("Tidak Boleh Kosong"),
            post: yup.number().required("Tidak boleh kosong"),
            sharing_program: yup.number().required("Tidak boleh kosong"),
          })
        ),
      })
    ),
  });
  const satuanOpt = useSatuanOptions(true);
  const institute = props?.lembaga?.value;
  // const institute = role === "admin-lembaga" ? lembaga : props?.lembaga?.value;
  const pendapatanOptions = usePendapatanOptions(institute, props.tahun, 3);
  useResetForm(
    method,
    {
      detail_of_activity_list: [
        {
          ...props.data,
          sharing_program: props?.data?.sharing_program ? 1 : 0,
        },
      ],
    },
    method.show || props.data
  );
  return (
    <Dialog show={props.show} toggle={props.close} scroll>
      <div className="bg-white md:w-[80vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl"> Tambah Rincian</h1>
        </div>
        <form
          onSubmit={method.handleSubmit(props.submit)}
          className="px-6 space-y-1"
        >
          <h1 className="text-gray-600 text-xl">Rincian</h1>
          <ArrayRincian
            methodRincian={method}
            id={0}
            satuanOpt={satuanOpt}
            show={props.show}
            pendapatanOpt={pendapatanOptions}
          />
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}
