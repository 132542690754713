import api from "./http";

const ApiAuth = {
    login : (data) =>{
        return api.post(`/authentication/login`,data)
    },
    logout : () =>{
        return api.get(`/logout`)
    },
    putUser : (data) =>{
        return api.put(`/user`,data)
    },
    getUser : () =>{
        return api.get(`/user`)
    },
    postUser : (data) =>{
        return api.post(`/user`,data)
    },
    deleteUser : (uuid) =>{
        return api.delete(`/user/${uuid}`)
    },
    putUserByAdmin : (uuid,data) =>{
        return api.put(`/user/profile/${uuid}`,data)
    },
    addSistem : (uuid,data)=>{
        return api.post(`/user/system/${uuid}`,data)
    },
    deleteSistem : (uuid,uuid_sis)=>{
        return api.delete(`/user/system/${uuid}/${uuid_sis}`)
    },
    updateSistem : (uuid,uuid_sistem,data) =>{
        return api.put(`/user/system/${uuid}/${uuid_sistem}`,data)
    },
}
export default ApiAuth