import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useGetSharingProgram from "../hooks/useGetSharingProgram";

export default function useSharingProgramOptions(trigger) {
  const { allSharingProgram } = useSelector((state) => state.sharingProgram);
  const [allSharingProgramOptions, setAllSharingProgramOptions] = useState([]);
  useGetSharingProgram(trigger);
  useEffect(() => {
    if (allSharingProgram.length > 0) {
      const allSatuan = allSharingProgram.map((e) => {
        return { value: e.uuid, label: e.name };
      });
      setAllSharingProgramOptions(allSatuan);
    }
  }, [allSharingProgram]);
  return allSharingProgramOptions;
}
