import React, { useEffect, useState } from 'react'
import { HiOutlineChevronDown, HiOutlinePencil } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import DialogDelete from '../component/DialogDelete'
import FormAddSistem from '../component/FormAddSistem'
import FormAddUser from '../component/FormAddUser'
import FormEditSistem from '../component/FormEditSistem'
import FormEditUser from '../component/FormEditUser'
import HeaderUi from '../component/HeaderUi'
import Loader from '../component/Loader'
import SnackBar from '../component/Snackbar'
import Table from '../component/Table'
import { addUser, destroyUser, editUserByAdmin, postSistem } from '../store/actions/user'
import useGetAllUser from '../hooks/useGetAllUser'
import useLembagaApakahOptions from '../options/lembaga'

function TableAdmin() {
    const [editUser, setEditUser] = useState(false)
    const [formDeleteUser, setFormDeleteUser] = useState(false)
    const [formSistem, setFormSistem] = useState(false)
    const [dropActions, setDropActions] = useState(false)
    const [formAddSystem, setFormAddSystem] = useState(false)
    const [uuid, setUuid] = useState("")
    const [check, setCheck] = useState()
    const [userOne, setUserOne] = useState({})
    const [formAdd, setFormAdd] = useState(false)
    const [snackUser, setSnackUser] = useState(false)
    const dispatch = useDispatch()
    const { allUser, msgUser,loadingUser } = useSelector((state) => state.user)
    useGetAllUser(snackUser)
    const tambahAdmin = (e) => {
        dispatch(addUser(e))
        setFormAdd(!formAdd)
        setSnackUser(!snackUser)
    }
    const updateAdmin = (e) => {
        dispatch(editUserByAdmin({uuid:userOne.uuid,edit:e}))
        setEditUser(!editUser)
        setSnackUser(!snackUser)
    }
    const tambahSistem = (e) => {
        dispatch(postSistem({uuid,post:e}))
        setFormAddSystem(!formAddSystem)
        setSnackUser(!snackUser)
    }
    const onDelete = () => {
        dispatch(destroyUser(uuid))
        setFormDeleteUser(!formDeleteUser)
        setSnackUser(!snackUser)
    }
    const lembagaApakahOptions = useLembagaApakahOptions(true,false)
    return (
        <div>
            <Loader show={loadingUser} size="w-5 h-5" />
            <FormAddUser show={formAdd} exitForm={setFormAdd} submit={tambahAdmin} />
            <FormEditUser show={editUser} close={setEditUser} submit={updateAdmin} data={userOne} />
            <FormEditSistem show={formSistem} close={setFormSistem} data={userOne} snackEdit={snackUser} setSnackEdit={setSnackUser} />
            <FormAddSistem show={formAddSystem} close={setFormAddSystem} submit={tambahSistem} />
            <DialogDelete show={formDeleteUser} close={setFormDeleteUser} click={onDelete} />
            <SnackBar show={snackUser} toggle={setSnackUser} msg={msgUser} />
            <HeaderUi titleHeader="Admin" location={`list`} />
            <Table
                classSelect="hidden"
                titleFirst="Tambah"
                clickFirst={() => setFormAdd(!formAdd)}
            >
                {allUser.length === 0 ? <div>
                    <h1 className='font-light text-center my-10 text-xl'>Tidak Ada User</h1>
                </div> :
                    <div className="overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden">
                        <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        No
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Username
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Nama
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Sistem
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Role
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {allUser.map((d, id) => (
                                    <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                        <td className='px-6 py-3'>{id + 1}</td>
                                        <td className='px-6 py-3'>{d.username}</td>
                                        <td className='px-6 py-3'>{d.nama}</td>
                                        <td className='px-6 py-3'>{d.sistem.map((e, id) => (
                                            <h1 key={id} className=''>{e.nama_sistem}</h1>
                                        ))}</td>
                                        <td className='px-6 py-3'>
                                            {d.sistem.map((f, id) => (
                                                <h1 key={id} className=''>{f.nama_role} {f.lembaga_apakah && f.nama_sistem === 'apakah' ? `(${lembagaApakahOptions?.find((data)=>data?.value === f.lembaga_apakah)?.label})`:''}</h1>
                                            ))}
                                        </td>
                                        <td className='px-6 py-3'><HiOutlineChevronDown className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                            setDropActions(!dropActions)
                                            setCheck(id)
                                        }} /><div className={`border absolute shadow-lg bg-slate-100 ease-in-out transition-all duration-200 ${dropActions ? `mt-2 bg-opacity-100 visible` : `-mt-0 text-transparent bg-opacity-0 invisible`} ${id === check ? "block" : "hidden"} `}>
                                                <div className='py-2 px-2 hover:bg-slate-200 cursor-pointer' onClick={() => {
                                                    setDropActions(!dropActions)
                                                    setUserOne(d)
                                                    setEditUser(!editUser)
                                                    setUuid(d.uuid)
                                                }}>
                                                    Edit User
                                                </div>
                                                <div className='py-2 px-2 hover:bg-slate-200 cursor-pointer' onClick={() => {
                                                    setFormAddSystem(!formAddSystem)
                                                    setUuid(d.uuid)
                                                    setDropActions(!dropActions)
                                                }}>
                                                    Tambah Sistem
                                                </div>
                                                <div className='py-2 px-2 hover:bg-slate-200 cursor-pointer' onClick={() => {
                                                    setUserOne(d)
                                                    setUuid(d.uuid)
                                                    setDropActions(!dropActions)
                                                    setFormSistem(!formSistem)
                                                }}>
                                                    Edit Sistem
                                                </div>
                                                <div className='py-2 px-2 hover:bg-slate-200 cursor-pointer' onClick={() => {
                                                    setFormDeleteUser(!formDeleteUser)
                                                    setUuid(d.uuid)
                                                    setDropActions(!dropActions)
                                                }}>
                                                    Delete
                                                </div>
                                            </div></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
            </Table>
        </div>
    )
}

export default TableAdmin