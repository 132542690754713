import { createSlice } from "@reduxjs/toolkit";
import {
  addLembaga,
  addProgram,
  addKomponen,
  addKegiatan,
  addSubKegiatan,
  addRincian,
  destroyRincian,
  updateLembaga,
  updateProgram,
  updateKomponen,
  updateKegiatan,
  updateSubKegiatan,
  destroyLembaga,
  destroyProgram,
  destroyKomponen,
  destroyKegiatan,
  destroySubKegiatan,
  addPersetujuan,
  postNewAnggaran,
  updateDetail,
} from "../actions/proker";

export const prokerStore = createSlice({
  name: "proker",
  initialState: {
    msgProker: "",
    loadingProker: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addLembaga.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(addLembaga.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(addLembaga.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(addProgram.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(addProgram.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(addProgram.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(addKomponen.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(addKomponen.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(addKomponen.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(addKegiatan.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(addKegiatan.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(addKegiatan.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(addSubKegiatan.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(addSubKegiatan.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(addSubKegiatan.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(addRincian.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(addRincian.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(addRincian.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(destroyRincian.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(destroyRincian.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(destroyRincian.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(updateLembaga.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(updateLembaga.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(updateLembaga.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(updateProgram.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(updateProgram.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(updateProgram.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(updateKomponen.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(updateKomponen.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(updateKomponen.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(updateKegiatan.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(updateKegiatan.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(updateKegiatan.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(updateSubKegiatan.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(updateSubKegiatan.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(updateSubKegiatan.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(updateDetail.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(updateDetail.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(updateDetail.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(destroyLembaga.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(destroyLembaga.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(destroyLembaga.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(destroyProgram.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(destroyProgram.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(destroyProgram.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(destroyKomponen.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(destroyKomponen.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(destroyKomponen.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(destroyKegiatan.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(destroyKegiatan.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(destroyKegiatan.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(destroySubKegiatan.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(destroySubKegiatan.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(destroySubKegiatan.rejected, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload;
      })
      .addCase(addPersetujuan.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(addPersetujuan.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(addPersetujuan.rejected, (state) => {
        state.loadingProker = false;
      })
      .addCase(postNewAnggaran.pending, (state) => {
        state.loadingProker = true;
      })
      .addCase(postNewAnggaran.fulfilled, (state, action) => {
        state.loadingProker = false;
        state.msgProker = action.payload.message;
      })
      .addCase(postNewAnggaran.rejected, (state) => {
        state.loadingProker = false;
      })
      // .addCase(postQuisioner.pending, (state) => {
      //   state.loadingProker = true;
      // })
      // .addCase(postQuisioner.fulfilled, (state, action) => {
      //   state.loadingProker = false;
      //   state.msgProker = action.payload.message;
      // })
      // .addCase(postQuisioner.rejected, (state) => {
      //   state.loadingProker = false;
      // });
  },
});
export default prokerStore.reducer;
