import { createSlice } from "@reduxjs/toolkit";
import {getRealization} from "../actions/realization"

export const realizationStore = createSlice({
    name:"realization",
    initialState:{
        realization:[],
        loadingRealization:false
    },
    extraReducers:builder=>{
        builder
        .addCase(getRealization.pending,(state)=>{
            state.loadingRealization=true
        }).addCase(getRealization.fulfilled, (state, action)=>{
            state.loadingRealization = false
            state.realization = action.payload
        }).addCase(getRealization.rejected, (state)=>{
            state.loadingRealization = false
        })
    }
})

export default realizationStore.reducer;