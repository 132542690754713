import api from "./http";

const ApiDashboard = {
  getDashboard: () => {
    return api.get(`/apakah/dashboard`);
  },
  getDashboardLembaga: (institution_id) => {
    return api.get(`/apakah/dashboard/${institution_id}`);
  },
  getPengeluaran: () => {
    return api.get(`/apakah/rincian-kegiatan/dashboard`);
  },
};
export default ApiDashboard;
