import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from "yup"
import { allLembaga } from '../store/actions/loadProgram'
import Option from './Option'
import { HiEyeSlash, HiEye } from 'react-icons/hi2'
import AddButton from './AddButton'
import Input from './Input'
import Dialog from './Dialog'
import Radio from './Radio'
import SistemHelp from "./SistemHelp"
import useLembagaApakahOptions from '../options/lembaga'
import useResetForm from '../hooks/custom/useResetForm'

function FormAddUser(props) {
    const [eye, setEye] = useState(false)
    const { generalUser } = useSelector((state) => state.auth)
    const method = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                name: yup.string().required("Tidak Boleh Kosong"),
                username: yup.string().required("Tidak Boleh Kosong"),
                password: yup.string().min(8, "Minimal 8 Karakater").required("Tidak Boleh Kosong"),
                general_user: yup.string().oneOf(["true", "false"], "Pilih Salah Satu").required("Tidak Boleh Kosong"),
                sistem: yup.array().of(yup.object().shape({
                    system_id: yup.string().required("Tidak Boleh Kosong"),
                    role_id: yup.string().required("Tidak Boleh Kosong"),
                    super_admin: yup.string().oneOf(["true", "false"], "Pilih Salah Satu").required("Tidak Boleh Kosong"),
                    institution_apakah: yup.string()
                }))
            })
        ),
        defaultValues: { sistem: [{ system_id: "", role_id: "" }] }
    })
    const lembagaOpt = useLembagaApakahOptions()
    const { fields, append, remove } = useFieldArray({
        control: method.control, name: "sistem"
    })
    useResetForm(method,{},props.show)
    return (
        <Dialog show={props.show} toggle={props.exitForm}>
            <div className='bg-white md:w-[40vw] w-[90vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'> Tambah User</h1>
                </div>
                <form onSubmit={method.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <div className='flex justify-between space-x-4'>
                        <div className='w-[100%]'>
                            <Input
                                label="Nama"
                                method={method}
                                methodName="name"
                            />
                            <Input
                                label="username"
                                method={method}
                                methodName="username"
                            />
                            <Input
                                type={eye ? "text" : "password"}
                                label="Password"
                                method={method}
                                methodName="password"
                                icon={eye ? <HiEye className='w-6 h-6 -mr-3 cursor-pointer mt-3 absolute right-5' onClick={() => setEye(!eye)} /> : <HiEyeSlash className='w-6 h-6 my-auto -mr-3 cursor-pointer mt-3 absolute right-5' onClick={() => setEye(!eye)} />}
                            />
                            <div>
                                <h1 className='font-light text-lg mb-2'>General User</h1>
                                <div className='flex my-auto md:space-x-0 space-x-2'>
                                    <Radio method={method} methodName="general_user" value="true" label="Iya" />
                                    <Radio method={method} methodName="general_user" value="false" label="Tidak" />
                                </div>
                                {method.formState.errors["general_user"] && (
                                    <p className="text-red-600 mt-1 mx-1">
                                        {method.formState.errors["general_user"].message}
                                    </p>
                                )}
                            </div>
                            <button type='button' onClick={() => append({ system_id: "", role_id: "" })} className="border border-sky-400  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300 w-full my-5">Tambah Sistem</button>
                        </div>

                        <div className='w-full'>
                            {fields.map((d, id) => (
                                <div key={id}>
                                    <SistemHelp method={method} id={id} />
                                    <button type='button' onClick={() => remove(id)} className={`border border-red-700 my-1 px-5 py-[2px] hover:border-red-800 transition-colors ease-in-out duration-300 hover:text-red-800 ${generalUser ? "block": "hidden"}`}>Hapus</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <AddButton />
                </form>
            </div>
        </Dialog>
    )
}

export default FormAddUser