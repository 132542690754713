import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allKegiatan } from "../store/actions/loadProgram";

export default function useGetKegiatan(komponenId,trigger) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (komponenId) {
      dispatch(allKegiatan(komponenId));
    }
  }, [komponenId, trigger]);
}
