import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getRole } from "../store/actions/loadProgram";

export default function useGetRoleBySystem(system_id,trigger) {
  const dispatch = useDispatch();
  useEffect(() => {
    if(system_id){
        dispatch(getRole(system_id));
    }
  }, [,system_id,trigger]);
}
