import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useGetKegiatan from "../hooks/useGetKegiatan";

export default function useKegiatanOptions(komponenId, tahun, trigger) {
  const { kegiatan } = useSelector((state) => state.loadProgram);
  const [allKegiatanOptions, setAllKegiatanOptions] = useState([]);
  useGetKegiatan(komponenId, tahun, trigger);
  useEffect(() => {
    if (kegiatan.length > 0) {
      const allKegiatan = kegiatan.map((e) => {
        return { value: e.id, label: e.name };
      });
      setAllKegiatanOptions(allKegiatan);
    }
  }, [kegiatan, komponenId, tahun, trigger]);
  return allKegiatanOptions;
}
