import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allLembaga } from '../store/actions/loadProgram'

export default function useGetLembagaApakah(trigger) {
    const dispatch = useDispatch()
    const {role} = useSelector(state=>state.auth)
    useEffect(()=>{
        // if(role !== 'admin-lembaga'){
            dispatch(allLembaga())
        // }
    },[role,trigger])
}
