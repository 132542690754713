import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useGetLembagaApakah from "../hooks/useGetLembagaApakah";

export default function useLembagaApakahOptions(trigger,includeAll) {
  const { lembaga } = useSelector((state) => state.loadProgram);
  const [allLembagaOptions, setAllLembagaOptions] = useState([]);
  useGetLembagaApakah(trigger)
  useEffect(() => {
    if (lembaga.length > 0) {
      const allLembaga = lembaga.map((e) => {
        return { value: e.id, label: e.name };
      });
      includeAll && allLembaga.splice(0, 0, { value: "-", label: "SEMUA" });
      setAllLembagaOptions(allLembaga);
    }
  }, [lembaga,trigger]);
  return allLembagaOptions;
}
