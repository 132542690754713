import { useDispatch } from "react-redux";
import { allSatuan } from "../store/actions/loadProgram";
import { useEffect } from "react";

export default function useGetSatuan(trigger) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allSatuan());
  }, [trigger]);
}
