import React, { useState } from "react";
import {
  HiOutlineChevronUp,
  HiOutlineChevronDown,
  HiOutlinePlus,
  HiOutlinePencilSquare,
  HiOutlineTrash,
} from "react-icons/hi2";
import FormKomponen from "../component/FormKomponen";
import FormLembaga from "../component/FormLembaga";
import FormProgram from "../component/FormProgram";
import HeaderUi from "../component/HeaderUi";
import Table from "../component/Table";
import FormKegiatan from "../component/FormKegiatan";
import { useDispatch, useSelector } from "react-redux";
import {
  addKegiatan,
  addKomponen,
  addLembaga,
  addProgram,
  addRincian,
  addSubKegiatan,
  destroyKegiatan,
  destroyKomponen,
  destroyLembaga,
  destroyProgram,
  destroyRincian,
  destroySubKegiatan,
  updateDetail,
  updateKegiatan,
  updateKomponen,
  updateLembaga,
  updateProgram,
  updateSubKegiatan,
} from "../store/actions/proker";
import Loader from "../component/Loader";
import SnackBar from "../component/Snackbar";
import FormSubKegiatan from "../component/FormSubKegiatan";
import FormRincian from "../component/FormRincian";
import {
  allRincianKegiatan,
  allRincianSubKegiatan,
} from "../store/actions/loadProgram";
import DialogRincian from "../component/DialogRincian";
import FormEditProgram from "../component/FormEditProgram";
import DialogDelete from "../component/DialogDelete";
import FormEditKomponen from "../component/FormEditKomponen";
import FormEditSub from "../component/FormEditSub";
import FormEditKegiatan from "../component/FormEditKegiatan";
import FormEditLembaga from "../component/FormEditLembaga";
import { currency } from "../component/Currency";
import { convertToRupiah } from "../component/FormatMoney";
import useGetProgram from "../hooks/useGetProgram";
import useGetKomponen from "../hooks/useGetKomponen";
import useGetKegiatan from "../hooks/useGetKegiatan";
import useLembagaApakahOptions from "../options/lembaga";
import useTahunActive from "../options/tahunActive";

function Kegiatan() {
  const dispatch = useDispatch();
  const [formAddLembaga, setFormAddLembaga] = useState(false);
  const [formAddProgram, setFormAddProgram] = useState(false);
  const [formAddComponent, setFormAddComponent] = useState(false);
  const [formAddActivity, setFormAddActivity] = useState(false);
  const [formAddSubActivity, setFormAddSubActivity] = useState(false);
  const [formAddDetailActivity, setFormAddDetailActivity] = useState(false);
  const [formEditInstitution, setFormEditInstitution] = useState(false);
  const [formEditProgram, setFormEditProgram] = useState(false);
  const [formEditComponent, setFormEditComponent] = useState(false);
  const [formEditActivity, setFormEditActivity] = useState(false);
  const [formEditSubActivity, setFormEditSubActivity] = useState(false);
  const [modalDetailActivity, setModalDetailActivity] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [dropdownProgram, setDropdownProgram] = useState(false);
  const [dropdownKomponen, setDropdownKomponen] = useState(false);
  const [dropdownKegiatan, setDropdwonKegiatan] = useState(false);
  const [modalDeleteLembaga, setModalDeleteLembaga] = useState(false);
  const [modalDeleteProgram, setModalDeleteProgram] = useState(false);
  const [modalDeleteKomponen, setModalDeleteKomponen] = useState(false);
  const [modalDeleteKegiatan, setModalDeleteKegiatan] = useState(false);
  const [modalDeleteSubKegiatan, setModalDeleteSubKegiatan] = useState(false);
  const [detail, setDetail] = useState(false);
  const [institution, setInstitution] = useState({});
  const [programId, setProgramId] = useState("");
  const [componentId, setComponentId] = useState("");
  const [activityId, setActivityId] = useState("");
  const [subActivityId, setSubActivityId] = useState("");
  const [programNo, setProgramNo] = useState("");
  const [componentNo, setComponentNo] = useState("");
  const [activityNo, setActivityNo] = useState("");
  const [subActivityNo, setSubActivityNo] = useState("");
  const [programName, setProgramName] = useState("");
  const [componentName, setComponentName] = useState("");
  const [oneActivity, setOneActivity] = useState("");
  const [subActivityName, setSubActivityName] = useState("");
  const { program, komponen, kegiatan, loadingProgram } = useSelector(
    (state) => state.loadProgram
  );
  const { lembaga, role } = useSelector((state) => state.auth);
  const { msgProker } = useSelector((state) => state.proker);
  const { rincianKegiatan, totalPendapatan, totalKegiatan } = useSelector(
    (state) => state.program
  );
  const tahunActive = useTahunActive().year;
  const lembagaApakahOptions = useLembagaApakahOptions(modalMessage);
  const tambahLembaga = (data) => {
    data.academic_year = tahunActive;
    dispatch(addLembaga(data));
    setFormAddLembaga(!formAddLembaga);
    setModalMessage(!modalMessage);
  };
  const tambahProgram = (data) => {
    data.academic_year = tahunActive;
    dispatch(addProgram(data));
    setFormAddProgram(!formAddProgram);
    setModalMessage(!modalMessage);
  };
  const tambahKomponen = (data) => {
    data.academic_year = tahunActive;
    dispatch(addKomponen(data));
    setFormAddComponent(!formAddComponent);
    setModalMessage(!modalMessage);
  };
  const tambahKegiatan = (data) => {
    data.activity.academic_year = tahunActive;
    data.activity.continue_activity =
      data.activity.continue_activity === "iya" ? true : false;
    dispatch(addKegiatan(data));
    setFormAddActivity(!formAddActivity);
    setModalMessage(!modalMessage);
  };
  const tambahSubKegiatan = (data) => {
    data.sub.academic_year = tahunActive;
    dispatch(addSubKegiatan(data));
    setFormAddSubActivity(!formAddSubActivity);
    setModalMessage(!modalMessage);
  };
  const tambahRincian = (data) => {
    let restruct = data.detail_of_activity_list?.map((value) => {
      value.unit_price = convertToRupiah(value.unit_price);
      value.total = convertToRupiah(value.total);
      value.academic_year = tahunActive;
      return value;
    });
    data.detail_of_activity_list = restruct;
    dispatch(addRincian(data));
    setFormAddDetailActivity(!formAddDetailActivity);
    setModalMessage(!modalMessage);
  };
  const editLembaga = (data) => {
    dispatch(updateLembaga({ no_lem: institution.value, edit: data }));
    setFormEditInstitution(!formEditInstitution);
    setModalMessage(!modalMessage);
  };
  const editProgram = (data) => {
    dispatch(updateProgram({ no_prog: programId, edit: data }));
    setFormEditProgram(!formEditProgram);
    setModalMessage(!modalMessage);
  };
  const editKomponen = (data) => {
    dispatch(updateKomponen({ no_kom: componentId, edit: data }));
    setFormEditComponent(!formEditComponent);
    setModalMessage(!modalMessage);
  };
  const editKegiatan = (data) => {
    data.continue_activity = data.continue_activity === "iya" ? true : false;
    dispatch(updateKegiatan({ no_keg: activityId, edit: data }));
    setFormEditActivity(!formEditActivity);
    setModalMessage(!modalMessage);
  };
  const editSubKegiatan = (data) => {
    dispatch(updateSubKegiatan({ no_sub: subActivityId, edit: data }));
    setFormEditSubActivity(!formEditSubActivity);
    setModalMessage(!modalMessage);
  };
  const editDetail = (id, data) => {
    let restruct = data.detail_of_activity_list?.map((value) => {
      value.unit_price = convertToRupiah(value.unit_price);
      value.total = convertToRupiah(value.total);
      value.academic_year = tahunActive;
      return value;
    });
    data.detail_of_activity_list = restruct;
    dispatch(updateDetail({ id: id, data: restruct[0] }));
    setModalMessage(!modalMessage);
  };
  const hapusLembaga = () => {
    dispatch(destroyLembaga(institution.value));
    setModalDeleteLembaga(!modalDeleteLembaga);
    setModalMessage(!modalMessage);
  };
  const hapusProgram = () => {
    dispatch(destroyProgram(programId));
    setModalDeleteProgram(!modalDeleteProgram);
    setModalMessage(!modalMessage);
  };
  const hapusKomponen = () => {
    dispatch(destroyKomponen(componentId));
    setModalDeleteKomponen(!modalDeleteKomponen);
    setModalMessage(!modalMessage);
  };
  const hapusKegiatan = () => {
    dispatch(destroyKegiatan(activityId));
    setModalDeleteKegiatan(!modalDeleteKegiatan);
    setModalMessage(!modalMessage);
  };
  const hapusSub = () => {
    dispatch(destroySubKegiatan(subActivityId));
    setModalDeleteSubKegiatan(!modalDeleteSubKegiatan);
    setModalMessage(!modalMessage);
  };
  const hapusDetail = (id) => {
    dispatch(destroyRincian(id));
    setModalMessage(true);
  };
  useGetProgram(institution?.value, tahunActive, modalMessage);
  useGetKomponen(programId, modalMessage);
  useGetKegiatan(componentId, modalMessage);
  // const loadProgram =
  //   role !== "admin-lembaga" && institution?.value
  //     ? program
  //     : role === "admin-lembaga"
  //     ? program
  //     : [];
  const loadProgram = institution?.value ? program : [];
  return (
    <div>
      <Loader show={loadingProgram} size="w-5 h-5 " />
      <SnackBar show={modalMessage} toggle={setModalMessage} msg={msgProker} />
      <FormLembaga
        show={formAddLembaga}
        close={setFormAddLembaga}
        submit={tambahLembaga}
      />
      <FormProgram
        show={formAddProgram}
        close={setFormAddProgram}
        submit={tambahProgram}
        lembaga={institution.value}
        // lembaga={role === "admin-lembaga" ? lembaga : institution.value}
        tahun={tahunActive}
      />
      <FormKomponen
        show={formAddComponent}
        close={setFormAddComponent}
        submit={tambahKomponen}
        programId={programId}
        lembaga={institution}
        tahun={tahunActive}
      />
      <FormKegiatan
        show={formAddActivity}
        close={setFormAddActivity}
        submit={tambahKegiatan}
        programId={programId}
        komponenId={componentId}
        lembaga={institution}
        tahun={tahunActive}
      />
      <FormSubKegiatan
        show={formAddSubActivity}
        close={setFormAddSubActivity}
        submit={tambahSubKegiatan}
        komponenId={componentId}
        kegiatanId={activityId}
        tahun={tahunActive}
      />
      <FormRincian
        show={formAddDetailActivity}
        close={setFormAddDetailActivity}
        submit={tambahRincian}
        rincian={rincianKegiatan}
        rinci={detail}
        lembaga={institution}
        tahun={tahunActive}
        activityId={activityId}
        subActivityId={subActivityId || null}
      />
      <DialogRincian
        show={modalDetailActivity}
        close={setModalDetailActivity}
        click={() => {
          setModalDetailActivity(!modalDetailActivity);
          setFormAddDetailActivity(!formAddDetailActivity);
        }}
        lembaga={lembaga}
        remove={hapusDetail}
        update={editDetail}
      />
      <DialogDelete
        show={modalDeleteProgram}
        close={setModalDeleteProgram}
        click={hapusProgram}
      />
      <DialogDelete
        show={modalDeleteKomponen}
        close={setModalDeleteKomponen}
        click={hapusKomponen}
      />
      <DialogDelete
        show={modalDeleteKegiatan}
        close={setModalDeleteKegiatan}
        click={hapusKegiatan}
      />
      <DialogDelete
        show={modalDeleteSubKegiatan}
        close={setModalDeleteSubKegiatan}
        click={hapusSub}
      />
      <DialogDelete
        show={modalDeleteLembaga}
        close={setModalDeleteLembaga}
        click={hapusLembaga}
      />
      <FormEditLembaga
        show={formEditInstitution}
        close={setFormEditInstitution}
        nama_lembaga={institution.label}
        submit={editLembaga}
      />
      <FormEditProgram
        show={formEditProgram}
        close={setFormEditProgram}
        submit={editProgram}
        programName={programName}
      />
      <FormEditKomponen
        show={formEditComponent}
        close={setFormEditComponent}
        submit={editKomponen}
        componentName={componentName}
      />
      <FormEditKegiatan
        show={formEditActivity}
        close={setFormEditActivity}
        submit={editKegiatan}
        activityName={oneActivity}
      />
      <FormEditSub
        show={formEditSubActivity}
        close={setFormEditSubActivity}
        submit={editSubKegiatan}
        subActivityName={subActivityName}
      />
      <HeaderUi titleHeader="Kegiatan" location={`list`} />
      <Table
        classSelect={"block"}
        // classSelect={role === "admin-lembaga" ? "invisible" : "block"}
        classButton={role === "bendahara" ? "block" : "hidden"}
        titleFirst="Lembaga"
        clickFirst={() => setDropdownMenu(!dropdownMenu)}
        dropDown={dropdownMenu}
        formAdd={() => {
          setFormAddLembaga(!formAddLembaga);
          setDropdownMenu(!dropdownMenu);
        }}
        formEdit={() => {
          setFormEditInstitution(!formEditInstitution);
          setDropdownMenu(!dropdownMenu);
        }}
        formDelete={() => {
          setModalDeleteLembaga(!modalDeleteLembaga);
          setDropdownMenu(!dropdownMenu);
        }}
        optSelect={lembagaApakahOptions}
        setChange={setInstitution}
        change={institution}
      >
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-5">
          <div className="flex  text-xs text-gray-700 uppercase bg-sky-100">
            <h1 className="px-6 py-3 w-[7rem]">Detail</h1>
            <h1 className="md:px-6 py-3 w-[10rem]">No Program</h1>
            <h1 className="px-6 py-3 w-[50rem]">Program</h1>
            <h1 className="px-12 py-3 w-[50rem]">Total</h1>
            {role !== "eksekutif" && (
              <>
                <h1 className={`md:px-6 px-12 py-3 `}>Edit</h1>
                <h1 className={`px-6 py-3`}>Delete</h1>
                <h1
                  className={`px-6 py-3 w-[7rem] bg-sky-400 hover:bg-sky-500 hover:text-slate-50 transition-colors ease-in-out duration-300 cursor-pointer }`}
                  onClick={() => {
                    setFormAddProgram(!formAddProgram);
                  }}
                >
                  Tambah Program
                </h1>
              </>
            )}
          </div>
          {loadProgram?.length > 0 &&
            loadProgram?.map((dataProgram, id) => (
              <div key={id}>
                <div className="flex  w-full text-sm text-left text-gray-500 hover:bg-gray-100">
                  <h1 className="px-6 py-3 w-[7rem]">
                    {dataProgram.id === programId && dropdownProgram ? (
                      <HiOutlineChevronUp
                        className="w-5 h-5 mx-2  cursor-pointer"
                        onClick={() => {
                          setProgramId(dataProgram.id);
                          setProgramNo(dataProgram.no);
                          setDropdownProgram(false);
                        }}
                      />
                    ) : (
                      <HiOutlineChevronDown
                        className="w-5 h-5 mx-2  cursor-pointer"
                        onClick={() => {
                          setProgramId(dataProgram.id);
                          setProgramNo(dataProgram.no);
                          setDropdownProgram(true);
                        }}
                      />
                    )}
                  </h1>
                  <h1 className="px-6 py-3 w-[10rem]">{dataProgram?.no}</h1>
                  <h1 className="px-6 py-3 w-[50rem]">{dataProgram.item}</h1>
                  <h1 className="px-12 py-3 w-[50rem]">
                    {dataProgram.total
                      ? currency(dataProgram.total)
                      : currency(0)}
                  </h1>
                  <h1
                    className={`md:px-5 px-3 py-3 ${
                      role === "eksekutif" ? "hidden" : "w-[7rem]"
                    } `}
                  >
                    <HiOutlinePencilSquare
                      className="w-6 h-6 cursor-pointer "
                      onClick={() => {
                        setProgramId(dataProgram.id);
                        setProgramName(dataProgram.item);
                        setFormEditProgram(!formEditProgram);
                      }}
                    />
                  </h1>
                  <h1
                    className={`md:px-5 px-16 py-3  ${
                      role === "eksekutif" ? "hidden" : "w-[7rem]"
                    } `}
                  >
                    <HiOutlineTrash
                      className="w-6 h-6 cursor-pointer mx-auto"
                      onClick={() => {
                        setProgramId(dataProgram.id);
                        setModalDeleteProgram(!modalDeleteProgram);
                      }}
                    />
                  </h1>
                  <h1
                    className={`px-12 py-3  ${
                      role === "eksekutif" ? "hidden" : "w-[7rem]"
                    } `}
                  ></h1>
                </div>
                <div
                  className={`relative overflow-x-auto sm:rounded-lg my-5 ml-5 transition-all ease-in-out duration-100 ${
                    dataProgram.id === programId && dropdownProgram
                      ? "visible mt-0 bg-opacity-100 text-opacity-100"
                      : "bg-opacity-0 -mt-1 text-transparent invisible hidden"
                  }`}
                >
                  <div className="flex  text-xs text-gray-700 uppercase bg-sky-100">
                    <h1 className="px-6 py-3 w-[7rem]">Detail</h1>
                    <h1 className="px-6 py-3 w-[10rem]">No Komponen</h1>
                    <h1 className="px-6 py-3 w-[50rem]">Komponen</h1>
                    <h1 className="px-6 py-3 w-[50rem]">Total</h1>
                    {role !== "eksekutif" && (
                      <>
                        <h1 className={`md:px-6 px-12 py-3 `}>Edit</h1>
                        <h1 className={`px-6 py-3`}>Delete</h1>
                        <h1
                          className={`px-6 py-3 w-[7rem] bg-sky-400 hover:bg-sky-500 hover:text-slate-50 transition-colors ease-in-out duration-300 cursor-pointer }`}
                          onClick={() => {
                            setFormAddComponent(!formAddComponent);
                            setProgramId(dataProgram.id);
                          }}
                        >
                          Tambah Komponen
                        </h1>
                      </>
                    )}
                  </div>
                  {komponen?.map((dataKomponen, id) => (
                    <div key={id}>
                      <div
                        key={id}
                        className="flex  w-full text-sm text-left text-gray-500 hover:bg-gray-100"
                      >
                        <h1 className="px-6 py-3 w-[7rem]">
                          {dataKomponen.id === componentId &&
                          dropdownKomponen ? (
                            <HiOutlineChevronUp
                              className="w-5 h-5 mx-2 cursor-pointer"
                              onClick={() => {
                                setComponentId(dataKomponen.id);
                                setComponentNo(dataKomponen.no);
                                setDropdownKomponen(false);
                              }}
                            />
                          ) : (
                            <HiOutlineChevronDown
                              className="w-5 h-5 mx-2 cursor-pointer"
                              onClick={() => {
                                setComponentId(dataKomponen.id);
                                setComponentNo(dataKomponen.no);
                                setDropdownKomponen(true);
                              }}
                            />
                          )}
                        </h1>
                        <h1 className="px-6 py-3 w-[10rem]">
                          {programNo}.{dataKomponen.no}
                        </h1>
                        <h1 className="px-6 py-3 w-[50rem]">
                          {dataKomponen.item}
                        </h1>
                        <h1 className="px-6 py-3 w-[50rem]">
                          {dataKomponen.total
                            ? currency(dataKomponen.total)
                            : currency(0)}
                        </h1>
                        <h1
                          className={`px-6 py-3  ${
                            role === "eksekutif" ? "hidden" : "w-[7rem]"
                          } `}
                        >
                          <HiOutlinePencilSquare
                            className="w-6 h-6 cursor-pointer "
                            onClick={() => {
                              setComponentId(dataKomponen.id);
                              setComponentName(dataKomponen.item);
                              setFormEditComponent(!formEditComponent);
                            }}
                          />
                        </h1>
                        <h1
                          className={`px-6 py-3  ${
                            role === "eksekutif" ? "hidden" : "w-[7rem]"
                          } `}
                        >
                          <HiOutlineTrash
                            className="w-6 h-6 cursor-pointer mx-auto"
                            onClick={() => {
                              setComponentId(dataKomponen.id);
                              setModalDeleteKomponen(!modalDeleteKomponen);
                            }}
                          />
                        </h1>
                        <h1
                          className={`px-12 py-3  ${
                            role === "eksekutif" ? "hidden" : "w-[7rem]"
                          } `}
                        ></h1>
                      </div>
                      <div
                        className={`relative overflow-x-auto sm:rounded-lg my-5 ml-5 ${
                          dataKomponen.id === componentId && dropdownKomponen
                            ? "block"
                            : "hidden"
                        }`}
                      >
                        <div className="flex  text-xs text-gray-700 uppercase bg-sky-100">
                          <h1 className="px-6 py-3 w-[7rem]">Detail</h1>
                          <h1 className="px-6 py-3 w-[10rem]">No Kegiatan</h1>
                          <h1 className="px-6 py-3 w-[50rem] ">Kegiatan</h1>
                          <h1 className="px-6 py-3 w-[7rem]">Rutin</h1>
                          <h1 className="px-6 py-3 w-[7rem]">Bobot</h1>
                          <h1 className="px-6 py-3 w-[50rem] ">Total</h1>
                          <h1 className="px-6 py-3 w-[7rem]">Rincian</h1>
                          {role !== "eksekutif" && (
                            <>
                              <h1 className={`md:px-6 px-12 py-3 `}>Edit</h1>
                              <h1 className={`px-6 py-3`}>Delete</h1>
                              <h1
                                className={`px-6 py-3 w-[7rem] bg-sky-400 hover:bg-sky-500 hover:text-slate-50 transition-colors ease-in-out duration-300 cursor-pointer }`}
                                onClick={() => {
                                  setFormAddActivity(!formAddActivity);
                                  setComponentId(dataKomponen.id);
                                }}
                              >
                                Tambah Kegiatan
                              </h1>
                            </>
                          )}
                        </div>
                        {kegiatan?.map((dataKegiatan, id) => (
                          <div key={id}>
                            <div
                              key={id}
                              className="flex  w-full text-sm text-left text-gray-500 hover:bg-gray-100"
                            >
                              <h1 className="px-6 py-3 w-[8rem]">
                                {dataKegiatan.id === activityId &&
                                dropdownKegiatan ? (
                                  <HiOutlineChevronUp
                                    className="w-5 h-5 mx-2 cursor-pointer"
                                    onClick={() => {
                                      setActivityId(dataKegiatan.id);
                                      setActivityNo(dataKegiatan.no);
                                      setDropdwonKegiatan(false);
                                    }}
                                  />
                                ) : (
                                  <HiOutlineChevronDown
                                    className="w-5 h-5 mx-2 cursor-pointer"
                                    onClick={() => {
                                      setActivityId(dataKegiatan.id);
                                      setActivityNo(dataKegiatan.no);
                                      setDropdwonKegiatan(true);
                                    }}
                                  />
                                )}
                              </h1>
                              <h1 className="px-6 py-3 w-[10rem]">
                                {programNo}.{componentNo}.{dataKegiatan.no}
                              </h1>
                              <h1 className="px-6 py-3 w-[50rem]">
                                {dataKegiatan.name}
                              </h1>
                              <h1 className="px-6 py-3 w-[7rem]">
                                {dataKegiatan.continue ? "Iya" : "Tidak"}
                              </h1>
                              <h1 className="px-6 py-3 w-[7rem]">
                                {dataKegiatan.weight || 0}
                              </h1>
                              <h1 className="px-6 py-3 w-[50rem]">
                                {dataKegiatan.total
                                  ? currency(dataKegiatan.total)
                                  : currency(0)}
                              </h1>
                              <div
                                onClick={() => {
                                  setSubActivityId("");
                                  dispatch(allRincianKegiatan(dataKegiatan.id));
                                  setDetail(true);
                                  setActivityId(dataKegiatan.id);
                                  setModalDetailActivity(!modalDetailActivity);
                                }}
                                className={`md:px-9 px-14 py-3 w-auto cursor-pointer ${
                                  dataKegiatan?.sub_activity?.length > 0
                                    ? "invisible"
                                    : "block"
                                }`}
                              >
                                <HiOutlinePlus className="w-6 h-6" />
                              </div>
                              <h1
                                className={`md:px-6 py-3  ${
                                  role === "eksekutif" ? "hidden" : "w-[7rem]"
                                } `}
                              >
                                <HiOutlinePencilSquare
                                  className="w-6 h-6 cursor-pointer "
                                  onClick={() => {
                                    setActivityId(dataKegiatan.id);
                                    setOneActivity(dataKegiatan);
                                    setFormEditActivity(!formEditActivity);
                                  }}
                                />
                              </h1>
                              <h1
                                className={`md:px-6 px-16 py-3  ${
                                  role === "eksekutif" ? "hidden" : "w-[7rem]"
                                } `}
                              >
                                <HiOutlineTrash
                                  className="w-6 h-6 cursor-pointer mx-auto"
                                  onClick={() => {
                                    setActivityId(dataKegiatan.id);
                                    setModalDeleteKegiatan(
                                      !modalDeleteKegiatan
                                    );
                                  }}
                                />
                              </h1>
                              <h1
                                className={`px-12 py-3  ${
                                  role === "eksekutif" ? "hidden" : "w-[7rem]"
                                } `}
                              ></h1>
                            </div>
                            <div
                              className={`relative overflow-x-auto sm:rounded-lg my-5 ml-5 ${
                                dataKegiatan.id === activityId &&
                                dropdownKegiatan
                                  ? "block"
                                  : "hidden"
                              }`}
                            >
                              <div className="flex  text-xs text-gray-700 uppercase bg-sky-100">
                                <h1 className="px-6 py-3 w-[7rem]">Rincian</h1>
                                <h1 className="px-6 py-3 w-[10rem]">
                                  No Sub Kegiatan
                                </h1>
                                <h1 className="px-6 py-3 w-[50rem]">
                                  Sub Kegiatan
                                </h1>
                                <h1 className="px-6 py-3 w-[10rem]">Bobot</h1>
                                <h1 className="px-6 py-3 w-[50rem]">Total</h1>
                                {role !== "eksekutif" && (
                                  <>
                                    <h1 className={`md:px-6 px-12 py-3 `}>
                                      Edit
                                    </h1>
                                    <h1 className={`px-6 py-3`}>Delete</h1>
                                    <h1
                                      className={`px-6 py-3 w-[7rem] bg-sky-400 hover:bg-sky-500 hover:text-slate-50 transition-colors ease-in-out duration-300 cursor-pointer }`}
                                      onClick={() => {
                                        setFormAddSubActivity(
                                          !formAddSubActivity
                                        );
                                        setComponentId(
                                          dataKegiatan.component_id
                                        );
                                        setActivityId(dataKegiatan.id);
                                      }}
                                    >
                                      Tambah Sub
                                    </h1>
                                  </>
                                )}
                              </div>
                              {kegiatan?.find((val) => val.id === activityId)
                                ?.sub_activity?.length > 0 &&
                                kegiatan
                                  ?.find((val) => val.id === activityId)
                                  ?.sub_activity?.map((dataSub, id) => (
                                    <div key={id}>
                                      <div
                                        key={id}
                                        className="flex  w-full text-sm text-left text-gray-500 hover:bg-gray-100"
                                        onClick={() => {
                                          setSubActivityId(dataSub.id);
                                        }}
                                      >
                                        <h1 className="px-6 py-3 w-[8rem]">
                                          {dataSub.id === subActivityId ? (
                                            <HiOutlineChevronDown
                                              className="w-5 h-5 mx-2 cursor-pointer"
                                              onClick={() => {
                                                setSubActivityId(dataSub.id);
                                                setSubActivityNo(dataSub.no);
                                                dispatch(
                                                  allRincianSubKegiatan(
                                                    dataSub.id
                                                  )
                                                );
                                                setModalDetailActivity(
                                                  !modalDetailActivity
                                                );
                                                setDetail(false);
                                              }}
                                            />
                                          ) : (
                                            <HiOutlineChevronDown className="w-5 h-5 mx-2 cursor-pointer" />
                                          )}
                                        </h1>
                                        <h1 className="px-6 py-3 w-[10rem]">
                                          {institution?.value}.{programNo}.
                                          {componentNo}.{activityNo}.
                                          {dataSub.sub_activity_no}
                                        </h1>
                                        <h1 className="px-6 py-3 w-[50rem]">
                                          {dataSub.name}
                                        </h1>
                                        <h1 className="px-6 py-3 w-[10rem]">
                                          {dataSub.weight}
                                        </h1>
                                        <h1 className="px-6 py-3 w-[50rem]">
                                          {dataSub.total
                                            ? currency(dataSub.total)
                                            : currency(0)}
                                        </h1>
                                        <h1
                                          className={`px-6 py-3  ${
                                            role === "eksekutif"
                                              ? "hidden"
                                              : "w-[7rem]"
                                          } `}
                                        >
                                          <HiOutlinePencilSquare
                                            className="w-6 h-6 cursor-pointer "
                                            onClick={() => {
                                              setSubActivityId(dataSub.id);
                                              setSubActivityName(dataSub.name);
                                              setFormEditSubActivity(
                                                !formEditSubActivity
                                              );
                                            }}
                                          />
                                        </h1>
                                        <h1
                                          className={`px-6 py-3  ${
                                            role === "eksekutif"
                                              ? "hidden"
                                              : "w-[7rem]"
                                          } `}
                                        >
                                          <HiOutlineTrash
                                            className="w-6 h-6 cursor-pointer mx-auto"
                                            onClick={() => {
                                              setSubActivityId(dataSub.id);
                                              setModalDeleteSubKegiatan(
                                                !modalDeleteSubKegiatan
                                              );
                                            }}
                                          />
                                        </h1>
                                        <h1
                                          className={`px-12 py-3  ${
                                            role === "eksekutif"
                                              ? "hidden"
                                              : "w-[7rem]"
                                          } `}
                                        ></h1>
                                      </div>
                                    </div>
                                  ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
        {Object.keys(totalPendapatan).length !== 0 ? (
          <div>
            <h1 className="text-lg font-semibold">Pendapatan</h1>
            {totalPendapatan?.pendapatan.length !== 0 ? (
              <div className="grid grid-cols-3">
                {totalPendapatan?.pendapatan?.map((d, id) => (
                  <div key={id}>
                    <h1>{`-> ${d.item_pendapatan}`}</h1>
                    <h1 className="ml-7">
                      Belum disetujui ={" "}
                      {d.belum_disetujui !== null
                        ? currency(d.belum_disetujui)
                        : currency(0)}
                    </h1>
                    <h1 className="ml-7">
                      Tersedia ={" "}
                      {d.tersedia !== null ? currency(d.tersedia) : currency(0)}
                    </h1>
                    <h1 className="ml-7">
                      Tersedia perkiraan ={" "}
                      {d.tersedia_perkiraan !== null
                        ? currency(d.tersedia_perkiraan)
                        : currency(0)}
                    </h1>
                    <h1 className="ml-7">
                      Total perkiraan ={" "}
                      {d.total_perkiraan !== null
                        ? currency(d.total_perkiraan)
                        : currency(0)}
                    </h1>
                    <h1 className="ml-7">
                      Total disetujui ={" "}
                      {d.total_disetujui !== null
                        ? currency(d.total_disetujui)
                        : currency(0)}
                    </h1>
                    <h1 className="ml-7">
                      Total ={" "}
                      {d.total !== null ? currency(d.total) : currency(0)}
                    </h1>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
            {totalPendapatan?.total !== null ? (
              Object.keys(totalPendapatan.total).length !== 0 ? (
                <div className="mt-5">
                  <h1 className="text-lg font-semibold">Total Keseluruhan</h1>
                  <h1 className="ml-7">
                    Belum disetujui ={" "}
                    {totalPendapatan?.total?.belum_disetujui !== null
                      ? currency(
                          parseInt(totalPendapatan?.total?.belum_disetujui)
                        )
                      : currency(0)}
                  </h1>
                  <h1 className="ml-7">
                    Tersedia perkiraan ={" "}
                    {totalPendapatan?.total?.tersedia_perkiraan !== null
                      ? currency(
                          parseInt(totalPendapatan?.total?.tersedia_perkiraan)
                        )
                      : currency(0)}
                  </h1>

                  <h1 className="ml-7">
                    Tersedia ={" "}
                    {totalPendapatan?.total?.tersedia !== null
                      ? currency(parseInt(totalPendapatan?.total?.tersedia))
                      : currency(0)}
                  </h1>
                  <h1 className="ml-7">
                    Total disetujui ={" "}
                    {totalPendapatan?.total?.total_disetujui !== null
                      ? currency(
                          parseInt(totalPendapatan?.total?.total_disetujui)
                        )
                      : currency(0)}
                  </h1>
                  <h1 className="ml-7">
                    Total perkiraan ={" "}
                    {totalPendapatan?.total?.total_perkiraan !== null
                      ? currency(
                          parseInt(totalPendapatan?.total?.total_perkiraan)
                        )
                      : currency(0)}
                  </h1>
                  <h1 className="ml-7">
                    Total ={" "}
                    {totalPendapatan?.total?.total !== null
                      ? currency(parseInt(totalPendapatan?.total?.total))
                      : currency(0)}
                  </h1>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </Table>
    </div>
  );
}

export default Kegiatan;
