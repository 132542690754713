import { createSlice } from "@reduxjs/toolkit";
import { getAllPencairan, postPencairan } from "../actions/pencairan";

export const PencaiaranStore = createSlice({
  name: "pencairan",
  initialState: {
    allPencairan: [],
    onePencairan: {},
    loadingPencairan: false,
    msgPencairan: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPencairan.pending, (state) => {
        state.loadingPencairan = true;
      })
      .addCase(getAllPencairan.fulfilled, (state, action) => {
        state.loadingPencairan = false;
        state.allPencairan = action.payload;
      })
      .addCase(getAllPencairan.rejected, (state, action) => {
        state.loadingPencairan = false;
        state.msgPencairan = "err";
      })
      .addCase(postPencairan.pending, (state) => {
        state.loadingPencairan = true;
      })
      .addCase(postPencairan.fulfilled, (state, action) => {
        state.loadingPencairan = false;
        state.msgPencairan = action.payload?.message;
      })
      .addCase(postPencairan.rejected, (state, action) => {
        state.loadingPencairan = false;
        state.msgPencairan = "err";
      });
  },
});

export default PencaiaranStore.reducer;
