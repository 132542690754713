import React, { useEffect, useState } from "react";
import Dialog from "./Dialog";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AddButton from "./AddButton";
import { useSelector } from "react-redux";
import TextArea from "./TextArea";
import Option from "./Option";
import useLembagaApakahOptions from "../options/lembaga";
import useResetForm from "../hooks/custom/useResetForm";

function FormProgram(props) {
  const { role, lembaga } = useSelector((state) => state.auth);
  const lembagaApakahOptions = useLembagaApakahOptions(false);
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        institution_id: yup.string().required("Lembaga Tidak Boleh Kosong"),
        item: yup.string().required("Nama Program Tidak Boleh Kosong"),
      })
    ),
  });
  useResetForm(
    method,
    {
      institution_id: props.lembaga,
      // institution_id: role === "admin-lembaga" ? lembaga : props.lembaga,
      item: "",
    },
    props?.show
  );
  return (
    <Dialog show={props.show} toggle={props.close}>
      <div className="bg-white md:w-[60vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl"> Tambah Program</h1>
        </div>
        <form
          onSubmit={method.handleSubmit(props.submit)}
          className="px-6 py-7 space-y-1 "
        >
          <div className=" md:space-x-5 flex md:flex-row flex-col">
            <div
              className={`${
                role !== "admin-lembaga" ? "block md:w-[50vw]" : "block md:w-[50vw]"
                // role !== "admin-lembaga" ? "block md:w-[50vw]" : "hidden"
              } `}
            >
              <Option
                label="Lembaga"
                method={method}
                methodName="institution_id"
                options={lembagaApakahOptions}
              />
            </div>
            <TextArea
              label="Program"
              method={method}
              methodName="item"
              className="md:w-[70vw]"
            />
          </div>
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}

export default FormProgram;
