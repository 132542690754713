import { createSlice } from "@reduxjs/toolkit";
import {
  addSharingProgram,
  deleteSharingProgram,
  getAllSharingProgram,
  updateSharingProgram,
} from "../actions/sharing";

export const SharingProgramStore = createSlice({
  name: "sharingProgram",
  initialState: {
    allSharingProgram: [],
    messageSharingProgram: "",
    loadingSharingProgram: false,
  },
  extraReducers: builder => {
    builder
      .addCase(getAllSharingProgram.pending, (state) => {
        state.loadingSharing = true;
      })
      .addCase(getAllSharingProgram.fulfilled, (state, action) => {
        state.loadingSharing = false;
        state.allSharingProgram = action.payload;
      })
      .addCase(getAllSharingProgram.rejected, (state, action) => {
        state.loadingSharing = false;
        state.messageSharingProgram = action.payload;
      })
      .addCase(addSharingProgram.pending, (state) => {
        state.loadingSharing = true;
      })
      .addCase(addSharingProgram.fulfilled, (state, action) => {
        state.loadingSharing = false;
        state.messageSharingProgram = action.payload.msg;
      })
      .addCase(addSharingProgram.rejected, (state, action) => {
        state.loadingSharing = false;
        state.messageSharingProgram = action.payload;
      })
      .addCase(updateSharingProgram.pending, (state) => {
        state.loadingSharing = true;
      })
      .addCase(updateSharingProgram.fulfilled, (state, action) => {
        state.loadingSharing = false;
        state.messageSharingProgram = action.payload.msg;
      })
      .addCase(updateSharingProgram.rejected, (state, action) => {
        state.loadingSharing = false;
        state.messageSharingProgram = action.payload;
      })
      .addCase(deleteSharingProgram.pending, (state) => {
        state.loadingSharing = true;
      })
      .addCase(deleteSharingProgram.fulfilled, (state, action) => {
        state.loadingSharing = false;
        state.messageSharingProgram = action.payload.msg;
      })
      .addCase(deleteSharingProgram.rejected, (state, action) => {
        state.loadingSharing = false;
        state.messageSharingProgram = action.payload;
      });
  },
});


export default SharingProgramStore.reducer;