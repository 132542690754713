import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import Dialog from './Dialog'
import Input from './Input'
import { HiEyeSlash,HiEye } from 'react-icons/hi2'
import AddButton from './AddButton'
import { useSelector } from 'react-redux'

function FormUser(props) {
    const {username,nama} = useSelector((state)=>state.auth)
    const [eye,setEye] = useState(false)
    const [eye2,setEye2] = useState(false)
    const methodUser = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                nama : yup.string().required("Tidak Boleh Kosong"),
                username: yup.string().required("Tidak Boleh Kosong"),
                old_pass : yup.string().min(8, "Password Minimal 8 Karakater"),
                new_pass: yup.string().min(8, "Password Minimal 8 Karakater")
            })
        )
    })
    useEffect(()=>{
        methodUser.reset({username:username,nama:nama})
    },[props.show])
    return (
        <Dialog show={props.show} toggle={props.exitForm}>
            <div className='bg-white md:w-[40vw] w-[90vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'> Edit User</h1>
                </div>
                <form onSubmit={methodUser.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <Input
                        // tag={<User className='w-7 h-7 mr-1 mb-1' />}
                        label="username"
                        method={methodUser}
                        methodName="username"
                    />
                    <Input
                        // tag={<Lock className='w-7 h-7 mr-1 mb-1' />}
                        type={eye ? "text" : "password"}
                        label="Password Lama"
                        method={methodUser}
                        methodName="old_pass"
                        icon={eye ? <HiEye className='w-6 h-6 mr-3 cursor-pointer absolute right-5 mt-3' onClick={() => setEye(!eye)} /> : <HiEyeSlash className='w-6 h-6 my-auto mr-3 cursor-pointer absolute right-5 mt-3' onClick={() => setEye(!eye)} />}
                    />
                    <Input
                        // tag={<Lock className='w-7 h-7 mr-1 mb-1' />}
                        type={eye2 ? "text" : "password"}
                        label="Password Baru"
                        method={methodUser}
                        methodName="new_pass"
                        icon={eye2 ? <HiEye className='w-6 h-6 mr-3 cursor-pointer absolute right-5 mt-3' onClick={() => setEye2(!eye2)} /> : <HiEyeSlash className='w-6 h-6 my-auto mr-3 cursor-pointer absolute right-5 mt-3' onClick={() => setEye2(!eye2)} />}
                    />
                    <AddButton />
                </form>
            </div>
        </Dialog>
        )
}

export default FormUser