import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Dialog from "./Dialog";
import Input from "./Input";
import AddButton from "./AddButton";
import Option from "./Option";
import useResetForm from "../hooks/custom/useResetForm";

function FormGrupPendapatan(props) {
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Tidak Boleh Kosong"),
        parent_id: yup
          .number()
          .typeError("Tidak boleh kosong")
          .required("Tidak Boleh Kosong"),
      })
    ),
    defaultValues: {},
  });
  const parenOptions = [
    { value: 1, label: "Pondok" },
    { value: 2, label: "BOS" },
    { value: 3, label: "Sumbangan" },
  ];
  useResetForm(method,{name:'',parent_id:null},props?.show)
  return (
    <Dialog show={props.show} toggle={props.close}>
      <div className="bg-white md:w-[70vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl"> Tambah Grup Pendapatan</h1>
        </div>
        <form
          onSubmit={method.handleSubmit(props.submit)}
          className="px-6 py-7 space-y-1"
        >
          <div className="md:grid md:grid-cols-1 gap-4">
            <Option
              label="Pendapatan"
              method={method}
              methodName="parent_id"
              options={parenOptions}
            />
          </div>
          <Input label="Nama" method={method} methodName="name" />
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}

export default FormGrupPendapatan;
