export const bulan = [
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
];

export const bulanOpt = [
  { value: 1, label: "Juli" },
  { value: 2, label: "Agustus" },
  { value: 3, label: "September" },
  { value: 4, label: "Oktober" },
  { value: 5, label: "November" },
  { value: 6, label: "Desember" },
  { value: 7, label: "Januari" },
  { value: 8, label: "Februari" },
  { value: 9, label: "Maret" },
  { value: 10, label: "April" },
  { value: 11, label: "Mei" },
  { value: 12, label: "Juni" },
];
export const sumberOpt = [
  { value: "pondok", label: "Pondok" },
  { value: "bosjuknis", label: "Bos Juknis" },
  { value: "bos_taktis", label: "Bos Taktis" },
  { value: "unit_usaha_pondok", label: "Unit Usaha Pondok" },
];
export const metodeOpt = [
  { value: "termin", label: "Termin" },
  { value: "harian", label: "Harian" },
  { value: "pekanan", label: "Pekanan" },
  { value: "bulanan", label: "Bulanan" },
  { value: "semester", label: "Semester" },
  { value: "tahunan", label: "Tahunan" },
];


export const listPendapatan = [
  {value:"PAGU PONDOK",label:"PAGU PONDOK"},
  {value:"BOS TAKTIS",label:"BOS TAKTIS"},
  {value:"BOS JUKNIS",label:"BOS JUKNIS"}
]

export const jenisBelanja = [
  {value:1,label:"Belanja Modal"},
  {value:2,label:"Pegawai"},
  {value:3,label:"Barang dan Jasa"},
]