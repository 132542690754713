import { createSlice } from "@reduxjs/toolkit";
import { getAllPtk } from "../actions/ptk";

export const PtkStore = createSlice({
    name:'ptk',
    initialState:{
        allPtk:[],
        onePtk:{},
        loadingPtk:false,
        msgPtk:"",
    },
    extraReducers:builder=>{
        builder
        .addCase(getAllPtk.pending,(state)=>{
            state.loadingPtk = true
        })
        .addCase(getAllPtk.fulfilled,(state,action)=>{
            state.loadingPtk = false
            state.allPtk=action.payload
        })
        .addCase(getAllPtk.rejected,(state,action)=>{
            state.loadingPtk = false
            state.msgPtk = action.payload
        })
    }
})

export default PtkStore.reducer