import { createSlice } from "@reduxjs/toolkit";
import { addUser, editUserByAdmin, getAllUser, updateUser,postSistem,destroySistem, editSistemOne, destroyUser } from "../actions/user";

export const userStore = createSlice({
    name:"user",
    initialState : {
        allUser : [],
        msgUser : "",
        loadingUser : false
    },
    extraReducers : builder =>{
        builder
        .addCase(updateUser.pending,(state)=>{
            state.loadingUser = true
        })
        .addCase(updateUser.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.message
        })
        .addCase(updateUser.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(getAllUser.pending,(state)=>{
            state.loadingUser = true
        })
        .addCase(getAllUser.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.allUser = action.payload
        })
        .addCase(getAllUser.rejected,(state)=>{
            state.loadingUser = false
        })
        .addCase(addUser.pending,(state)=>{
            state.loadingUser = true
        })
        .addCase(addUser.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.message
        })
        .addCase(addUser.rejected,(state)=>{
            state.loadingUser = false
        })
        .addCase(editUserByAdmin.pending,(state)=>{
            state.loadingUser = true
        })
        .addCase(editUserByAdmin.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.message
        })
        .addCase(editUserByAdmin.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(postSistem.pending,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(postSistem.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.message
        })
        .addCase(postSistem.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(destroySistem.pending,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(destroySistem.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.message
        })
        .addCase(destroySistem.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(editSistemOne.pending,(state)=>{
            state.loadingUser = false
        })
        .addCase(editSistemOne.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.message
        })
        .addCase(editSistemOne.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(destroyUser.pending,(state)=>{
            state.loadingUser = true
        })
        .addCase(destroyUser.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.message
        })
        .addCase(destroyUser.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })

    }
})
export default userStore.reducer