import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { getRole, getSistem, allLembaga } from "../store/actions/loadProgram";
import Options from "./Option";
import Dialog from "./Dialog";
import AddButton from "./AddButton";
import Radio from "./Radio";
import useLembagaApakahOptions from "../options/lembaga";
import useSystemOptions from "../options/system";
import useRoleOptions from "../options/role";
import useResetForm from "../hooks/custom/useResetForm";

function FormAddSistem(props) {
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        system_id: yup.string().required("Tidak Boleh Kosong"),
        role_id: yup.string().required("Tidak Boleh Kosong"),
        super_admin: yup
          .string()
          .oneOf(["true", "false"], "Pilih Salah Satu")
          .required("Tidak Boleh Kosong"),
        institution_apakah: yup.string(),
      })
    ),
  });
  const watchRole = method.watch("role_id");
  const sistemOpt = useSystemOptions(props.show);
  const roleOpt = useRoleOptions(method.watch("system_id"), props.show);
  const lembagaOpt = useLembagaApakahOptions(props.show, false);
  useResetForm(method, {}, props.show);
  return (
    <Dialog show={props.show} toggle={props.close}>
      <div className="bg-white md:w-[40vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl">Tambah Sistem Baru</h1>
        </div>
        <form
          onSubmit={method.handleSubmit(props.submit)}
          className="px-6 py-7 space-y-1"
        >
          <Options
            label="Sistem"
            method={method}
            methodName="system_id"
            options={sistemOpt}
          />
          <Options
            label="Role"
            method={method}
            methodName="role_id"
            options={roleOpt}
          />
          <div className="mb-1">
            <h1 className="font-light text-lg mb-2">Super Admin</h1>
            <div className="flex my-auto md:space-x-0 space-x-2">
              <Radio
                method={method}
                methodName={`super_admin`}
                value="true"
                label="Iya"
              />
              <Radio
                method={method}
                methodName={`super_admin`}
                value="false"
                label="Tidak"
              />
            </div>
            {method.formState.errors[`super_admin`] && (
              <p className="text-red-600 mt-1 mx-1">
                {method.formState.errors[`super_admin`].message}
              </p>
            )}
          </div>
          <div
            className={`${
              roleOpt.find((e) => e.value === watchRole)?.label ===
              "admin-lembaga"
                ? "block"
                : "hidden"
            }`}
          >
            <Options
              label="Lembaga"
              method={method}
              methodName="no_lembaga"
              options={lembagaOpt}
            />
          </div>
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}

export default FormAddSistem;
