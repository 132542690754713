import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiSharingProgram from "../../api/sharing";

export const getAllSharingProgram = createAsyncThunk(
  "get/all/sharing-program",
  async (_, { rejectWithValue }) => {
    try {
      const res = await ApiSharingProgram.getAll();
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response?.data?.msg);
    }
  }
);

export const addSharingProgram = createAsyncThunk(
  "post/sharing-program",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiSharingProgram.add(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response?.data?.msg);
    }
  }
);

export const updateSharingProgram = createAsyncThunk(
  "update/sharing-program",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiSharingProgram.update(data.uuid, data.data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response?.data?.msg);
    }
  }
);

export const deleteSharingProgram = createAsyncThunk(
  "delete/sharing-program",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiSharingProgram.delete(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response?.data?.msg);
    }
  }
);
