import axios from "axios";
import { isLogout } from "../store/actions/auth";

const api = axios.create({
// baseURL:"http://192.168.1.11:9191"
  // baseURL:"http://localhost:9999"
  // baseURL:"http://localhost:9933"
  baseURL: "https://api-cakepout.ponpesabuhurairah.id",
  // baseURL:"https://sipahamv2.ponpesabuhurairah.id"
});

let store;
export const injectStore = (_store) => {
  store = _store;
};

api.interceptors.request.use((req) => {
  if (!req.url.includes("/authentication/login")) {
    req.headers = {
      Authorization: `Bearer ${store.getState().auth.token}`,
    };
  }
  return req;
});

api.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      store.dispatch(isLogout());
    }
    return Promise.reject(err);
  }
);

export default api;
