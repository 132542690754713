import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useGetSatuan from "../hooks/useGetSatuan";

export default function useSatuanOptions(trigger) {
  const { satuan } = useSelector((state) => state.loadProgram);
  const [allSatuanOptions, setAllSatuanOptions] = useState([]);
  useGetSatuan(trigger);
  useEffect(() => {
    if (satuan.length > 0) {
      const allSatuan = satuan.map((e) => {
        return { value: e.id, label: e.name };
      });
      setAllSatuanOptions(allSatuan);
    }
  }, [satuan]);
  return allSatuanOptions;
}
