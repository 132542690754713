import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiDashboard from "../../api/dashboard";

export const getAllDashboard = createAsyncThunk(
    'dashboard/list',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiDashboard.getDashboard()
            if(res.status === 200){
                return res.data
            }
        }
        catch(_){
            return rejectWithValue("error")
        }
    }
)
export const getAllDashboardLembaga = createAsyncThunk(
    'dashboard/lembaga/list',
    async(lembaga,{rejectWithValue})=>{
        try{
            const res = await ApiDashboard.getDashboardLembaga(lembaga)
            if(res.status === 200){
                return res.data
            }
        }
        catch(_){
            return rejectWithValue("error")
        }
    }
)
export const allPengeluaran = createAsyncThunk(
    'dashboard/rincian/list',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiDashboard.getPengeluaran()
            if(res.status === 200){
                return res.data
            }
        }
        catch(_){
            return rejectWithValue("error")
        }
    }
)