import api from "./http";

const ApiSharingProgram = {
  getAll: () => {
    return api.get(`/apakah/sharing-program`);
  },
  add: (data) => {
    return api.post(`/apakah/sharing-program`, data);
  },
  update: (uuid, data) => {
    return api.put(`/apakah/sharing-program/${uuid}`, data);
  },
  delete: (uuid) => {
    return api.delete(`/apakah/sharing-program/${uuid}`);
  },
};

export default ApiSharingProgram;
