import Select from "react-select"

function Selector({ title,onChange, options, value }) {
    return (
        <div className='w-60 font-montserrat font-light'>
            <label className="font-light mb-1">{title}</label>
            <Select
                styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: "6px",
                        paddingTop: "1px",
                        paddingBottom: "1px",
                    }),

                }}
                options={options}
                value={value}
                onChange={onChange}
                defaultValue={{ value: null, label: "Pilih Mustamiq" }}
            />
        </div>
    )
}

export default Selector