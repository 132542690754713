import { createSlice } from "@reduxjs/toolkit";
import { addIncomeGroup, addPendapatan, destroyPendapatan, destroyRincianPendapatan, getAllPendapatan, getAllPendapatanByLem, getIncomeGroup, getOneByNoPendapatan, updatePendapatan } from "../actions/pendapatan";

export const pendapatanStore = createSlice({
    name:"pendapatan",
    initialState:{
        pendapatanAll : [],
        groupPendapatan:[],
        pendapatanOne : {},
        msgPendapatan : "",
        loadingPendapatan : false
    },
    extraReducers : builder =>{
        builder
        .addCase(addPendapatan.pending,(state)=>{
            state.loadingPendapatan = true
        })
        .addCase(addPendapatan.fulfilled,(state,action)=>{
            state.loadingPendapatan = false
            state.msgPendapatan = action.payload.message
        })
        .addCase(addPendapatan.rejected,(state,action)=>{
            state.loadingPendapatan = false
            state.msgPendapatan = action.payload
        })
        .addCase(addIncomeGroup.pending,(state)=>{
            state.loadingPendapatan = true
        })
        .addCase(addIncomeGroup.fulfilled,(state,action)=>{
            state.loadingPendapatan = false
            state.msgPendapatan = action.payload.message
        })
        .addCase(addIncomeGroup.rejected,(state,action)=>{
            state.loadingPendapatan = false
            state.msgPendapatan = action.payload
        })
        .addCase(getAllPendapatan.pending,(state)=>{
            state.loadingPendapatan = true
        })
        .addCase(getAllPendapatan.fulfilled,(state,action)=>{
            state.loadingPendapatan = false
            state.pendapatanAll = action.payload
        })
        .addCase(getAllPendapatan.rejected,(state)=>{
            state.loadingPendapatan = false
        })
        .addCase(getIncomeGroup.pending,(state)=>{
            state.loadingPendapatan = true
        })
        .addCase(getIncomeGroup.fulfilled,(state,action)=>{
            state.loadingPendapatan = false
            state.groupPendapatan = action.payload
        })
        .addCase(getIncomeGroup.rejected,(state)=>{
            state.loadingPendapatan = false
        })
        .addCase(getAllPendapatanByLem.pending,(state)=>{
            state.loadingPendapatan = true
        })
        .addCase(getAllPendapatanByLem.fulfilled,(state,action)=>{
            state.loadingPendapatan = false
            state.pendapatanAll = action.payload
        })
        .addCase(getAllPendapatanByLem.rejected,(state)=>{
            state.loadingPendapatan = false
        })
        .addCase(getOneByNoPendapatan.pending,(state)=>{
            state.loadingPendapatan = true
        })
        .addCase(getOneByNoPendapatan.fulfilled,(state,action)=>{
            state.loadingPendapatan = false
            state.pendapatanOne = action.payload
        })
        .addCase(getOneByNoPendapatan.rejected,(state)=>{
            state.loadingPendapatan = false
        })
        .addCase(updatePendapatan.pending,(state)=>{
            state.loadingPendapatan = true
        })
        .addCase(updatePendapatan.fulfilled,(state,action)=>{
            state.loadingPendapatan = false
            state.msgPendapatan = action.payload.message
        })
        .addCase(updatePendapatan.rejected,(state,action)=>{
            state.loadingPendapatan = false
            state.msgPendapatan = action.payload
        })
        .addCase(destroyPendapatan.pending,(state)=>{
            state.loadingPendapatan = true
        })
        .addCase(destroyPendapatan.fulfilled,(state,action)=>{
            state.loadingPendapatan = false
            state.msgPendapatan = action.payload.message
        })
        .addCase(destroyPendapatan.rejected,(state,action)=>{
            state.loadingPendapatan = false
            state.msgPendapatan = action.payload
        })
        .addCase(destroyRincianPendapatan.pending,(state)=>{
            state.loadingPendapatan = true
        })
        .addCase(destroyRincianPendapatan.fulfilled,(state,action)=>{
            state.loadingPendapatan = false
            state.msgPendapatan = action.payload.message
        })
        .addCase(destroyRincianPendapatan.rejected,(state,action)=>{
            state.loadingPendapatan = false
            state.msgPendapatan = action.payload
        })
    }
})
export default pendapatanStore.reducer