import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import Dialog from "./Dialog";
import Input from "./Input";
import AddButton from "./AddButton";
import ArrayRincian from "./ArrayRincian";
import { useDispatch, useSelector } from "react-redux";
import { allSatuan, getTahunActive } from "../store/actions/loadProgram";
import Option from "./Option";
import TextArea from "./TextArea";
import { destroyRincianPendapatan } from "../store/actions/pendapatan";
import { convertToRupiah, formatRupiah } from "./FormatMoney";
import useActiveYear from "../options/tahunActive";
import useLembagaApakahOptions from "../options/lembaga";
import useResetForm from "../hooks/custom/useResetForm";

function FormEditPendapatan(props) {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.auth);
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Tidak Boleh Kosong"),
        total: yup.string().required("Tidak Boleh Kosong"),
      })
    ),
  });
  const watchTotal = method.watch("total");
  useEffect(() => {
    if (watchTotal !== undefined && watchTotal !== null && watchTotal !== "") {
      let reset = convertToRupiah(watchTotal);
      let change = formatRupiah(reset);
      method.setValue("total", change);
    }
  }, [watchTotal]);
  useResetForm(method, props.data, props.data);
  return (
    <Dialog show={props.show} toggle={props.close}>
      <div className="bg-white md:w-[70vw] w-[90vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl"> Edit Pendapatan</h1>
        </div>
        <form
          onSubmit={method.handleSubmit(props.submit)}
          className="px-6 py-7 space-y-1"
        >
          <Input label="Nama" method={method} methodName="name" />
          <Input label="Total" method={method} methodName="total" />
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}

export default FormEditPendapatan;
